section.component.inspiration {
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
    }

    .title-row {
        margin-bottom: 30px;
        @include tabletLandscape {
            margin-bottom: 10px;
        }
    }

    .my-flex-columns {
        width: 100%;
        margin-left: -15px;
        margin-right: -15px;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        .column {
            position: relative;
            transition: transform .2s;
            backface-visibility: hidden;
            *zoom:1;

            &:hover {
                transform: scale(1.05);
            }

            @include media-breakpoint-down(sm) {
                padding: 7.5px 0;
                margin-bottom: 7.5px;
            }

            &.small-col {
                width: 25%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                @include tablet {
                    width: 33.3333333333%;
                }
            }

            &.big-col {
                width: 50%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                @include tablet {
                    width: 66.6666666667%;
                }

            }

            img {
                width: 100%;
                height: auto;
            }

            .column-inner {
                padding-bottom: 75%;
                position: relative;
            }

            a {
                width: auto;
                display: block;
                position: absolute;
                top: 15px;
                left: 15px;
                right: 15px;
                bottom: 15px;
                background-size: cover;
                background-repeat: no-repeat;

                @include media-breakpoint-down(sm) {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

            }

            p.inspiration-link {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $white;
                padding: 10px 10px 10px 0;
                color: $black;
                text-transform: uppercase;
                text-decoration: none;
                font-family: $tideBunny;
                font-size: 14px;
                margin: 0;

                @include media-breakpoint-down(sm) {
                    bottom: 0;
                }

                i {
                    color: $magenta;
                    margin-right: 5px;
                }
            }

        }

    }

}
