section.component.content-block {
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }
    @include tabletLandscape {
        margin-bottom: 60px;
    }
    @include tablet {
        margin-bottom: 50px;
    }

    .offset-title-row {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }
    .contentblock-row {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }

        .editor_content {
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
            &:last-of-type {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }
            }
            strong {
                font-family: $tideBunny,sans-serif;
            }
        }

        &.contentblock-row-3col {
            margin-bottom: 50px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
            @include tabletLandscape {
                margin-bottom: 60px;
            }

            @include tablet {
                margin-bottom: 20px;
            }

            .editor_content {
                @include tablet {
                    margin-bottom: 30px;
                }
            }

        }

        &.contentblock-row-4col {
            margin-bottom: 100px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
            @include tabletLandscape {
                margin-bottom: 60px;
            }

            @include tablet {
                margin-bottom: 20px;
            }

            .editor_content {
                @include tablet {
                    margin-bottom: 30px;
                }
            }

        }

        &.contentblock-faqs {
            margin-bottom: 60px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }
    }

    .text-left {
        text-align: left!important;
    }
    .text-center {
        text-align: center!important;
    }
    .text-right {
        text-align: right!important;
    }


    ul.faqs {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            transition: background-color .2s;
            &:nth-child(odd) {
                background-color: $lightGray;
            }

            &.question-active {
                background-color: $yellow;
                h4:after {
                    transform: rotate(-180deg);
                }
            }

            h4 {
                display: table;
                width: 100%;
                position: relative;
                font-family: $tideKahuna;
                font-size: 16px;
                font-weight: normal;
                color: $black;
                height: 60px;
                margin: 0;
                padding: 0 30px;
                cursor: pointer;

                @include media-breakpoint-down(sm) {
                    padding: 0 30px 0 15px;
                }

                span {
                    display: table-cell;
                    vertical-align: middle;
                }

                &:after {
                    width: 60px;
                    height: 60px;
                    content: '';
                    display: block;
                    background-image: url('../img/faq-arrow.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 13px 9px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition: transform .2s;
                    backface-visibility: hidden;
                    *zoom:1;
                }

            }
            .editor_content {
                display: none;
                padding: 0 30px 30px 30px;
                p + ul {
                    margin-top: -10px;
                }
                ul {
                    padding: 0 0 20px 15px;
                    list-style-type: disc;
                    li:nth-child(odd) {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    figure.spacer {
        width: 100%;
        height: 50px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .fact-box {
        background-color: $lightGray;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px;

        &.white {
            color: $white;
        }

        .ak-puff {
            position: absolute;
            top: 32px;
            margin: 0;
            padding: 0;
            z-index: 10;
            @include media-breakpoint-down(sm) {
                display: none;
            }

            .puff-content {
                position: relative;
                z-index: 3;
                display: inline-block;
                background-color: $white;
                padding: 12px 18px;
                color: $black;
                font-family: $tideBunny,sans-serif;
                text-transform: uppercase;
                font-size: 22px;
                line-height: 26px;
                margin: 0;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                max-width: 200px;
            }

            &:after {
                content: '';
                z-index: 1;
                position: absolute;
                width: 100%;
                height: calc(100% + 10px + 40px);
                background-color: $yellow;
                left: 0;
                top: 0;
                padding: 10px 0 30px 0;
                -webkit-transform: translate(-28px,-18px);
                transform: translate(-28px,-18px);
            }
        }

        .editor_content {
            h3 {
                &.white {
                    color: $white;
                }
            }
        }

        &.one-col {
            .ak-puff {
                right: 32px;
            }
        }

        &.two-col {
            .right-col {
                padding-top: 140px;
                @include media-breakpoint-down(sm) {
                    padding-top: 0;
                }

                .ak-puff {
                    top: 16px;
                    left: 42px;
                    .puff-content {
                        max-width: 280px;
                    }
                }
            }

            .title {
                padding-left: 0;
            }
        }

        .title {
            h3 {
                @include h3();
                font-size: 18px;
                line-height: 26px;
                border-left: 3px solid $yellow;
                padding-left: 13px;
                transform: translateX(-15px);

                &.white {
                    color: $white;
                }
            }
        }
    }

    .contentblock-table {
        table {
            width: 100%;
            &.col1 {
                th, td {
                    &:first-child {
                        color: $magenta;
                    }
                }
            }

            &.col2 {
                th, td {
                    &:nth-child(2) {
                        color: $magenta;
                    }
                }
            }
            &.col3 {
                th, td {
                    &:nth-child(3) {
                        color: $magenta;
                    }
                }
            }
            &.col4 {
                th, td {
                    &:nth-child(4) {
                        color: $magenta;
                    }
                }
            }
            &.col5 {
                th, td {
                    &:nth-child(5) {
                        color: $magenta;
                    }
                }
            }
            &.col6 {
                th, td {
                    &:nth-child(6) {
                        color: $magenta;
                    }
                }
            }
            &.col7 {
                th, td {
                    &:nth-child(7) {
                        color: $magenta;
                    }
                }
            }
            &.col8 {
                th, td {
                    &:nth-child(8) {
                        color: $magenta;
                    }
                }
            }
            &.col9 {
                th, td {
                    &:nth-child(9) {
                        color: $magenta;
                    }
                }
            }
            &.col10 {
                th, td {
                    &:nth-child(10) {
                        color: $magenta;
                    }
                }
            }
            th {
                font-family: $tideBunny,sans-serif;
                border-bottom: 1px solid $black;
            }

            td, th {
                border-left: 1px solid $black;
                padding: 5px 10px;
                line-height: 22px;

                &:first-child {
                    border-left: none;
                    padding-left: 0;
                }

                .strong {
                    font-family: $tideBunny,sans-serif;
                }

                .magenta {
                    color: $magenta;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 11px;
                    padding: 5px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                }
            }

            &.lines {
                tr {
                    border-bottom: 1px solid $black;
                    &:nth-child(2) {
                        border-top: 1px solid $black;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

    }

    & + .cta-akademi {
        margin-top: -60px;

        @include tabletLandscape {
            margin-top: -20px;
        }

        @include tablet {
            margin-top: -20px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }
    }

}
