section.component.quote-block {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    margin: 0 0 120px 0;

    @include media-breakpoint-down(sm) {
        padding: 30px 0;
        margin: 0 0 45px 0;
    }

    @include tabletLandscape {
        padding: 60px 0;
        margin: 0 0 60px 0;
    }

    @include tablet {
        padding: 50px 0;
        margin: 0 0 50px 0;
    }

    .quote-slider {
        .owl-item {
            padding: 0 0 50px 0;

            @include tablet {
                width: 100%;
            }

        }
    }

    blockquote {
        position: relative;
        margin: 0;
        padding: 34px;

        @include media-breakpoint-down(sm) {
            padding: 20px 20px 30px 20px;
            //margin: 0 0 50px 0;
        }

        p {
            font-family: 'Times', serif;
            font-style: italic;
            color: $white;
            font-size: 35px;

            @include media-breakpoint-down(sm) {
                font-size: 22px;
                line-height: 1.22em;
            }

            @include tablet {
                font-size: 28px;
                line-height: 1.22em;
            }

            &:before { content: '“'; }
            &:after { content: '”'; }
        }


        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 34px;
            position: absolute;
            top: 0;
            display: block;
            border: 1px solid $white;
        }

        &:before {
            border-right: 0;
            left: 0;
        }

        &:after {
            border-left: 0;
            right: 0;
        }

        em {
            display: inline-block;
            position: absolute;
            right: 60px;
            top: 100%;
            background-color: $yellow;
            font-style: normal;
            font-family: $tideBunny;
            font-size: 16px;
            padding: 15px;
            transform: translateY(-50%);
            span {
                display: block;
                font-family: $tideKahuna;
                font-size: 14px;
            }

            @include media-breakpoint-down(sm) {
                top: 105%;
            }
        }
    }

}
