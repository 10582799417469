section.component.small-map-component {
    margin-bottom: 120px;

    &.hide {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
    }

    .small-map-wrapper {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;

        .editor_content {
            background-color: $white;
            position: relative;
            width: 100%;
            max-width: 380px;
            transform: translateX(-280px);
            padding: 30px 30px 30px 20px;
            z-index: 9999;

            @include media-breakpoint-down(sm) {
                position: static;
                transform: translate(0,0);
                padding: 0 0 20px 0;
            }

        }

        .small-map {
            width: 100%;
            background-color: $magenta;
            min-height: 430px;

            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;

            @include media-breakpoint-down(sm) {
                height: 230px;
                position: static;
            }

            @include media-breakpoint-down(md) {
                min-height: 300px;
            }

        }

    }
}

section.component.arkitektkopia-map-container {
    position: relative;
    margin-bottom: 160px;

    @include tablet {
        &:after {
            content: '';
            display: table;
            width: 100%;
            clear: both;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-bottom: 80px;
    }

    .ak-large-map {
        width: 100%;
        height: 660px;
        margin-bottom: 50px;

        @media screen and (max-width: 711px) {
            height: 400px;
            margin-bottom: 0;
        }

        @include tabletLandscape {
            height: 500px;
        }

        @include tablet {
            height: 420px;
        }

    }

    .iwCross {
        // border: 2px solid red;
        width: 20px!important;
        height: 20px!important;
        right: 42px!important;
        top: 13px!important;
        opacity: 1!important;
        img {
            display: none;
        }

        &:after,
        &:before {
            width: 20px;
            height: 1px;
            content: '';
            background-color: $yellow;
            position: absolute;
            left: 0;
            top: 50%;
        }

        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }

    }

    .gm-style-iw {
        width: auto!important;
        min-width: 290px!important;
        background-color: rgb(0,0,0);

        .gm-style-iw-d::-webkit-scrollbar-track-piece {
            background-color: rgb(0,0,0);
        }

        .gm-style-iw-d {
            min-width: 278px!important;
        }

        button {
            background-image: url('../img/close-printshop.svg') !important;
            background-repeat: no-repeat!important;
            top: 8px!important;
            right: 8px!important;
            width: 20px!important;
            height: 20px!important;
            &.gm-ui-hover-effect {
                opacity: 1;
            }

            img {
                display: none!important;
            }
        }

    }

    .markers-inner {
        background-color: rgba(0,0,0,.9);
        padding: 10px 20px 20px 20px;

        h4 {
            color: $white;
            font-family: $tideBunny;
            font-size: 16px;
            font-weight: normal;
            text-transform: uppercase;
            margin-bottom: 1em;

            &:before {
                width: 19px;
                height: 27px;
                content: '';
                display: inline-block;
                background-image: url('../img/small-yellow-pin.png');
                background-repeat: no-repeat;
                background-size: 19px 27px;
                margin-right: 6px;
                transform: translateY(6px);
            }

        }

        p {
            color: $white;
            font-family: $tideKahuna;
            font-size: 14px;
            margin: 0;
            font-weight: normal;
            line-height: 1.66em;
            &:last-of-type {
                margin-bottom: 16px;
            }
        }

        .map-btn {
            display: inline-block;
            text-decoration: none;
            width: 220px;
            background-color: $white;
            color: $magenta;
            font-family: $tideBunny;
            text-align: center;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
        }
    }

    .container-fluid {
        @include tablet {
            width: 50%;
            max-width: 50%;
            float: left;
        }
    }

    .map-side-content {
        width: 100%;
        max-width: 390px;
        padding: 40px 64px 40px;
        position: absolute;
        bottom: 80px;
        right: 50px;
        z-index: 9998;

        @include media-breakpoint-down(sm) {
            position: static;
            padding: 30px 15px 30px;
        }

        @include tablet {
            position: static;
            width: 50%;
            max-width: 100%;
            bottom: auto;
            right: auto;
            padding: 0 35px 30px;
            float: left;
        }

        @include tabletLandscape {
            bottom: -55px;
        }

        * {
            z-index: 3;
            position: relative;
        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $white;
            z-index: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &:after {
            width: calc(100% + 40px);
            height: calc(100% - 20px);
            content: '';
            top: 40px;
            left: -20px;
            position: absolute;
            border: 1px solid $black;
            z-index: -1;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }
    }

    .payoff p {
        border-left: 3px solid $yellow;
        padding-left: 30px;
        @include paragraph;

        @include tabletLandscape {
            max-width: 80%;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
            transform: translateX(-15px);
        }

    }



    .map-dropdown-container {
        width: 390px;
        height: 51px;
        background-color: $white;
        position: absolute;
        top: 70px;
        right: 50px;
        transition: background-color .3s;
        z-index: 9999;

        @include media-breakpoint-down(sm) {
            width: auto;
            left: 15px;
            right: 15px;
            top: 15px;
            height: 40px;
        }

        @include tablet {
            top: 15px;
            left: 15px;
        }


        &.list-visible {
            background-color: $yellow;
            p.printshop-list-trigger span {
                transform: rotate(-180deg);
            }
        }

        p.printshop-list-trigger, #sectional_nav {
            display: block;
            width: 100%;
            height: 51px;
            line-height: 49px;
            color: $black;
            font-size: 16px;
            padding-left: 15px;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                height: 40px;
                line-height: 39px;
                font-size: 14px;
            }

            span {
                width: 51px;
                height: 51px;
                position: absolute;
                right: 0;
                top: 0;
                text-align: center;
                transition: transform .3s;
                @include media-breakpoint-down(sm) {
                    width: 40px;
                    height: 40px;
                }

                i {
                    font-size: 20px;
                    @include media-breakpoint-down(sm) {
                        font-size: 16px;
                    }
                }
            }
        }

        #sectional_nav {
            display: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border: none;
            background-color: inherit;

            &::after {
                content: "\25BC";
                position: absolute;
                height: .8em;
                font-size: .6em;
                line-height: 1;
                right: .9em;
                top: 50%;
                margin-top: -.5em;
                color: $black;
            }

            &+.fa {
                display: none;
                position: absolute;
                top: 10px;
                right: 16px;
                font-size: 20px;
            }
        }

        @media only screen and (min-device-width : 300px) and (max-device-width : 768px) {
             p.printshop-list-trigger {
                 display: none;
             }

             #sectional_nav {
                 display: block;
                 &+.fa {
                     display: block;
                 }
             }
         }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
            p.printshop-list-trigger {
                display: none;
            }

            #sectional_nav {
                display: block;
                &+.fa {
                    display: block;
                }
            }
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : landscape) {
            p.printshop-list-trigger {
                display: none;
            }

            #sectional_nav {
                display: block;
                &+.fa {
                    display: block;
                }
            }
        }

        ul.printshop-list {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            background-color: $white;
            list-style: none;
            border: 1px solid $black;
            max-height: 400px;
            overflow-y: scroll;
            display: none;

            @include media-breakpoint-down(sm) {
                max-height: 300px;
            }


            li {
                width: 100%;
                display: block;
                height: 34px;
                line-height: 34px;

                &:first-of-type { margin-top: 10px; }
                &:last-of-type { margin-bottom: 10px; }

                &:nth-child(odd) {
                    a {
                        background-color: $lightGray;
                    }
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: $magenta;
                    padding-left: 15px;
                    text-decoration: none;
                    transition: background-color .2s ease;
                    &:hover {
                        background-color: $yellow;
                        color: $black;
                    }
                }
            }

        }

    }

}
