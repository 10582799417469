section.component.pricing {
    margin-bottom: 50px;
    .price-wrapper {
        background-color: $lightGray;
        height: 100%;
        display: flex;
        flex-direction: column;

        .price-name {
            padding: 20px 15px;
            text-align: center;
            &.magenta {
                background-color: #CD007A;
            }
            &.yellow {
                background-color: #FFE600;
            }
            &.pink {
                background-color: #F49AC1;
            }
            &.black {
                background-color: #231F20;
            }
            &.blue {
                background-color: #75BEE9;
            }
            &.gray {
                background-color: #9D9FA2;
            }
            &.green {
                background-color: #7CC366;
            }
        }
        .h2.the-price {
            text-align: center;
            padding: 20px 15px;
        }

        .payment-period {
            background: rgba(255,255,255,0.3);
            padding: 5px 10px 5px;
        }
        .content {
            padding: 20px 15px;


        }
    }
    .ak-buttons-container {
        text-align: center;
        margin-top: auto;
        padding: 0 15px 20px 15px;
        a.magenta span, a.white span {
            @include media-breakpoint-down(md) {
                min-width: 190px;
            }
        }
    }
    .price {
        @include media-breakpoint-down(xs) {
            margin-top: 30px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
