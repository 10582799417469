section.component.contact-people {
    .contact-wrapper {
        margin-bottom: 120px;
        position: relative;

        @include media-breakpoint-down(sm) {
            margin-bottom: 50px;
        }

        @include tabletLandscape {
            margin-bottom: 60px;
        }

        @include tablet {
            margin-bottom: 70px;
        }

        img {
            width: 100%;
            max-width: 320px;
            height: auto;
            @include media-breakpoint-down(sm) {
                max-width: 320px;
            }
        }

        .editor_content {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-30%);
            background-color: $white;
            width: 55%;
            padding: 20px;

            @include media-breakpoint-down(md) {
                top: auto;
                bottom: 0;
                width: 100%;
                max-width: 320px;
                transform: translateY(50px);
                right: -15px;
                padding: 15px;
            }

            h3 {
                text-transform: none;
                font-size: 19px;
                font-weight: normal;
                margin: 0 0 .2em 0;
            }

            a {
                position: relative;
                transition: color .2s;
                &:after {
                    content: '';
                    width: 0;
                    height: 1px;
                    background-color: $yellow;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: 0;

                    transition: width .4s $easeInOutExpo;
                }

                &:hover {
                    color: $black;
                    &:after {
                        width: 100%;
                    }
                }

                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }
                @include tabletLandscape {
                    font-size: 13px;
                }

            }


        }
    }
}
