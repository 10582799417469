section.threeD-model {
    .threeD-object {
        width: 1000px;
        height: 800px;

        @include tablet {
            width: 640px;
            height: 512px;
        }

        @include media-breakpoint-down(sm) {
            width: 300;
            height: 240px;
        }

        #canvas {
            width: 100%;
            height: 100%;
        }
    }
}

.threeD-model-mobile {
    .editor_content {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;

        @media screen and (max-width: 414px) {
            height: 520px;
        }

        @media screen and (max-width: 375px) {
            height: 470px;
        }

        @media screen and (max-width: 360px) {
            height: 450px;
        }

        @media screen and (max-width: 320px) {
            height: 400px;
        }

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

}
