// Content from the WordPress editor
.editor_content {

    &.center {
        text-align: center;
    }

    &.white {
        color: $white;
    }

    &.intro-block.gray {
        position: relative;
        z-index: 5;
        padding: 60px 0;
        background-color: $lightGray;

        @include media-breakpoint-down(sm) {
            padding: 30px 0;
            margin-bottom: 30px;
        }

        @include tabletLandscape {
            margin-bottom: 60px;
            padding: 45px 0;
        }

        @include tablet {
            padding: 50px 0;
            margin-bottom: 50px;
        }

    }

    h1, .h1 {
        @include h1($black);
    }

    h2, .h2 {
        @include h2();

        &.yellow-border {
            border-left: 3px solid $yellow;
            padding-left: 30px;
            @include media-breakpoint-down(sm) {
                padding-left: 15px;
                transform: translateX(-15px);
            }
        }

        &.yellow-line {
            position: relative;
            margin-bottom: 20px;
            &:before {
                width: 3px;
                height: calc(100% - 4px);
                content: '';
                position: absolute;
                background-color: $yellow;
                left: -23px;
                top: 2px;
                @include media-breakpoint-down(sm) {
                    left: -15px;
                }
            }
        }

    }

    h3, .h3 {
        @include h3();
    }

    h1,h2,h3,h4 {
        &.section-title {
            font-weight: normal;
            @include sectionTitle($white);
        }
    }

    p {
        @include paragraph;

        &.preamble {
            @include preamble;
        }

        strong {
            font-family: $tideBunny;
            font-weight: normal;
        }

        &:last-child {
            margin: 0 0 0 0;
        }

        a {
            text-decoration: none;
            color: $magenta;
            transition: color .2s;
            &:hover {
                color: $black;
            }
        }
    }

    a.arrow-link {
        &.magenta {
            @include arrow-link($magenta, $black);
        }
        &.file {
            i {
                margin-right: 5px;
            }
        }
    }

    ul, ol {
        font-family: $tideKahuna;
        font-size: 14px;
        line-height: 22px;
        padding: 0 0 0 14px;
        li {
            margin: 0 0 0 0;
            a {
                color: $magenta;
                text-decoration: none;
                transition: color .2s;
                &:hover {
                    color: $black;
                }
            }
        }
    }

    blockquote, q {}

    q.blue-q {
        display: block;
        width: 100%;
        position: relative;
        padding: 30px;
        font-family: 'Times', serif;
        font-style: italic;
        color: $blue;
        font-size: 35px;


        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            border: 1px solid $blue;
            width: 50px;
        }

        &:before {
            left: 0;
            border-right: 0;
        }

        &:after {
            right: 0;
            border-left: 0;
        }

        &.pink {
            color: $magenta;

            &:before,
            &:after {
                border: $magenta;
            }
        }
    }

    .wp-caption {
        margin: 40px 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        border: none;
        position: relative;

        @include media-breakpoint-down(sm) {
            margin: 30px 0 10px;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .wp-caption-text {
            position: absolute;
            left: calc(100% - 60px);
            bottom: 40px;
            display: block;
            max-width: 230px;
            width: 100%;
            text-align: left;
            font-family: $tideKahuna;
            font-size: 14px;
            line-height: 22px;
            color: $blue;
            background-color: $white;
            margin: 0;
            padding: 15px 20px;

            @include media-breakpoint-down(sm) {
                position: static;
                max-width: 100%;
                padding: 5px 0;
            }

            @include tablet {
                position: static;
                max-width: 100%;
                padding: 5px 0;
            }

        }

    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0;
    }

    ul.ak-products-list {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        columns: 4;
        column-gap: 30px;

        &.margin-above {
            margin-top: 50px;
        }

        @include media-breakpoint-down(sm) {
            columns: 2;
            column-gap: 15px;
        }

        @include tabletLandscape {
            columns: 3;
            column-gap: 15px;
        }

        li {
            display: block;
            margin: 0 0 5px 0;
            a {
                text-decoration: none;
                color: $black;
                i {
                    color: $magenta;
                    margin-right: 5px;
                }
            }
        }
    }

    .content-columns {
        max-width: 100%;
        display: flex;
        p {
            display: block;
            max-width: 50%;
        }
    }

    a.icon-link {
        font-weight: normal;
        font-family: $tideBunny;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
        margin-bottom: 0.4em;
        transition: color .2s;
        position: relative;

        &:hover {
            color: $black;
            &:after {
                width: 100%;
            }
        }

        &:after {
            content: '';
            width: 0;
            height: 6px;
            background-color: $yellow;
            display: block;
            position: absolute;
            right: -5px;
            bottom: 1px;
            z-index: -1;
            transition: width .2s;
            backface-visibility: hidden;
        }


        i {
            font-size: 20px;
            margin-right: 5px;
        }
    }

}


section.hero + section.intro-block.gray {
    margin-bottom: 120px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 15px;
    }
}

img.img-responsive {
    max-width: 100%;
    height: auto;
}
