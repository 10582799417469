.site-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $white;
    -webkit-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.07);
    -moz-box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.07);
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.07);
    z-index: 9999;
    opacity: 1;
    transition: opacity .2s, transform .2s;

    &.not-visible {
        opacity: 0;
        transform: translateY(-100%);
    }

    nav.navbar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @include menu-breakpoint {
            justify-content: flex-end;
        }

        a.site-logotype {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 180px;
            margin: 0;
            padding: 0;
            line-height: 1;

            @include menu-breakpoint {
                max-width: 130px;
            }

        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            height: 78px;
            display: flex;
            align-items: center;
            line-height: 1;

            @include menu-breakpoint {
                height: 55px;
            }



            &.header-right {
                li {
                    margin-left: 30px;

                    @include menu-breakpoint {
                        margin-left: 0!important;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        @include menu-breakpoint {
                            display: none;
                        }
                    }

                }
            }

            &.header-left {
                @include menu-breakpoint {
                    display: none;
                }
                li {
                    margin-right: 30px;
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            li {
                display: inline-block;

                &.current-menu-item,
                &.active-item {
                    a:after {
                        width: 100%;
                    }
                }

                a {
                    color: $black;
                    text-decoration: none;
                    font-family: $tideBunny;
                    text-transform: uppercase;
                    font-size: 14px;
                    line-height: 1;
                    position: relative;
                    display: block;

                    &:hover {
                        &:after {
                            width: 100%;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 10px;
                        background-color: $yellow;
                        transform: translate(5px, -9px);
                        position: absolute;
                        right: 0;
                        top: 100%;
                        z-index: -1;
                        transition: width .2s $easeInOutExpo;
                        pointer-events: none;
                    }

                }

                button {
                    cursor: pointer;
                    outline: none;
                    margin: 0;
                    padding: 0;
                    border: none;
                    background: none;
                }

                &.burger {
                    margin-left: 15px;
                    height: 32px;
                    button {
                        width: 32px;
                        height: 32px;
                        position: relative;
                        vertical-align: top;

                        &.offcanvas-visible {

                            &:hover {
                                span {
                                    &:after,
                                    &:before {
                                        width: 100%;
                                    }
                                }
                            }

                            span {
                                background-color: transparent;
                                &:after {
                                    width: 100%;
                                    transform: rotate(-135deg);
                                }

                                &:before {
                                    width: 100%;
                                    transform: rotate(135deg);
                                }
                            }
                        }

                        &:hover {
                            span {
                                &:before {
                                    width: 75%;
                                }
                                &:after {
                                    width: 100%;
                                }
                            }
                        }

                        span {
                            width: 19px;
                            display: block;
                            height: 2px;
                            background-color: $black;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            margin-top: -1px;
                            transition: background-color .2s;

                            &:after,
                            &:before {
                                content: '';
                                width: 100%;
                                height: 2px;
                                position: absolute;
                                background-color: $black;
                                right: 0;
                                top: 0;
                                transition: width .2s ease, transform .2s $easeInOutExpo;
                                backface-visibility: hidden;
                                *zoom:1;
                            }

                            &:before {
                                transform: translateY(-5px);
                            }
                            &:after {
                                transform: translateY(5px);
                                width: 75%;
                            }
                        }
                    }
                }

                &.search {
                    margin-left: 15px;
                    height: 32px;
                    button {
                        width: 32px;
                        height: 32px;
                        text-align: right;
                        position: relative;

                        i {
                            position: relative;
                            z-index: 1;
                        }

                        &:hover,
                        &.search-active {
                            &:after {
                                width: 12px;
                            }
                        }

                        &:after {
                            height: 10px;
                            width: 0;
                            content: '';
                            position: absolute;
                            right: -3px;
                            top: 16px;
                            background-color: $yellow;
                            z-index: 0;
                            transition: width .2s $easeInOutExpo;
                        }

                    }
                }
            }
        }
    }


    form.search-form {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background-color: $yellow;
        padding: 10px;
        z-index: -1;
        border-top: 1px solid #e5cf00;
        display: none;

        .search-field {
            width: calc(100% - 100px);
            margin: 0;
            padding: 0;
            display: block;
            float: left;
            height: 32px;
            border: none;
            background: none;
            border-left: 1px solid $magenta;
            padding-left: 15px;
            font-size: 16px;
            font-family: $tideKahuna;
            border-radius: 0;

            @include input-placeholder {
                color: $black;
                opacity: 1;
            }

        }

        .search-submit {
            width: 100px;
            margin: 0;
            padding: 0;
            display: block;
            float: left;
            height: 32px;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;

            text-align: right;
            color: $magenta;
            font-size: 16px;
            font-family: $tideBunny;
            text-transform: uppercase;
        }

    }
}

body.search-results {
    header.site-header {
        form.search-form {
            display: block;
        }

        li.search button:after {
            width: 12px;
        }

    }
}

body.tax-producttype,
body.single-products {
    .vad-vi-gor-menu-item {
        a:after {
            width: 100%!important;
        }
    }
}
