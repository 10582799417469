section.component.references {
    margin-bottom: 100px;
    margin-top: -90px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
        margin-top: -20px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
        margin-top: -50px;
    }

    .reference {
        position: relative;
        overflow: hidden;
        margin: 0 0 30px 0;

        @include media-breakpoint-down(sm) {
            margin: 0 0 15px 0;
        }

        &:hover {
            .reference-content {
                top: 0;
                transform: translateX(0);
                h4 {
                    transform: translate(0, 0);
                    span {
                        opacity: 0;
                        width: 0;
                        margin-right: 14px;
                    }
                }
            }
        }

        .reference-content {
            background-color: transparentize($yellow, .15);
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: transform .4s $easeInOutExpo, top .4s $easeInOutExpo;
        }

        &.small-reference {
            .reference-content {
                p {
                    font-size: 25px;
                    @include media-breakpoint-down(sm) {
                        font-size: 23px;
                    }
                    @include tablet {
                        display: none;
                    }
                }
            }
        }

        &.big-reference {
            .reference-content {
                p {
                    font-size: 35px;
                    @include media-breakpoint-down(sm) {
                        font-size: 23px;
                    }
                    @include tablet {
                        font-size: 28px;
                    }
                }
            }
        }

        h4 {
            margin: 0;
            padding: 14px 14px 14px 0;
            line-height: 1;
            display: table;
            width: auto;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $white;
            font-family: $tideBunny;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 16px;
            z-index: 1;
            transform: translate(100%, -100%);
            transition: transform .4s $easeInOutExpo;

            span {
                display: inline-block;
                width: 14px;
                height: 14px;
                position: relative;
                margin-right: 8px;
                overflow: hidden;
                transition: width .4s $easeInOutExpo, margin-right .4s $easeInOutExpo, opacity .1s ease;
                &:before,
                &:after {
                    content: '';
                    width: 14px;
                    height: 4px;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin: -1px 0 0 0;
                    background-color: $magenta;
                    border-radius: 1px;
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        q {
            position: absolute;
            left: 0;
            top: 50%;
            margin: 0;
            padding: 0 25px;
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            transform: translateY(-40%);
            text-align: center;

            &:after,
            &:before {
                display: none;
            }

            p {
                font-family: 'Times', serif;
                font-style: italic;
                color: $black;
                line-height: 1.2em;
                &:before { content: '“'; }
                &:after { content: '”'; }
            }

            a {
                width: auto;
                display: table;
                font-family: $tideBunny;
                color: $magenta;
                text-decoration: none;
                text-transform: uppercase;
                margin-top: 10px;
                position: relative;
                transition: color .2s ease;
                z-index: 1;
                margin: 0 auto;

                &:hover {
                    color: $black;
                    &:after {
                        width: 100%;
                    }
                }

                &:after {
                    content: '';
                    width: 0;
                    height: 10px;
                    background: $white;
                    position: absolute;
                    transition: width .2s $easeInOutExpo;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    transform: translate(5px, -1px);
                    backface-visibility: hidden;
                    *zoom:1;
                }

                i {
                    margin-left: 4px;
                }
            }
        }
    }


    // Masonrylayout
    .my-flex-columns {
        width: 100%;
        margin-left: -15px;
        margin-right: -15px;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        .column {
            position: relative;
            backface-visibility: hidden;
            *zoom:1;

            @include media-breakpoint-down(sm) {
                padding: 7.5px 0;
                margin-bottom: 7.5px;
            }

            &.small-col {
                width: 33.3333333333%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                @include tablet {
                    width: 33.3333333333%;
                }

                .inner-content .hidden-content .hidden-content-inner q {
                    font-size: 25px;
                }

            }

            &.big-col {
                width: 66.6666666667%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                @include tablet {
                    width: 66.6666666667%;
                }

                p.inspiration-link {
                    white-space: nowrap;
                }

            }

            img {
                width: 100%;
                height: auto;
            }

            &:hover {
                .inner-content {
                    .hidden-content {
                        transform: translate(0, 0);
                    }

                    p.inspiration-link {
                        bottom: 100%;
                        //right: 0;
                        left: 100%;
                        transform: translate(-100%, 100%);
                        padding: 10px 10px 10px 10px;
                        i {
                            opacity: 0;
                        }
                    }
                }
            }

            .column-inner {
                padding-bottom: 75%;
                position: relative;
            }

            .inner-content {
                width: auto;
                display: block;
                position: absolute;
                top: 15px;
                left: 15px;
                right: 15px;
                bottom: 15px;
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;

                @include media-breakpoint-down(sm) {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                .hidden-content {
                    background-color: rgba(255, 230, 0, .85);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    transform: translate(-100%, 100%);

                    transition: transform .3s;

                    .hidden-content-inner {
                        max-width: 100%;
                        width: 100%;
                        padding: 0 30px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;

                        q {
                            display: inline-block;
                            width: 100%;
                            font-family: 'Times', serif;
                            font-size: 35px;
                            font-style: italic;
                            line-height: 1;
                            margin-bottom: 13px;
                        }

                        a {
                            font-family: $tideBunny;
                            font-size: 14px;
                            text-transform: uppercase;
                            color: $magenta;
                            text-decoration: none;
                            i {
                                margin-left: 5px;
                            }
                        }

                    }

                }

            }

            p.inspiration-link {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $white;
                padding: 10px 10px 10px 20px;
                color: $black;
                text-transform: uppercase;
                text-decoration: none;
                font-family: $tideBunny;
                font-size: 14px;
                margin: 0;
                z-index: 10;
                text-align: left;


                @include media-breakpoint-down(sm) {
                    bottom: 0;
                }

                transition: bottom .3s, left .3s, transform .3s, padding .3s;

                // bottom: 100%;
                // right: 0;
                // left: auto;
                // transform: translate(0, 100%);

                i {
                    color: $magenta;
                    margin-right: 5px;
                    transition: opacity .3s;
                    position: absolute;
                    left: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

        }

    }

}

section.component.referenceslider {
    margin-top: -90px;
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
        margin-top: -30px;
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-top: -50px;
        margin-bottom: 60px;
    }

    @include tablet {
        margin-top: -60px;
        margin-bottom: 50px;
    }

    .owl-nav.disabled {
        display: block!important;
    }

}
