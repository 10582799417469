footer.site-footer {
    width: 100%;
    background-image: url('../img/footer-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0;

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    .editor_content {

        ul.social-media {
            margin: 0 0 30px 0;
            padding: 0;
            width: 100%;

            @include media-breakpoint-down(sm) {
                margin: 0 0 20px 0;
            }

            li {
                display: inline-block;
                margin: 0 15px;

                &.mnd {
                    max-width: 33px;
                    transform: translateY(3px);
                    img {
                        max-width: 100%;
                        height: auto;
                        display: block;
                    }
                }

                a {
                    color: $white;
                    font-size: 38px;
                    line-height: 1;
                }
            }
        }

        p {
            font-size: 14px;
            display: inline-block;
            width: 100%;
            margin: 0 0 40px 0;
            @include media-breakpoint-down(sm) {
                margin: 0 0 20px 0;
            }
            a {
                color: $white;
                text-decoration: none;
                font-size: 14px;
                transition: color .2s;
                &:hover {
                    color: $yellow;
                }
            }
        }

        .footer-buttons {
            a.button {
                @include white-line-button();
                background-color: transparent;
                margin: 15px;

                &:first-of-type {
                    background-color: $white;
                    span {
                        color: $black;
                        border: 1px solid $black;
                    }
                }

            }
        }

    }

}

.site-footer-payoff {
    text-align: center;
    padding: 20px 0;
    p {
        font-family: $tideBunny;
        text-transform: uppercase;
        margin: 0;

        i {
            color: $magenta;
            font-size: 22px;
        }

        a {
            color: $magenta;
            text-decoration: none;
            display: inline-block;
            position: relative;
            transition: color .2s ease;

            &:hover {
                color: $black;
                &:after {
                    width: 100%;
                }
            }

            &:after {
                content: '';
                display: block;
                width: 0px;
                height: 1px;
                background-color: $yellow;
                position: absolute;
                right: 0;
                top: 100%;
                transition: width .2s ease;
            }

        }

    }
}
