section.component.content-block.bar {
    margin-bottom: 0;
    width: 100%;
    background-color: $yellow;
    padding: 17px;
    border-top: 1px solid #e5cf00;
    .link {
        text-align: right;
    }

    p {
        font-family: $tideBunny;
        font-size: 16px;
        border-left: 2px solid $magenta;
        padding-left: 15px;
    }

    .editor_content a.arrow-link.magenta {
        line-height: 0;
        text-transform: inherit;
    }

}
