nav.mega-menu {
    position: fixed;
    left: 0;
    top: 78px;
    width: 100%;
    z-index: 900;
    height: calc(100vh - 78px);

    background-image: url('../img/perspective-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    display: none;

    overflow-y: auto;


    .select2-container--default {
        margin-top: 30px;
    }


    button.close-menu {
        display: block;
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 0!important;
        line-height: 1;
        position: absolute;
        top: 30px;
        right: 30px;
        border: none;
        background: none;
        cursor: pointer;

        span {
            display: block;
            width: 50px;
            height: 50px;

            &:after,
            &:before {
                content: '';
                height: 2px;
                width: 50px;
                display: block;
                background-color: $yellow;
                position: absolute;
                top: 50%;
                left: 0;
            }

            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

    }


    div.editor_content {
        padding-top: 50px;
        h3 {
            color: $white;
            display: inline-block;
            width: 100%;
            margin-bottom: .4em;
        }
    }

    a.editor_content {
        color: $white;
        margin-bottom: 40px;
        display: block;
        text-decoration: none;
        em {
            font-style: normal;
            color: $yellow;
            font-family: $tideBunny;
            text-transform: uppercase;
            font-size: 16px;
            margin: 0 0 .3em 0;
            display: inline-block;
            width: 100%;
        }

        p {
            margin: 0;
            font-family: $tideKahuna;
            font-size: 14px;
            color: $white;
        }

        ul {
            margin: 0 0 0 14px;
            padding: 0;
            //list-style: none;
            li {
                font-family: $tideKahuna;
                font-size: 14px;
                color: $white;
            }
        }

    }


}
