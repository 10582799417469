main.search-result {
    padding-top: 200px;
    min-height: calc(100vh - 395px);

    @include media-breakpoint-down(sm) {
        padding-top: 140px;
    }

    .search-row {
        h1 {
            margin: 0 0 1em 0;
            @include media-breakpoint-down(sm) {
                margin: 0 0 .5em 0;
            }
        }
    }

    .no-result {
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    .search-group {
        margin-bottom: 80px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        .section-title {
            display: inline-block;
            width: 100%;
            text-align: center;
            @include sectionTitle($black);
            font-weight: normal;
            margin: 0 0 2em 0;
            em {
                font-style: normal;
            }
        }


        .post-row {
            &:last-of-type {
                margin-bottom: 0;
            }
            margin-bottom: 60px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }

            .featured-image {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 15px;
                }
            }

            .h2 {
                a {
                    color: $magenta;
                    text-decoration: none;
                    position: relative;
                    transition: color .2s;
                    &:hover {
                        color: $black;
                    }
                }
                margin: 0 0 .25em 0;
            }

            em {
                font-style: normal;
                font-size: 16px;
                color: $gray;
                display: block;
                width: 100%;
                margin: 0 0 1em 0;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
        }
    }
}
