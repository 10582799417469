// Colors
$yellow: #FFE600;
$lightGray: #EEEDED;
$pink: #F49AC1;
$black: #231F20;
$white: #FFF;
$blue: #75BEE9;
$gray: #9D9FA2;
$magenta: #CD007A;
$green: #7CC366;
$gold: #b79028;
$goldGradient: linear-gradient(90deg,#b79028 0,#ffe517 25%,#ffca31 50%,#fff 75%,#ffde85 100%);
$goldGradientReverse: linear-gradient(270deg,#b79028 0,#ffe517 25%,#ffca31 50%,#fff 75%,#ffde85 100%);

$copper: #B87333;
$copperGradient: linear-gradient(90deg,#B87333 0,#A96424 25%,#9A5515 50%,#fff 75%,#C78242 100%);
$copperGradientReverse: linear-gradient(270deg,#B87333 0,#A96424 25%,#9A5515 50%,#fff 75%,#C78242 100%);


// Typorgrahy $tideKahuna, $tideBunny, $tideDude
@mixin sectionTitle($color: $white) {
    font-family: $tideBunny;
    text-transform: uppercase;
    line-height: 1;
    color: $color;
    font-size: 19px;
    font-weight: normal;
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
    &:before {
        content: '-';
        display: inline-block;
        margin-right: 5px;
    }
    &:after {
        content: '-';
        display: inline-block;
        margin-left: 5px;
    }
}

@mixin h1($color: $white) {
    color: $color;
    font-family: $tideBunny;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    margin: 0;

    @include media-breakpoint-down(md) {
        font-size: 45px;
        line-height: 50px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 35px;
        line-height: 40px;
    }

    @include tabletLandscape {
        font-size: 45px;
        line-height: 50px;
    }
}

@mixin subHeader($color: $white) {
    color: $color;
    font-family: $tideBunny;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
}

@mixin h2($color: $black) {
    color: $color;
    font-family: $tideKahuna;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        font-size: 25px;
        line-height: 30px;
    }

    @include tablet {
        font-size: 25px;
        line-height: 30px;
    }

}

@mixin h3($color: $black) {
    color: $color;
    font-family: $tideBunny;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 .4em 0;
}

@mixin preamble() {
    font-family: $tideKahuna;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 22px;
    }
}

@mixin paragraph() {
    font-family: $tideKahuna;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
}

@mixin imgText() {
    color: $blue;
    font-size: 14px;
    line-height: 22px;
    font-family: $tideKahuna;
    font-weight: normal;
}
// Animations & Gradients
