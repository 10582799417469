section.component.hero {
    padding: 120px 0;
    position: relative;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cbcbcb+0,d8d8d8+50,cbcbcb+100 */
    background: rgb(203,203,203);
    background: -moz-linear-gradient(top, rgba(203,203,203,1) 0%, rgba(216,216,216,1) 50%, rgba(203,203,203,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(203,203,203,1) 0%,rgba(216,216,216,1) 50%,rgba(203,203,203,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(203,203,203,1) 0%,rgba(216,216,216,1) 50%,rgba(203,203,203,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbcbcb', endColorstr='#cbcbcb',GradientType=0 ); /* IE6-9 */

    @include media-breakpoint-down(md) {
        padding: 60px 0 20px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 36px 0 20px 0;
    }

    @include tabletLandscape {
        padding: 80px 0;
    }

    &.margin-under {
        margin-bottom: 100px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
        @include tabletLandscape {
            margin-bottom: 60px;
        }
        @include tablet {
            margin-bottom: 50px;
        }
    }

    &.more-padding {
        padding: 120px 0 170px;

        @include media-breakpoint-down(sm) {
            padding: 36px 0 90px;
        }

        @include tabletLandscape {
            padding: 60px 0 80px;
        }

        @include tablet {
            padding: 80px 0 100px;
        }
    }

    &.widget-margin {
        margin-bottom: 200px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }

    &.no-margin-under {
        margin-bottom: 0;
        padding: 160px 0;
    }

    &.slider-margin-padding,
    &.widget-margin {
        padding: 120px 0 230px;
        margin-bottom: 240px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            padding: 36px 0 90px;
        }

    }

    &.widget-margin-smaller {
        padding: 120px 0 160px;
        margin-bottom: 220px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            padding: 36px 0 90px;
        }

    }

    &.top {
        z-index: 100;
    }

    &.animated {
        .background-image {
            animation-iteration-count: infinite;
            animation-name: hue-loop;
            animation-duration: 16s;
            animation-timing-function: linear;
        }
    }

    .background-image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    @keyframes hue-loop {
        0% { filter: hue-rotate(0); }
        12% { filter: hue-rotate(50deg); }
        24% { filter: hue-rotate(0); }
        36% { filter: hue-rotate(-50deg); }
        48% { filter: hue-rotate(-100deg); }
        60% { filter: hue-rotate(-150deg); }
        72% { filter: hue-rotate(-100deg); }
        84% { filter: hue-rotate(-50deg); }
        96% { filter: hue-rotate(-10deg); }
        100% { filter: hue-rotate(0); }
    }

    .hero-content {

        &.text-center {
            text-align: center;
        }

        &.black {
            .h1,
            .subtitle {
                color: $black;
            }
        }
        .h1 {
            @include h1;
            margin: 0 0 .4em 0;
            br {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
        .subtitle {
            font-family: $tideBunny;
            font-size: 22px;
            color: $white;
            text-transform: uppercase;

            &.lower-case {
                text-transform: none;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                line-height: 20px;
            }
            a {
                color: $yellow;
                text-decoration: none;
            }
        }

        time.product-cats {
            display: block;
            width: 100%;
            transform: translateY(-10px);
            margin-bottom: 16px;

            @include media-breakpoint-down(sm) {
                position: relative;
                padding: 8px 0 0 25px;
                margin-bottom: 10px;
            }

            &.yellow-border {
                border-left: 3px solid $yellow;
                padding-left: 15px;

                @include media-breakpoint-down(sm) {
                    padding: 0 0 0 15px;
                }

                a:hover {
                    color: $yellow;
                }
            }

            i {
                color: $gray;
                font-size: 16px;
                margin-right: 5px;
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    left: 0;
                    top: 12px;
                }
            }

            a {
                color: $gray;
                font-size: 16px;
                text-transform: uppercase;
                font-family: $tideBunny;
                text-decoration: none;
                transition: color .2s;

                @include media-breakpoint-down(sm) {
                    display: block;
                    span {
                        display: none;
                    }
                }

                &:hover {
                    color: $magenta;
                    span {
                        color: $gray;
                    }
                }

                span {
                    margin: 0 5px;
                    color: $gray!important;
                }

                &:last-child {
                    span {
                        display: none;
                    }
                }
            }
        }

        .academy-category-trigger {
            background-color: rgba(0,0,0,.5);
            font-family: TideSans-600Bunny,sans-serif;
            font-size: 12px;
            color: #ffe600;
            height: 34px;
            line-height: 34px;
            padding: 0 10px;
            margin: 30px 0 20px;
            -webkit-transition: color .2s,background-color .2s;
            transition: color .2s,background-color .2s;
            max-width: 350px;
            position: relative;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(xs) {
                margin-bottom: 30px;
            }

            span {
                width: 34px;
                height: 34px;
                position: absolute;
                right: 0;
                top: 0;
                text-align: center;
                -webkit-transition: -webkit-transform .3s;
                transition: -webkit-transform .3s;
                transition: transform .3s;
                transition: transform .3s,-webkit-transform .3s;
            }
        }
        .academy-dropdown-container {
            position: relative;
            &.list-visible {
                .academy-category-trigger {
                    span i {
                        -webkit-transform: rotate(-180deg);
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        ul.product-cats {
            margin: 30px 0 70px;
            padding: 0;
            width: 80%;
            list-style: none;

            &.academy {
                width: 100%;

                @include media-breakpoint-down(md) {
                    max-width: 350px;
                    margin: 0;
                    display: none;
                    position: absolute;
                    top: 34px;
                    transform: translateX(0);

                    li {
                        display: block;
                        margin: 0;

                        a {
                            width: 100%;
                            background-color: $white;
                            color: $magenta;
                        }

                        &:nth-child(odd) {
                            a {
                                background-color: $lightGray;

                                &:hover, &.active {
                                    background-color: $yellow;
                                    color: $black;
                                }
                            }


                        }


                    }
                }
            }

            @include tablet {
                margin: 30px 0 30px 0;
            }

            li {
                display: inline-block;
                margin: 0 8px 8px 0;

                a {
                    display: inline-block;
                    background-color: rgba(0, 0, 0, .5);
                    font-family: $tideBunny;
                    font-size: 12px;
                    color: $yellow;
                    text-decoration: none;
                    height: 34px;
                    line-height: 34px;
                    padding: 0 10px;
                    transition: color .2s, background-color .2s;

                    &:hover, &.active {
                        background-color: $yellow;
                        color: $black;
                    }
                }
            }
        }
    }

    time.post-meta {
        display: inline-block;
        border-left: 3px solid $yellow;
        font-family: $tideBunny;
        text-transform: uppercase;
        color: rgba(255,255,255,0.3);
        font-size: 16px;
        line-height: 1;
        padding-left: 4px;

        a {
            text-decoration: none;
            color: rgba(255,255,255,0.3);
            transition: color .2s;
            &:hover {
                color: $yellow;
            }
        }

    }

    .container-fluid {
        position: relative;
    }

    a.ak-puff {
        &:after,
        &:before {
            transition: transform .4s $easeInOutExpo;
            pointer-events: none;
        }

        .puff-content {
            transition: transform .4s $easeInOutExpo;
        }

        &:hover {
            &:before {
                transform: translate(-28px, -28px);
            }

            &:after {
                transform: translate(-58px, -58px);
            }
        }
    }

    .ak-puff {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(75px, -70%);
        margin: 0;
        padding: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include tabletLandscape {
            top: 50%;
            transform: translate(75px, 0);
        }


        @media screen and (max-width: 1320px) {
            transform: translate(-15px, -60%);
        }

        &:after {
            content: '';
            z-index: 1;
            position: absolute;
            width: 100%;
            height: calc(100% + 28px + 58px);
            background-color: $yellow;
            left: 0;
            top: 0;
            padding: 28px 0 58px 0;
            transform: translate(-28px, -28px);
        }

        &:before {
            content: '';
            z-index: 2;
            position: absolute;
            width: 100%;
            height: calc(100% + 28px + 58px);
            border: 1px solid $white;
            //background-color: $yellow;
            left: 0;
            top: 0;
            padding: 28px 0 58px 0;
            transform: translate(-58px, -58px);
        }

        .puff-content {
            position: relative;
            z-index: 3;
            display: inline-block;
            background-color: $white;
            padding: 15px 20px;
            color: $black;
            font-family: $tideBunny;
            text-transform: uppercase;
            font-size: 22px;
            line-height: 26px;
            margin: 0;
            backface-visibility: hidden;
            *zoom: 1;

            max-width: 280px;

            i {
                margin-left: 10px;
                color: $magenta;
            }

            &.max-width {
                max-width: 245px;
                min-width: 240px;
                width: 100%;
                padding: 30px;
                img {
                    max-width: 100%;
                    max-height: 100px;
                    width: auto;
                    height: auto;
                    display: block;
                    margin: 0 auto;
                }
            }

        }
    }

    &.black-friday {
        padding: 180px 0 160px;
        .background-image {
            height: 900px;
            background-position: center center;

            @include tablet {
                height: 830px;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 120px 0 60px;
        }

        .hero-content {
            .h1 {
                font-size: 90px;
                line-height: 90px;
                text-shadow: 2px 1px $gray, 11px 3px 16px $black;

                @include tablet {
                    font-size: 75px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 37px;
                    line-height: 45px;
                }
            }
            ul.product-cats li a {
                background-color: $yellow;
                color: $black;
                &:hover {
                    background-color: rgba(0,0,0,.5);
                    color: $yellow;
                }
            }
        }
    }
}

section.component.hero-widgets {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    transform: translateY(-50%);

    @include media-breakpoint-down(sm) {
        position: static;
        transform: translateY(0);
        margin-top: -40px;
    }


    .row .col-12 {
        &:nth-child(4),
        &:nth-child(3) {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        &:nth-child(4) {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    .hero-widget {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }

        &:hover {
            .hero-widget-content {
                top: 0;
                transform: translateX(0);
                h4 {
                    transform: translate(0, 0);
                    span {
                        opacity: 0;
                        width: 0;
                        margin-right: 14px;
                    }
                }
            }
        }

        .hero-widget-content {
            background-color: transparentize($yellow, .15);
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: transform .4s $easeInOutExpo, top .4s $easeInOutExpo;

            .widgetLink {
                display: block;
                height: 100%;
            }

            .widgetLink + h4, .widgetLink + h4 + p {
                pointer-events: none;
            }
        }

        h4 {
            margin: 0;
            padding: 14px 14px 14px 0;
            line-height: 1;
            display: table;
            width: auto;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $white;
            font-family: $tideBunny;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 16px;
            z-index: 1;
            transform: translate(100%, -100%);
            transition: transform .4s $easeInOutExpo;

            @include tabletLandscape {
                font-size: 14px;
            }

            span {
                display: inline-block;
                width: 14px;
                height: 14px;
                position: relative;
                margin-right: 8px;
                overflow: hidden;
                transition: width .4s $easeInOutExpo, margin-right .4s $easeInOutExpo, opacity .1s ease;
                &:before,
                &:after {
                    content: '';
                    width: 14px;
                    height: 4px;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin: -1px 0 0 0;
                    background-color: $magenta;
                    border-radius: 1px;
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        p {
            position: absolute;
            left: 0;
            top: 50%;
            margin: 0;
            padding: 0 25px;
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            transform: translateY(-40%);

            a {
                width: auto;
                display: table;
                font-family: $tideBunny;
                color: $magenta;
                text-decoration: none;
                text-transform: uppercase;
                margin-top: 10px;
                position: relative;
                transition: color .2s ease;
                z-index: 1;

                &:hover {
                    color: $black;
                    &:after {
                        width: 100%;
                    }
                }

                &:after {
                    content: '';
                    width: 0;
                    height: 10px;
                    background: $white;
                    position: absolute;
                    transition: width .2s $easeInOutExpo;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    transform: translate(5px, -1px);
                    backface-visibility: hidden;
                    *zoom:1;
                }

                i {
                    margin-left: 4px;
                }
            }
        }
    }


    .ak-slider {
        .hero-widget {
            width: 100%;
        }
    }

}


/* Hero on Contact page */
section.component.hero-widgets-contact {
    position: absolute;
    top: 100%;
    z-index: 10000;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    @include media-breakpoint-down(sm) {
        position: static;
        transform: translateY(0);
        margin-top: -40px;
    }

    @include tablet {
        .col-md-4 {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .hero-widget {
        background: $white;
        padding: 25px 25px 25px 50px;

        @include tablet {
            padding: 20px 15px 15px 40px;
        }

        a {
            width: auto;
            display: table;
            font-family: $tideBunny;
            color: $magenta;
            text-decoration: none;
            position: relative;
            transition: color .2s ease;
            z-index: 1;

            &:hover {
                color: $black;
                &:after {
                    width: 100%;
                }
            }

            &:after {
                content: '';
                width: 0;
                height: 10px;
                background: $yellow;
                position: absolute;
                transition: width .2s $easeInOutExpo;
                right: 0;
                bottom: 0;
                z-index: -1;
                transform: translate(5px, -1px);
                backface-visibility: hidden;
                *zoom:1;
            }

            i {
                margin-left: 4px;
            }

            &.large-link {
                font-size: 20px;
                font-family: $tideKahuna;
            }
        }

        .map-dropdown-container {
            position: relative;
            transition: background-color .3s;
            z-index: 9999;

            &.list-visible {
                background-color: $yellow;
                p.printshop-list-trigger span {
                    transform: rotate(-180deg);
                }
            }

            p.printshop-list-trigger, #sectional_nav {
                display: block;
                cursor: pointer;
                width: auto;
                display: table;
                width: auto;
                display: table;
                font-family: $tideBunny;
                color: $magenta;
                text-decoration: none;
                margin-top: 10px;
                position: relative;
                transition: color .2s ease;
                z-index: 1;
                font-size: 20px;
                font-family: $tideKahuna;

                &:hover {
                    color: $black;
                    &:after {
                        width: 100%;
                    }
                }

                &:after {
                    content: '';
                    width: 0;
                    height: 10px;
                    background: $yellow;
                    position: absolute;
                    transition: width .2s $easeInOutExpo;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    transform: translate(5px, -1px);
                    backface-visibility: hidden;
                    *zoom:1;
                }

                i {
                    margin-left: 4px;
                }

                span {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: -30px;
                    top: 0;
                    text-align: center;
                    transition: transform .3s;

                    i {
                        font-size: 20px;
                    }
                }
            }

            #sectional_nav {
                display: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                border: none;
                background-color: inherit;

                &::after {
                    content: "\25BC";
                    position: absolute;
                    height: .8em;
                    font-size: .6em;
                    line-height: 1;
                    right: .9em;
                    top: 50%;
                    margin-top: -.5em;
                    color: $black;
                }

                &+.fa {
                    display: none;
                    position: absolute;
                    top: 10px;
                    right: 16px;
                    font-size: 20px;
                    color: $magenta;

                    @media only screen and (max-device-width : 1024px) {
                        top: 0;
                    }
                }
            }

            @media only screen and (min-device-width : 300px) and (max-device-width : 768px) {
                 p.printshop-list-trigger {
                     display: none;
                 }

                 #sectional_nav {
                     display: block;
                     width: 100%;
                     &+.fa {
                         display: block;
                     }
                 }
             }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                p.printshop-list-trigger {
                    display: none;
                }

                #sectional_nav {
                    display: block;
                    font-size: 18px;
                    &+.fa {
                        display: block;
                        font-size: 18px;
                    }
                }
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : landscape) {
                p.printshop-list-trigger {
                    display: none;
                }

                #sectional_nav {
                    display: block;
                    width: 100%;
                    &+.fa {
                        display: block;
                        top: 0;
                    }
                }
            }

            ul.printshop-list {
                position: absolute;
                width: 100%;
                top: 100%;
                margin: 10px 0 0 0;
                padding: 0;
                background-color: $white;
                list-style: none;
                border: 1px solid $black;
                max-height: 400px;
                overflow-y: scroll;
                display: none;

                @include media-breakpoint-down(sm) {
                    max-height: 300px;
                }


                li {
                    width: 100%;
                    display: block;
                    height: 34px;
                    line-height: 34px;

                    &:first-of-type { margin-top: 10px; }
                    &:last-of-type { margin-bottom: 10px; }

                    &:nth-child(odd) {
                        a {
                            background-color: $lightGray;
                        }
                    }

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: $magenta;
                        padding-left: 15px;
                        text-decoration: none;
                        transition: background-color .2s ease;
                        &:hover {
                            background-color: $yellow;
                            color: $black;
                        }
                    }
                }

            }

        }
    }

    @include media-breakpoint-down(sm) {
        transform: translateY(0);
    }
}
