@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
/* line 21, assets/sass/bootstrap/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 27, assets/sass/bootstrap/_reboot.scss */
html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

/* line 45, assets/sass/bootstrap/_reboot.scss */
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 57, assets/sass/bootstrap/_reboot.scss */
body {
  margin: 0;
  color: #212529;
  text-align: left;
  background-color: #fff; }

/* line 73, assets/sass/bootstrap/_reboot.scss */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/* line 83, assets/sass/bootstrap/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 99, assets/sass/bootstrap/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

/* line 109, assets/sass/bootstrap/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 121, assets/sass/bootstrap/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

/* line 129, assets/sass/bootstrap/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 135, assets/sass/bootstrap/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 142, assets/sass/bootstrap/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 149, assets/sass/bootstrap/_reboot.scss */
dt {
  font-weight: 700; }

/* line 153, assets/sass/bootstrap/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 158, assets/sass/bootstrap/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 162, assets/sass/bootstrap/_reboot.scss */
dfn {
  font-style: italic; }

/* line 167, assets/sass/bootstrap/_reboot.scss */
b,
strong {
  font-weight: bolder; }

/* line 173, assets/sass/bootstrap/_reboot.scss */
small {
  font-size: 80%; }

/* line 182, assets/sass/bootstrap/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 190, assets/sass/bootstrap/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 191, assets/sass/bootstrap/_reboot.scss */
sup {
  top: -.5em; }

/* line 198, assets/sass/bootstrap/_reboot.scss */
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  /* line 15, assets/sass/bootstrap/mixins/_hover.scss */
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

/* line 216, assets/sass/bootstrap/_reboot.scss */
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  /* line 19, assets/sass/bootstrap/mixins/_hover.scss */
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  /* line 225, assets/sass/bootstrap/_reboot.scss */
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

/* line 236, assets/sass/bootstrap/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* line 245, assets/sass/bootstrap/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

/* line 262, assets/sass/bootstrap/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 272, assets/sass/bootstrap/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 277, assets/sass/bootstrap/_reboot.scss */
svg:not(:root) {
  overflow: hidden; }

/* line 286, assets/sass/bootstrap/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 290, assets/sass/bootstrap/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 298, assets/sass/bootstrap/_reboot.scss */
th {
  text-align: inherit; }

/* line 309, assets/sass/bootstrap/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: .5rem; }

/* line 318, assets/sass/bootstrap/_reboot.scss */
button {
  border-radius: 0; }

/* line 326, assets/sass/bootstrap/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/* line 331, assets/sass/bootstrap/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 342, assets/sass/bootstrap/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 347, assets/sass/bootstrap/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 355, assets/sass/bootstrap/_reboot.scss */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 363, assets/sass/bootstrap/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 371, assets/sass/bootstrap/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 378, assets/sass/bootstrap/_reboot.scss */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/* line 390, assets/sass/bootstrap/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 396, assets/sass/bootstrap/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 411, assets/sass/bootstrap/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

/* line 423, assets/sass/bootstrap/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 428, assets/sass/bootstrap/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 433, assets/sass/bootstrap/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 446, assets/sass/bootstrap/_reboot.scss */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 456, assets/sass/bootstrap/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 465, assets/sass/bootstrap/_reboot.scss */
output {
  display: inline-block; }

/* line 469, assets/sass/bootstrap/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 474, assets/sass/bootstrap/_reboot.scss */
template {
  display: none; }

/* line 480, assets/sass/bootstrap/_reboot.scss */
[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

/* line 12, assets/sass/bootstrap/bootstrap-grid.scss */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

/* line 17, assets/sass/bootstrap/bootstrap-grid.scss */
*,
*::before,
*::after {
  box-sizing: inherit; }

/* line 6, assets/sass/bootstrap/_grid.scss */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    /* line 6, assets/sass/bootstrap/_grid.scss */
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    /* line 6, assets/sass/bootstrap/_grid.scss */
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    /* line 6, assets/sass/bootstrap/_grid.scss */
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    /* line 6, assets/sass/bootstrap/_grid.scss */
    .container {
      max-width: 1140px; } }

/* line 18, assets/sass/bootstrap/_grid.scss */
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

/* line 28, assets/sass/bootstrap/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

/* line 34, assets/sass/bootstrap/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 38, assets/sass/bootstrap/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

/* line 32, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

/* line 37, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

/* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 49, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 51, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.3333333333%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.6666666667%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.3333333333%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.6666666667%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.3333333333%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.6666666667%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.3333333333%; }

/* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  /* line 32, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 37, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 49, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 51, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  /* line 32, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 37, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 49, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 51, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  /* line 32, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 37, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 49, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 51, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  /* line 32, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  /* line 37, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  /* line 44, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 49, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 51, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 54, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  /* line 60, assets/sass/bootstrap/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

/* line 11, assets/sass/bootstrap/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, assets/sass/bootstrap/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 13, assets/sass/bootstrap/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 14, assets/sass/bootstrap/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 15, assets/sass/bootstrap/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 16, assets/sass/bootstrap/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 17, assets/sass/bootstrap/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 18, assets/sass/bootstrap/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 19, assets/sass/bootstrap/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 11, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 13, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 14, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 15, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 16, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 17, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 18, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 19, assets/sass/bootstrap/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 11, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 13, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 14, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 15, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 16, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 17, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 18, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 19, assets/sass/bootstrap/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 11, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 13, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 14, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 15, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 16, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 17, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 18, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 19, assets/sass/bootstrap/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 11, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 13, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 14, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 15, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 16, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 17, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 18, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 19, assets/sass/bootstrap/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 29, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 30, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 31, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 32, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 33, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 34, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 35, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 36, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 37, assets/sass/bootstrap/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 11, assets/sass/bootstrap/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, assets/sass/bootstrap/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, assets/sass/bootstrap/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, assets/sass/bootstrap/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, assets/sass/bootstrap/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, assets/sass/bootstrap/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, assets/sass/bootstrap/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 20, assets/sass/bootstrap/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 21, assets/sass/bootstrap/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 22, assets/sass/bootstrap/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 23, assets/sass/bootstrap/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 24, assets/sass/bootstrap/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 26, assets/sass/bootstrap/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 27, assets/sass/bootstrap/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 28, assets/sass/bootstrap/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 29, assets/sass/bootstrap/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 30, assets/sass/bootstrap/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 32, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 33, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 34, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 35, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 36, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 37, assets/sass/bootstrap/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 39, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 40, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 41, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 42, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 43, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 44, assets/sass/bootstrap/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 20, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 21, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 22, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 23, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 24, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 26, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 27, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 28, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 29, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 30, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 32, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 33, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 34, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 35, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 36, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 37, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 39, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 40, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 41, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 42, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 43, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 44, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 20, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 21, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 22, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 23, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 24, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 26, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 27, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 28, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 29, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 30, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 32, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 33, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 34, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 35, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 36, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 37, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 39, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 40, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 41, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 42, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 43, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 44, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 20, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 21, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 22, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 23, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 24, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 26, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 27, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 28, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 29, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 30, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 32, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 33, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 34, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 35, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 36, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 37, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 39, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 40, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 41, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 42, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 43, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 44, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, assets/sass/bootstrap/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 20, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 21, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 22, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 23, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 24, assets/sass/bootstrap/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 26, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 27, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 28, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 29, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 30, assets/sass/bootstrap/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 32, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 33, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 34, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 35, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 36, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 37, assets/sass/bootstrap/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 39, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 40, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 41, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 42, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 43, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 44, assets/sass/bootstrap/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@font-face {
  font-family: 'TideSans-600Bunny';
  font-display: swap;
  src: url("../fonts/TideSans-600Bunny.eot?#iefix") format("embedded-opentype"), url("../fonts/TideSans-600Bunny.otf") format("opentype"), url("../fonts/TideSans-600Bunny.woff") format("woff"), url("../fonts/TideSans-600Bunny.ttf") format("truetype"), url("../fonts/TideSans-600Bunny.svg#TideSans-600Bunny") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TideSans-300LilKahuna';
  font-display: swap;
  src: url("../fonts/TideSans-300LilKahuna.eot?#iefix") format("embedded-opentype"), url("../fonts/TideSans-300LilKahuna.otf") format("opentype"), url("../fonts/TideSans-300LilKahuna.woff") format("woff"), url("../fonts/TideSans-300LilKahuna.ttf") format("truetype"), url("../fonts/TideSans-300LilKahuna.svg#TideSans-300LilKahuna") format("svg");
  font-weight: normal;
  font-style: normal; }

/* =WordPress Core  */
/* line 2, assets/sass/components/_wp-core.scss */
.alignnone {
  margin: 5px 20px 20px 0; }

/* line 6, assets/sass/components/_wp-core.scss */
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto; }

/* line 12, assets/sass/components/_wp-core.scss */
.alignright {
  float: right;
  margin: 5px 0 20px 20px; }
  @media screen and (max-width: 700px) {
    /* line 12, assets/sass/components/_wp-core.scss */
    .alignright {
      float: none;
      margin-right: 0;
      display: block;
      margin: 0 auto; } }

/* line 21, assets/sass/components/_wp-core.scss */
.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }
  @media screen and (max-width: 700px) {
    /* line 21, assets/sass/components/_wp-core.scss */
    .alignleft {
      float: none;
      margin-right: 0;
      display: block;
      margin: 0 auto; } }

/* line 31, assets/sass/components/_wp-core.scss */
a img.alignright {
  float: right;
  margin: 5px 0 20px 20px; }

/* line 36, assets/sass/components/_wp-core.scss */
a img.alignnone {
  margin: 5px 20px 20px 0; }

/* line 40, assets/sass/components/_wp-core.scss */
a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0; }

/* line 45, assets/sass/components/_wp-core.scss */
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

/* line 51, assets/sass/components/_wp-core.scss */
.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center; }

/* line 59, assets/sass/components/_wp-core.scss */
.wp-caption.alignnone {
  margin: 5px 20px 20px 0; }

/* line 63, assets/sass/components/_wp-core.scss */
.wp-caption.alignleft {
  margin: 5px 20px 20px 0; }

/* line 67, assets/sass/components/_wp-core.scss */
.wp-caption.alignright {
  margin: 5px 0 20px 20px; }

/* line 71, assets/sass/components/_wp-core.scss */
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto; }

/* line 80, assets/sass/components/_wp-core.scss */
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px; }

/* Text meant only for screen readers. */
/* line 88, assets/sass/components/_wp-core.scss */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

/* line 96, assets/sass/components/_wp-core.scss */
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/* line 116, assets/sass/components/_wp-core.scss */
.sticky,
.gallery-caption,
.bypostauthor {
  display: block; }

/* line 9, assets/sass/components/_mixins.scss */
main.background-404 .editor_content form:after, section.component.column-block .carousel-item:after, section.component.image-slider .carousel-item:after {
  content: '';
  display: table;
  clear: both;
  width: 100%; }

/* line 171, assets/sass/components/_buttons.scss */
a.btn.btn-outlined-black {
  display: inline-block;
  position: relative;
  font-family: "TideSans-600Bunny", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #231F20;
  text-decoration: none;
  line-height: 1;
  transition: color .2s;
  background-color: #FFF; }
  /* line 13, assets/sass/components/_buttons.scss */
  a.btn.btn-outlined-black:hover {
    color: #231F20; }
    /* line 15, assets/sass/components/_buttons.scss */
    a.btn.btn-outlined-black:hover span:after {
      width: calc(100% - 5px); }
  /* line 20, assets/sass/components/_buttons.scss */
  a.btn.btn-outlined-black span {
    padding: 18px 38px 16px;
    display: block;
    position: relative;
    z-index: 2;
    border: 1px solid #231F20;
    pointer-events: none; }
    /* line 28, assets/sass/components/_buttons.scss */
    a.btn.btn-outlined-black span:after {
      content: '';
      width: 0;
      height: calc(100% - 5px);
      position: absolute;
      right: 0;
      top: 5px;
      background-color: #FFE600;
      z-index: -1;
      transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
  /* line 43, assets/sass/components/_buttons.scss */
  a.btn.btn-outlined-black:after {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    right: -6px;
    top: 6px;
    background-color: #FFE600;
    z-index: 0;
    transition: width 0.1s cubic-bezier(1, 0, 0, 1);
    transform: translateZ(0); }
  /* line 56, assets/sass/components/_buttons.scss */
  a.btn.btn-outlined-black:before {
    content: '';
    width: 100%;
    height: 6px;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #FFE600;
    z-index: 0;
    transform: translate(6px, 0);
    pointer-events: none; }
  /* line 174, assets/sass/components/_buttons.scss */
  a.btn.btn-outlined-black.black-bg {
    background-color: #231F20;
    color: #FFF; }
    /* line 177, assets/sass/components/_buttons.scss */
    a.btn.btn-outlined-black.black-bg:hover {
      color: #231F20; }

/* line 185, assets/sass/components/_buttons.scss */
.ak-buttons-container {
  padding-top: 20px; }
  /* line 187, assets/sass/components/_buttons.scss */
  .ak-buttons-container a {
    margin: 0 30px 0 0; }
    /* line 189, assets/sass/components/_buttons.scss */
    .ak-buttons-container a:last-child {
      margin: 0 0 0 0; }
    @media (max-width: 767.98px) {
      /* line 187, assets/sass/components/_buttons.scss */
      .ak-buttons-container a {
        width: 100%;
        margin: 0 0 15px 0; }
        /* line 196, assets/sass/components/_buttons.scss */
        .ak-buttons-container a:last-child {
          margin: 0 0 0 0; } }
    /* line 201, assets/sass/components/_buttons.scss */
    .ak-buttons-container a.magenta {
      display: inline-block;
      position: relative;
      text-decoration: none; }
      /* line 76, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta span {
        display: block;
        line-height: 51px;
        font-family: "TideSans-600Bunny", sans-serif;
        text-decoration: none;
        text-align: center;
        height: 51px;
        min-width: 230px;
        transition: background-color .2s, color .2s;
        color: #FFF;
        background-color: #CD007A;
        position: relative;
        z-index: 1;
        padding: 0 10px; }
        /* line 91, assets/sass/components/_buttons.scss */
        .ak-buttons-container a.magenta span i {
          margin-right: 5px; }
      /* line 97, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta:hover span, .ak-buttons-container a.magenta.active span {
        color: #FFF;
        background-color: #231F20; }
      /* line 101, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta:hover:after, .ak-buttons-container a.magenta.active:after {
        top: 6px;
        left: 6px; }
      /* line 107, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta:hover.gold span, .ak-buttons-container a.magenta.active.gold span {
        background: linear-gradient(270deg, #b79028 0, #ffe517 25%, #ffca31 50%, #fff 75%, #ffde85 100%); }
      /* line 113, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta:hover.copper span, .ak-buttons-container a.magenta.active.copper span {
        background: linear-gradient(270deg, #B87333 0, #A96424 25%, #9A5515 50%, #fff 75%, #C78242 100%); }
      /* line 121, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.magenta:after {
        width: 100%;
        height: 51px;
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: #FFE600;
        z-index: 0;
        transition: top .2s, left .2s; }
    /* line 204, assets/sass/components/_buttons.scss */
    .ak-buttons-container a.white {
      display: inline-block;
      position: relative;
      text-decoration: none; }
      /* line 76, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white span {
        display: block;
        line-height: 51px;
        font-family: "TideSans-600Bunny", sans-serif;
        text-decoration: none;
        text-align: center;
        height: 51px;
        min-width: 230px;
        transition: background-color .2s, color .2s;
        color: #231F20;
        background-color: #FFF;
        position: relative;
        z-index: 1;
        padding: 0 10px; }
        /* line 91, assets/sass/components/_buttons.scss */
        .ak-buttons-container a.white span i {
          margin-right: 5px; }
      /* line 97, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white:hover span, .ak-buttons-container a.white.active span {
        color: #231F20;
        background-color: #FFE600; }
      /* line 101, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white:hover:after, .ak-buttons-container a.white.active:after {
        top: 6px;
        left: 6px; }
      /* line 107, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white:hover.gold span, .ak-buttons-container a.white.active.gold span {
        background: linear-gradient(270deg, #b79028 0, #ffe517 25%, #ffca31 50%, #fff 75%, #ffde85 100%); }
      /* line 113, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white:hover.copper span, .ak-buttons-container a.white.active.copper span {
        background: linear-gradient(270deg, #B87333 0, #A96424 25%, #9A5515 50%, #fff 75%, #C78242 100%); }
      /* line 121, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.white:after {
        width: 100%;
        height: 51px;
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: #231F20;
        z-index: 0;
        transition: top .2s, left .2s; }
    /* line 208, assets/sass/components/_buttons.scss */
    .ak-buttons-container a.gold {
      display: inline-block;
      position: relative;
      text-decoration: none; }
      /* line 76, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold span {
        display: block;
        line-height: 51px;
        font-family: "TideSans-600Bunny", sans-serif;
        text-decoration: none;
        text-align: center;
        height: 51px;
        min-width: 230px;
        transition: background-color .2s, color .2s;
        color: #231F20;
        background-color: #b79028;
        position: relative;
        z-index: 1;
        padding: 0 10px; }
        /* line 91, assets/sass/components/_buttons.scss */
        .ak-buttons-container a.gold span i {
          margin-right: 5px; }
      /* line 97, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold:hover span, .ak-buttons-container a.gold.active span {
        color: #231F20;
        background-color: #b79028; }
      /* line 101, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold:hover:after, .ak-buttons-container a.gold.active:after {
        top: 6px;
        left: 6px; }
      /* line 107, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold:hover.gold span, .ak-buttons-container a.gold.active.gold span {
        background: linear-gradient(270deg, #b79028 0, #ffe517 25%, #ffca31 50%, #fff 75%, #ffde85 100%); }
      /* line 113, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold:hover.copper span, .ak-buttons-container a.gold.active.copper span {
        background: linear-gradient(270deg, #B87333 0, #A96424 25%, #9A5515 50%, #fff 75%, #C78242 100%); }
      /* line 121, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold:after {
        width: 100%;
        height: 51px;
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: #231F20;
        z-index: 0;
        transition: top .2s, left .2s; }
      /* line 210, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.gold span {
        background: linear-gradient(90deg, #b79028 0, #ffe517 25%, #ffca31 50%, #fff 75%, #ffde85 100%); }
    /* line 215, assets/sass/components/_buttons.scss */
    .ak-buttons-container a.copper {
      display: inline-block;
      position: relative;
      text-decoration: none; }
      /* line 76, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper span {
        display: block;
        line-height: 51px;
        font-family: "TideSans-600Bunny", sans-serif;
        text-decoration: none;
        text-align: center;
        height: 51px;
        min-width: 230px;
        transition: background-color .2s, color .2s;
        color: #231F20;
        background-color: #B87333;
        position: relative;
        z-index: 1;
        padding: 0 10px; }
        /* line 91, assets/sass/components/_buttons.scss */
        .ak-buttons-container a.copper span i {
          margin-right: 5px; }
      /* line 97, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper:hover span, .ak-buttons-container a.copper.active span {
        color: #231F20;
        background-color: #B87333; }
      /* line 101, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper:hover:after, .ak-buttons-container a.copper.active:after {
        top: 6px;
        left: 6px; }
      /* line 107, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper:hover.gold span, .ak-buttons-container a.copper.active.gold span {
        background: linear-gradient(270deg, #b79028 0, #ffe517 25%, #ffca31 50%, #fff 75%, #ffde85 100%); }
      /* line 113, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper:hover.copper span, .ak-buttons-container a.copper.active.copper span {
        background: linear-gradient(270deg, #B87333 0, #A96424 25%, #9A5515 50%, #fff 75%, #C78242 100%); }
      /* line 121, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper:after {
        width: 100%;
        height: 51px;
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: #231F20;
        z-index: 0;
        transition: top .2s, left .2s; }
      /* line 217, assets/sass/components/_buttons.scss */
      .ak-buttons-container a.copper span {
        background: linear-gradient(90deg, #B87333 0, #A96424 25%, #9A5515 50%, #fff 75%, #C78242 100%); }

/* line 225, assets/sass/components/_buttons.scss */
.component.buttons {
  padding: 20px 0 60px; }
  /* line 228, assets/sass/components/_buttons.scss */
  .component.buttons .h2 {
    border-left: 3px solid #ffe600;
    padding: 0 0 10px 30px; }
  /* line 233, assets/sass/components/_buttons.scss */
  .component.buttons .ak-buttons-container {
    text-align: center; }

/* line 4, assets/sass/components/_content.scss */
.editor_content.center {
  text-align: center; }

/* line 8, assets/sass/components/_content.scss */
.editor_content.white {
  color: #FFF; }

/* line 12, assets/sass/components/_content.scss */
.editor_content.intro-block.gray {
  position: relative;
  z-index: 5;
  padding: 60px 0;
  background-color: #EEEDED; }
  @media (max-width: 767.98px) {
    /* line 12, assets/sass/components/_content.scss */
    .editor_content.intro-block.gray {
      padding: 30px 0;
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 12, assets/sass/components/_content.scss */
    .editor_content.intro-block.gray {
      margin-bottom: 60px;
      padding: 45px 0; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 12, assets/sass/components/_content.scss */
    .editor_content.intro-block.gray {
      padding: 50px 0;
      margin-bottom: 50px; } }

/* line 35, assets/sass/components/_content.scss */
.editor_content h1, .editor_content .h1 {
  color: #231F20;
  font-family: "TideSans-600Bunny", sans-serif;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  margin: 0; }
  @media (max-width: 991.98px) {
    /* line 35, assets/sass/components/_content.scss */
    .editor_content h1, .editor_content .h1 {
      font-size: 45px;
      line-height: 50px; } }
  @media (max-width: 767.98px) {
    /* line 35, assets/sass/components/_content.scss */
    .editor_content h1, .editor_content .h1 {
      font-size: 35px;
      line-height: 40px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 35, assets/sass/components/_content.scss */
    .editor_content h1, .editor_content .h1 {
      font-size: 45px;
      line-height: 50px; } }

/* line 39, assets/sass/components/_content.scss */
.editor_content h2, .editor_content .h2 {
  color: #231F20;
  font-family: "TideSans-300LilKahuna", sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  text-transform: none; }
  @media (max-width: 767.98px) {
    /* line 39, assets/sass/components/_content.scss */
    .editor_content h2, .editor_content .h2 {
      font-size: 25px;
      line-height: 30px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 39, assets/sass/components/_content.scss */
    .editor_content h2, .editor_content .h2 {
      font-size: 25px;
      line-height: 30px; } }
  /* line 42, assets/sass/components/_content.scss */
  .editor_content h2.yellow-border, .editor_content .h2.yellow-border {
    border-left: 3px solid #FFE600;
    padding-left: 30px; }
    @media (max-width: 767.98px) {
      /* line 42, assets/sass/components/_content.scss */
      .editor_content h2.yellow-border, .editor_content .h2.yellow-border {
        padding-left: 15px;
        transform: translateX(-15px); } }
  /* line 51, assets/sass/components/_content.scss */
  .editor_content h2.yellow-line, .editor_content .h2.yellow-line {
    position: relative;
    margin-bottom: 20px; }
    /* line 54, assets/sass/components/_content.scss */
    .editor_content h2.yellow-line:before, .editor_content .h2.yellow-line:before {
      width: 3px;
      height: calc(100% - 4px);
      content: '';
      position: absolute;
      background-color: #FFE600;
      left: -23px;
      top: 2px; }
      @media (max-width: 767.98px) {
        /* line 54, assets/sass/components/_content.scss */
        .editor_content h2.yellow-line:before, .editor_content .h2.yellow-line:before {
          left: -15px; } }

/* line 70, assets/sass/components/_content.scss */
.editor_content h3, .editor_content .h3 {
  color: #231F20;
  font-family: "TideSans-600Bunny", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 .4em 0; }

/* line 75, assets/sass/components/_content.scss */
.editor_content h1.section-title, .editor_content h2.section-title, .editor_content h3.section-title, .editor_content h4.section-title {
  font-weight: normal;
  font-family: "TideSans-600Bunny", sans-serif;
  text-transform: uppercase;
  line-height: 1;
  color: #FFF;
  font-size: 19px;
  font-weight: normal; }
  @media (max-width: 767.98px) {
    /* line 75, assets/sass/components/_content.scss */
    .editor_content h1.section-title, .editor_content h2.section-title, .editor_content h3.section-title, .editor_content h4.section-title {
      font-size: 16px; } }
  /* line 31, assets/sass/components/_variables.scss */
  .editor_content h1.section-title:before, .editor_content h2.section-title:before, .editor_content h3.section-title:before, .editor_content h4.section-title:before {
    content: '-';
    display: inline-block;
    margin-right: 5px; }
  /* line 36, assets/sass/components/_variables.scss */
  .editor_content h1.section-title:after, .editor_content h2.section-title:after, .editor_content h3.section-title:after, .editor_content h4.section-title:after {
    content: '-';
    display: inline-block;
    margin-left: 5px; }

/* line 81, assets/sass/components/_content.scss */
.editor_content p {
  font-family: "TideSans-300LilKahuna", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal; }
  /* line 84, assets/sass/components/_content.scss */
  .editor_content p.preamble {
    font-family: "TideSans-300LilKahuna", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 767.98px) {
      /* line 84, assets/sass/components/_content.scss */
      .editor_content p.preamble {
        font-size: 14px;
        line-height: 22px; } }
  /* line 88, assets/sass/components/_content.scss */
  .editor_content p strong {
    font-family: "TideSans-600Bunny", sans-serif;
    font-weight: normal; }
  /* line 93, assets/sass/components/_content.scss */
  .editor_content p:last-child {
    margin: 0 0 0 0; }
  /* line 97, assets/sass/components/_content.scss */
  .editor_content p a {
    text-decoration: none;
    color: #CD007A;
    transition: color .2s; }
    /* line 101, assets/sass/components/_content.scss */
    .editor_content p a:hover {
      color: #231F20; }

/* line 108, assets/sass/components/_content.scss */
.editor_content a.arrow-link.magenta {
  font-size: 16px;
  line-height: 1;
  color: #CD007A;
  transition: color .2s;
  font-family: "TideSans-600Bunny", sans-serif;
  text-transform: uppercase;
  position: relative;
  text-decoration: none; }
  /* line 146, assets/sass/components/_buttons.scss */
  .editor_content a.arrow-link.magenta i {
    margin-left: 5px; }
  /* line 150, assets/sass/components/_buttons.scss */
  .editor_content a.arrow-link.magenta:after {
    content: '';
    width: 0;
    height: 10px;
    background-color: #FFE600;
    position: absolute;
    right: -6px;
    top: calc(100% - 9px);
    z-index: -1;
    transition: width 0.3s cubic-bezier(1, 0, 0, 1); }
  /* line 162, assets/sass/components/_buttons.scss */
  .editor_content a.arrow-link.magenta:hover {
    color: #231F20; }
    /* line 164, assets/sass/components/_buttons.scss */
    .editor_content a.arrow-link.magenta:hover:after {
      width: 100%; }

/* line 112, assets/sass/components/_content.scss */
.editor_content a.arrow-link.file i {
  margin-right: 5px; }

/* line 118, assets/sass/components/_content.scss */
.editor_content ul, .editor_content ol {
  font-family: "TideSans-300LilKahuna", sans-serif;
  font-size: 14px;
  line-height: 22px;
  padding: 0 0 0 14px; }
  /* line 123, assets/sass/components/_content.scss */
  .editor_content ul li, .editor_content ol li {
    margin: 0 0 0 0; }
    /* line 125, assets/sass/components/_content.scss */
    .editor_content ul li a, .editor_content ol li a {
      color: #CD007A;
      text-decoration: none;
      transition: color .2s; }
      /* line 129, assets/sass/components/_content.scss */
      .editor_content ul li a:hover, .editor_content ol li a:hover {
        color: #231F20; }

/* line 138, assets/sass/components/_content.scss */
.editor_content q.blue-q {
  display: block;
  width: 100%;
  position: relative;
  padding: 30px;
  font-family: 'Times', serif;
  font-style: italic;
  color: #75BEE9;
  font-size: 35px; }
  /* line 149, assets/sass/components/_content.scss */
  .editor_content q.blue-q:before, .editor_content q.blue-q:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid #75BEE9;
    width: 50px; }
  /* line 160, assets/sass/components/_content.scss */
  .editor_content q.blue-q:before {
    left: 0;
    border-right: 0; }
  /* line 165, assets/sass/components/_content.scss */
  .editor_content q.blue-q:after {
    right: 0;
    border-left: 0; }
  /* line 170, assets/sass/components/_content.scss */
  .editor_content q.blue-q.pink {
    color: #CD007A; }
    /* line 173, assets/sass/components/_content.scss */
    .editor_content q.blue-q.pink:before, .editor_content q.blue-q.pink:after {
      border: #CD007A; }

/* line 180, assets/sass/components/_content.scss */
.editor_content .wp-caption {
  margin: 40px 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  border: none;
  position: relative; }
  @media (max-width: 767.98px) {
    /* line 180, assets/sass/components/_content.scss */
    .editor_content .wp-caption {
      margin: 30px 0 10px; } }
  /* line 192, assets/sass/components/_content.scss */
  .editor_content .wp-caption img {
    max-width: 100%;
    height: auto; }
  /* line 197, assets/sass/components/_content.scss */
  .editor_content .wp-caption .wp-caption-text {
    position: absolute;
    left: calc(100% - 60px);
    bottom: 40px;
    display: block;
    max-width: 230px;
    width: 100%;
    text-align: left;
    font-family: "TideSans-300LilKahuna", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #75BEE9;
    background-color: #FFF;
    margin: 0;
    padding: 15px 20px; }
    @media (max-width: 767.98px) {
      /* line 197, assets/sass/components/_content.scss */
      .editor_content .wp-caption .wp-caption-text {
        position: static;
        max-width: 100%;
        padding: 5px 0; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 197, assets/sass/components/_content.scss */
      .editor_content .wp-caption .wp-caption-text {
        position: static;
        max-width: 100%;
        padding: 5px 0; } }

/* line 229, assets/sass/components/_content.scss */
.editor_content img {
  max-width: 100%;
  height: auto;
  margin: 0; }

/* line 235, assets/sass/components/_content.scss */
.editor_content ul.ak-products-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  columns: 4;
  column-gap: 30px; }
  /* line 244, assets/sass/components/_content.scss */
  .editor_content ul.ak-products-list.margin-above {
    margin-top: 50px; }
  @media (max-width: 767.98px) {
    /* line 235, assets/sass/components/_content.scss */
    .editor_content ul.ak-products-list {
      columns: 2;
      column-gap: 15px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 235, assets/sass/components/_content.scss */
    .editor_content ul.ak-products-list {
      columns: 3;
      column-gap: 15px; } }
  /* line 258, assets/sass/components/_content.scss */
  .editor_content ul.ak-products-list li {
    display: block;
    margin: 0 0 5px 0; }
    /* line 261, assets/sass/components/_content.scss */
    .editor_content ul.ak-products-list li a {
      text-decoration: none;
      color: #231F20; }
      /* line 264, assets/sass/components/_content.scss */
      .editor_content ul.ak-products-list li a i {
        color: #CD007A;
        margin-right: 5px; }

/* line 272, assets/sass/components/_content.scss */
.editor_content .content-columns {
  max-width: 100%;
  display: flex; }
  /* line 275, assets/sass/components/_content.scss */
  .editor_content .content-columns p {
    display: block;
    max-width: 50%; }

/* line 281, assets/sass/components/_content.scss */
.editor_content a.icon-link {
  font-weight: normal;
  font-family: "TideSans-600Bunny", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0.4em;
  transition: color .2s;
  position: relative; }
  /* line 291, assets/sass/components/_content.scss */
  .editor_content a.icon-link:hover {
    color: #231F20; }
    /* line 293, assets/sass/components/_content.scss */
    .editor_content a.icon-link:hover:after {
      width: 100%; }
  /* line 298, assets/sass/components/_content.scss */
  .editor_content a.icon-link:after {
    content: '';
    width: 0;
    height: 6px;
    background-color: #FFE600;
    display: block;
    position: absolute;
    right: -5px;
    bottom: 1px;
    z-index: -1;
    transition: width .2s;
    backface-visibility: hidden; }
  /* line 313, assets/sass/components/_content.scss */
  .editor_content a.icon-link i {
    font-size: 20px;
    margin-right: 5px; }

/* line 322, assets/sass/components/_content.scss */
section.hero + section.intro-block.gray {
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    /* line 322, assets/sass/components/_content.scss */
    section.hero + section.intro-block.gray {
      margin-bottom: 15px; } }

/* line 329, assets/sass/components/_content.scss */
img.img-responsive {
  max-width: 100%;
  height: auto; }

/* line 1, assets/sass/components/_menu.scss */
.site-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #FFF;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07);
  z-index: 9999;
  opacity: 1;
  transition: opacity .2s, transform .2s; }
  /* line 14, assets/sass/components/_menu.scss */
  .site-header.not-visible {
    opacity: 0;
    transform: translateY(-100%); }
  /* line 19, assets/sass/components/_menu.scss */
  .site-header nav.navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 900px) {
      /* line 19, assets/sass/components/_menu.scss */
      .site-header nav.navbar {
        justify-content: flex-end; } }
    /* line 29, assets/sass/components/_menu.scss */
    .site-header nav.navbar a.site-logotype {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 180px;
      margin: 0;
      padding: 0;
      line-height: 1; }
      @media only screen and (max-width: 900px) {
        /* line 29, assets/sass/components/_menu.scss */
        .site-header nav.navbar a.site-logotype {
          max-width: 130px; } }
    /* line 47, assets/sass/components/_menu.scss */
    .site-header nav.navbar ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 78px;
      display: flex;
      align-items: center;
      line-height: 1; }
      @media only screen and (max-width: 900px) {
        /* line 47, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul {
          height: 55px; } }
      /* line 63, assets/sass/components/_menu.scss */
      .site-header nav.navbar ul.header-right li {
        margin-left: 30px; }
        @media only screen and (max-width: 900px) {
          /* line 63, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul.header-right li {
            margin-left: 0 !important; } }
        /* line 70, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul.header-right li:first-of-type {
          margin-left: 0; }
        @media only screen and (max-width: 900px) {
          /* line 74, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul.header-right li:nth-child(1), .site-header nav.navbar ul.header-right li:nth-child(2), .site-header nav.navbar ul.header-right li:nth-child(3) {
            display: none; } }
      @media only screen and (max-width: 900px) {
        /* line 85, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul.header-left {
          display: none; } }
      /* line 89, assets/sass/components/_menu.scss */
      .site-header nav.navbar ul.header-left li {
        margin-right: 30px; }
        /* line 91, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul.header-left li:last-of-type {
          margin-right: 0; }
      /* line 97, assets/sass/components/_menu.scss */
      .site-header nav.navbar ul li {
        display: inline-block; }
        /* line 102, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul li.current-menu-item a:after, .site-header nav.navbar ul li.active-item a:after {
          width: 100%; }
        /* line 107, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul li a {
          color: #231F20;
          text-decoration: none;
          font-family: "TideSans-600Bunny", sans-serif;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 1;
          position: relative;
          display: block; }
          /* line 118, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul li a:hover:after {
            width: 100%; }
          /* line 123, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul li a:after {
            content: '';
            display: block;
            width: 0;
            height: 10px;
            background-color: #FFE600;
            transform: translate(5px, -9px);
            position: absolute;
            right: 0;
            top: 100%;
            z-index: -1;
            transition: width 0.2s cubic-bezier(1, 0, 0, 1);
            pointer-events: none; }
        /* line 140, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul li button {
          cursor: pointer;
          outline: none;
          margin: 0;
          padding: 0;
          border: none;
          background: none; }
        /* line 149, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul li.burger {
          margin-left: 15px;
          height: 32px; }
          /* line 152, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul li.burger button {
            width: 32px;
            height: 32px;
            position: relative;
            vertical-align: top; }
            /* line 162, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.burger button.offcanvas-visible:hover span:after, .site-header nav.navbar ul li.burger button.offcanvas-visible:hover span:before {
              width: 100%; }
            /* line 169, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.burger button.offcanvas-visible span {
              background-color: transparent; }
              /* line 171, assets/sass/components/_menu.scss */
              .site-header nav.navbar ul li.burger button.offcanvas-visible span:after {
                width: 100%;
                transform: rotate(-135deg); }
              /* line 176, assets/sass/components/_menu.scss */
              .site-header nav.navbar ul li.burger button.offcanvas-visible span:before {
                width: 100%;
                transform: rotate(135deg); }
            /* line 185, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.burger button:hover span:before {
              width: 75%; }
            /* line 188, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.burger button:hover span:after {
              width: 100%; }
            /* line 194, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.burger button span {
              width: 19px;
              display: block;
              height: 2px;
              background-color: #231F20;
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -1px;
              transition: background-color .2s; }
              /* line 205, assets/sass/components/_menu.scss */
              .site-header nav.navbar ul li.burger button span:after, .site-header nav.navbar ul li.burger button span:before {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                background-color: #231F20;
                right: 0;
                top: 0;
                transition: width 0.2s ease, transform 0.2s cubic-bezier(1, 0, 0, 1);
                backface-visibility: hidden;
                *zoom: 1; }
              /* line 219, assets/sass/components/_menu.scss */
              .site-header nav.navbar ul li.burger button span:before {
                transform: translateY(-5px); }
              /* line 222, assets/sass/components/_menu.scss */
              .site-header nav.navbar ul li.burger button span:after {
                transform: translateY(5px);
                width: 75%; }
        /* line 230, assets/sass/components/_menu.scss */
        .site-header nav.navbar ul li.search {
          margin-left: 15px;
          height: 32px; }
          /* line 233, assets/sass/components/_menu.scss */
          .site-header nav.navbar ul li.search button {
            width: 32px;
            height: 32px;
            text-align: right;
            position: relative; }
            /* line 239, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.search button i {
              position: relative;
              z-index: 1; }
            /* line 246, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.search button:hover:after, .site-header nav.navbar ul li.search button.search-active:after {
              width: 12px; }
            /* line 251, assets/sass/components/_menu.scss */
            .site-header nav.navbar ul li.search button:after {
              height: 10px;
              width: 0;
              content: '';
              position: absolute;
              right: -3px;
              top: 16px;
              background-color: #FFE600;
              z-index: 0;
              transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
  /* line 270, assets/sass/components/_menu.scss */
  .site-header form.search-form {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #FFE600;
    padding: 10px;
    z-index: -1;
    border-top: 1px solid #e5cf00;
    display: none; }
    /* line 281, assets/sass/components/_menu.scss */
    .site-header form.search-form .search-field {
      width: calc(100% - 100px);
      margin: 0;
      padding: 0;
      display: block;
      float: left;
      height: 32px;
      border: none;
      background: none;
      border-left: 1px solid #CD007A;
      padding-left: 15px;
      font-size: 16px;
      font-family: "TideSans-300LilKahuna", sans-serif;
      border-radius: 0; }
      /* line 19, assets/sass/components/_mixins.scss */
      .site-header form.search-form .search-field.placeholder {
        color: #231F20;
        opacity: 1; }
      /* line 20, assets/sass/components/_mixins.scss */
      .site-header form.search-form .search-field:-moz-placeholder {
        color: #231F20;
        opacity: 1; }
      /* line 21, assets/sass/components/_mixins.scss */
      .site-header form.search-form .search-field::-moz-placeholder {
        color: #231F20;
        opacity: 1; }
      /* line 22, assets/sass/components/_mixins.scss */
      .site-header form.search-form .search-field:-ms-input-placeholder {
        color: #231F20;
        opacity: 1; }
      /* line 23, assets/sass/components/_mixins.scss */
      .site-header form.search-form .search-field::-webkit-input-placeholder {
        color: #231F20;
        opacity: 1; }
    /* line 303, assets/sass/components/_menu.scss */
    .site-header form.search-form .search-submit {
      width: 100px;
      margin: 0;
      padding: 0;
      display: block;
      float: left;
      height: 32px;
      background: none;
      border: 0;
      outline: none;
      cursor: pointer;
      text-align: right;
      color: #CD007A;
      font-size: 16px;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase; }

/* line 327, assets/sass/components/_menu.scss */
body.search-results header.site-header form.search-form {
  display: block; }

/* line 331, assets/sass/components/_menu.scss */
body.search-results header.site-header li.search button:after {
  width: 12px; }

/* line 341, assets/sass/components/_menu.scss */
body.tax-producttype .vad-vi-gor-menu-item a:after,
body.single-products .vad-vi-gor-menu-item a:after {
  width: 100% !important; }

/* line 1, assets/sass/components/_main.scss */
body {
  font-family: "TideSans-300LilKahuna", sans-serif;
  font-size: 14px; }

/* line 6, assets/sass/components/_main.scss */
.container-fluid {
  width: 100%;
  max-width: 1170px; }

/* line 11, assets/sass/components/_main.scss */
main {
  padding-top: 78px;
  transition: padding .2s; }
  @media only screen and (max-width: 900px) {
    /* line 11, assets/sass/components/_main.scss */
    main {
      padding-top: 55px; } }

/* line 22, assets/sass/components/_main.scss */
button.scrolldown-indicator {
  border: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #FFE600;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -25px);
  z-index: 99; }
  @media (max-width: 767.98px) {
    /* line 22, assets/sass/components/_main.scss */
    button.scrolldown-indicator {
      width: 34px;
      height: 34px;
      transform: translate(-50%, -17px); } }
  /* line 43, assets/sass/components/_main.scss */
  button.scrolldown-indicator svg {
    width: 24px;
    transform: translateY(2px); }
    @media (max-width: 767.98px) {
      /* line 43, assets/sass/components/_main.scss */
      button.scrolldown-indicator svg {
        width: 16px; } }

/* line 65, assets/sass/components/_main.scss */
.ak-breadcrumbs {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  display: block;
  margin: 0;
  padding: 4px 10px 4px 120px;
  list-style: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
  /* IE6-9 */ }
  @media (max-width: 767.98px) {
    /* line 65, assets/sass/components/_main.scss */
    .ak-breadcrumbs {
      display: none; } }
  /* line 86, assets/sass/components/_main.scss */
  .ak-breadcrumbs li {
    display: inline-block;
    color: #FFF;
    font-size: 12px;
    text-transform: uppercase; }
    /* line 92, assets/sass/components/_main.scss */
    .ak-breadcrumbs li span.separator {
      margin: 0 5px;
      display: inline-block; }
    /* line 97, assets/sass/components/_main.scss */
    .ak-breadcrumbs li a {
      color: #FFF;
      text-decoration: none; }

/* line 104, assets/sass/components/_main.scss */
.intelecomchat {
  z-index: 899;
  transition: opacity .2s;
  opacity: 1; }
  /* line 109, assets/sass/components/_main.scss */
  .intelecomchat.not-visible {
    opacity: 0; }
  @media only screen and (max-width: 900px) {
    /* line 104, assets/sass/components/_main.scss */
    .intelecomchat {
      z-index: 899; } }
  @media (max-width: 767.98px) {
    /* line 104, assets/sass/components/_main.scss */
    .intelecomchat {
      z-index: 10000; } }
  /* line 121, assets/sass/components/_main.scss */
  .intelecomchat .intelecomchatstarter {
    position: fixed;
    top: 85% !important;
    right: 0;
    background: none;
    border: 1px solid #231F20;
    border-right: 0;
    background-color: #FFF;
    height: 40px;
    width: 80px;
    border-radius: 0; }
    @media (max-width: 767.98px) {
      /* line 121, assets/sass/components/_main.scss */
      .intelecomchat .intelecomchatstarter {
        top: 50px !important;
        left: 15px !important;
        width: 27px;
        height: 21px;
        border: none; } }
    /* line 142, assets/sass/components/_main.scss */
    .intelecomchat .intelecomchatstarter:before {
      position: absolute;
      text-indent: 0;
      display: block;
      content: 'Chat';
      width: 100%;
      top: 0;
      left: 0;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-family: "TideSans-600Bunny", sans-serif;
      font-weight: normal;
      color: #231F20;
      z-index: 2; }
      @media (max-width: 767.98px) {
        /* line 142, assets/sass/components/_main.scss */
        .intelecomchat .intelecomchatstarter:before {
          content: '';
          height: 21px;
          width: 27px;
          background-image: url("../img/chat-icon-black.svg"); } }
    /* line 169, assets/sass/components/_main.scss */
    .intelecomchat .intelecomchatstarter:after {
      height: 5px;
      content: '';
      width: 100%;
      position: absolute;
      top: calc(100% + 1px);
      left: 5px;
      background-color: #FFE600; }
      @media (max-width: 767.98px) {
        /* line 169, assets/sass/components/_main.scss */
        .intelecomchat .intelecomchatstarter:after {
          display: none; } }

/* line 187, assets/sass/components/_main.scss */
.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  width: calc(100% + 150px);
  left: -75px;
  transform: translateY(-50%); }
  @media only screen and (max-width: 1310px) {
    /* line 187, assets/sass/components/_main.scss */
    .owl-carousel .owl-nav {
      width: calc(100% + 30px);
      left: -15px; } }
  /* line 199, assets/sass/components/_main.scss */
  .owl-carousel .owl-nav button {
    width: 47px;
    height: 47px;
    background: #FFE600 !important;
    outline: none; }
    @media (max-width: 767.98px) {
      /* line 199, assets/sass/components/_main.scss */
      .owl-carousel .owl-nav button {
        width: 36px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 199, assets/sass/components/_main.scss */
      .owl-carousel .owl-nav button {
        width: 36px; } }
    /* line 213, assets/sass/components/_main.scss */
    .owl-carousel .owl-nav button svg {
      margin-top: 3px;
      max-width: 17px; }
      @media (max-width: 767.98px) {
        /* line 213, assets/sass/components/_main.scss */
        .owl-carousel .owl-nav button svg {
          max-width: 13px; } }
    /* line 221, assets/sass/components/_main.scss */
    .owl-carousel .owl-nav button.owl-prev {
      background: #FFE600; }
      /* line 223, assets/sass/components/_main.scss */
      .owl-carousel .owl-nav button.owl-prev svg {
        transform: rotate(180deg); }
    /* line 227, assets/sass/components/_main.scss */
    .owl-carousel .owl-nav button.owl-next {
      float: right;
      background: #FFE600; }
      /* line 230, assets/sass/components/_main.scss */
      .owl-carousel .owl-nav button.owl-next svg {
        margin: 3px 0 0 5px; }

/* line 238, assets/sass/components/_main.scss */
.owl-carousel .owl-dots {
  width: 100%;
  position: absolute;
  top: calc(100% + 42px);
  left: 0;
  text-align: center; }
  @media (max-width: 767.98px) {
    /* line 238, assets/sass/components/_main.scss */
    .owl-carousel .owl-dots {
      top: calc(100% - 5px); } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 238, assets/sass/components/_main.scss */
    .owl-carousel .owl-dots {
      top: calc(100% + 15px); } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 238, assets/sass/components/_main.scss */
    .owl-carousel .owl-dots {
      top: calc(100% + 7px); } }
  /* line 257, assets/sass/components/_main.scss */
  .owl-carousel .owl-dots button {
    margin: 0 12px;
    padding: 0;
    line-height: 1;
    display: inline-block;
    opacity: .3;
    transition: opacity .2s; }
    /* line 264, assets/sass/components/_main.scss */
    .owl-carousel .owl-dots button.active {
      opacity: 1; }
    /* line 267, assets/sass/components/_main.scss */
    .owl-carousel .owl-dots button span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #FFF; }

/* line 279, assets/sass/components/_main.scss */
.password-required-area {
  width: 100%;
  min-height: 600px;
  background-color: #EEEDED;
  position: relative; }
  @media (max-width: 767.98px) {
    /* line 279, assets/sass/components/_main.scss */
    .password-required-area {
      min-height: 350px; } }
  /* line 290, assets/sass/components/_main.scss */
  .password-required-area form.post-password-form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 640px;
    transform: translate(-50%, -50%);
    text-align: center; }
    @media (max-width: 767.98px) {
      /* line 290, assets/sass/components/_main.scss */
      .password-required-area form.post-password-form {
        padding: 0 15px; } }
    /* line 303, assets/sass/components/_main.scss */
    .password-required-area form.post-password-form p input[type="password"] {
      height: 40px;
      font-family: "TideSans-600Bunny", sans-serif;
      padding: 0 10px;
      border: none; }
    /* line 310, assets/sass/components/_main.scss */
    .password-required-area form.post-password-form p input[type="submit"] {
      height: 40px;
      border: none;
      background: #FFE600;
      cursor: pointer;
      font-family: "TideSans-600Bunny", sans-serif; }

/* line 325, assets/sass/components/_main.scss */
#cookie-notice {
  background-color: #FFE600 !important; }
  /* line 327, assets/sass/components/_main.scss */
  #cookie-notice .cookie-notice-container {
    padding: 15px; }
    /* line 329, assets/sass/components/_main.scss */
    #cookie-notice .cookie-notice-container span {
      color: #231F20;
      font-family: "TideSans-600Bunny", sans-serif;
      font-size: 14px; }
  /* line 336, assets/sass/components/_main.scss */
  #cookie-notice .cn-set-cookie,
  #cookie-notice #cn-more-info {
    color: #FFF;
    background: #CD007A;
    border: none;
    font-family: "TideSans-600Bunny", sans-serif;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    padding: 5px 15px; }

/* line 349, assets/sass/components/_main.scss */
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; }

/* line 355, assets/sass/components/_main.scss */
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

/* line 1, assets/sass/components/_footer.scss */
footer.site-footer {
  width: 100%;
  background-image: url("../img/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/_footer.scss */
    footer.site-footer {
      padding: 40px 0; } }
  /* line 14, assets/sass/components/_footer.scss */
  footer.site-footer .editor_content ul.social-media {
    margin: 0 0 30px 0;
    padding: 0;
    width: 100%; }
    @media (max-width: 767.98px) {
      /* line 14, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content ul.social-media {
        margin: 0 0 20px 0; } }
    /* line 23, assets/sass/components/_footer.scss */
    footer.site-footer .editor_content ul.social-media li {
      display: inline-block;
      margin: 0 15px; }
      /* line 27, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content ul.social-media li.mnd {
        max-width: 33px;
        transform: translateY(3px); }
        /* line 30, assets/sass/components/_footer.scss */
        footer.site-footer .editor_content ul.social-media li.mnd img {
          max-width: 100%;
          height: auto;
          display: block; }
      /* line 37, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content ul.social-media li a {
        color: #FFF;
        font-size: 38px;
        line-height: 1; }
  /* line 45, assets/sass/components/_footer.scss */
  footer.site-footer .editor_content p {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    margin: 0 0 40px 0; }
    @media (max-width: 767.98px) {
      /* line 45, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content p {
        margin: 0 0 20px 0; } }
    /* line 53, assets/sass/components/_footer.scss */
    footer.site-footer .editor_content p a {
      color: #FFF;
      text-decoration: none;
      font-size: 14px;
      transition: color .2s; }
      /* line 58, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content p a:hover {
        color: #FFE600; }
  /* line 65, assets/sass/components/_footer.scss */
  footer.site-footer .editor_content .footer-buttons a.button {
    display: inline-block;
    position: relative;
    font-family: "TideSans-600Bunny", sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #FFF;
    text-decoration: none;
    line-height: 1;
    transition: color .2s;
    background-color: #FFF;
    background-color: transparent;
    margin: 15px; }
    /* line 13, assets/sass/components/_buttons.scss */
    footer.site-footer .editor_content .footer-buttons a.button:hover {
      color: #231F20; }
      /* line 15, assets/sass/components/_buttons.scss */
      footer.site-footer .editor_content .footer-buttons a.button:hover span:after {
        width: calc(100% - 5px); }
    /* line 20, assets/sass/components/_buttons.scss */
    footer.site-footer .editor_content .footer-buttons a.button span {
      padding: 18px 38px 16px;
      display: block;
      position: relative;
      z-index: 2;
      border: 1px solid #FFF;
      pointer-events: none; }
      /* line 28, assets/sass/components/_buttons.scss */
      footer.site-footer .editor_content .footer-buttons a.button span:after {
        content: '';
        width: 0;
        height: calc(100% - 5px);
        position: absolute;
        right: 0;
        top: 5px;
        background-color: #FFE600;
        z-index: -1;
        transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
    /* line 43, assets/sass/components/_buttons.scss */
    footer.site-footer .editor_content .footer-buttons a.button:after {
      content: '';
      width: 6px;
      height: 100%;
      position: absolute;
      right: -6px;
      top: 6px;
      background-color: #FFE600;
      z-index: 0;
      transition: width 0.1s cubic-bezier(1, 0, 0, 1);
      transform: translateZ(0); }
    /* line 56, assets/sass/components/_buttons.scss */
    footer.site-footer .editor_content .footer-buttons a.button:before {
      content: '';
      width: 100%;
      height: 6px;
      position: absolute;
      right: 0;
      top: 100%;
      background-color: #FFE600;
      z-index: 0;
      transform: translate(6px, 0);
      pointer-events: none; }
    /* line 70, assets/sass/components/_footer.scss */
    footer.site-footer .editor_content .footer-buttons a.button:first-of-type {
      background-color: #FFF; }
      /* line 72, assets/sass/components/_footer.scss */
      footer.site-footer .editor_content .footer-buttons a.button:first-of-type span {
        color: #231F20;
        border: 1px solid #231F20; }

/* line 85, assets/sass/components/_footer.scss */
.site-footer-payoff {
  text-align: center;
  padding: 20px 0; }
  /* line 88, assets/sass/components/_footer.scss */
  .site-footer-payoff p {
    font-family: "TideSans-600Bunny", sans-serif;
    text-transform: uppercase;
    margin: 0; }
    /* line 93, assets/sass/components/_footer.scss */
    .site-footer-payoff p i {
      color: #CD007A;
      font-size: 22px; }
    /* line 98, assets/sass/components/_footer.scss */
    .site-footer-payoff p a {
      color: #CD007A;
      text-decoration: none;
      display: inline-block;
      position: relative;
      transition: color .2s ease; }
      /* line 105, assets/sass/components/_footer.scss */
      .site-footer-payoff p a:hover {
        color: #231F20; }
        /* line 107, assets/sass/components/_footer.scss */
        .site-footer-payoff p a:hover:after {
          width: 100%; }
      /* line 112, assets/sass/components/_footer.scss */
      .site-footer-payoff p a:after {
        content: '';
        display: block;
        width: 0px;
        height: 1px;
        background-color: #FFE600;
        position: absolute;
        right: 0;
        top: 100%;
        transition: width .2s ease; }

/* line 1, assets/sass/components/_form.scss */
input, textarea {
  outline: none; }

/* line 6, assets/sass/components/_form.scss */
section.component.need-help-form {
  margin-bottom: 100px; }
  @media (max-width: 767.98px) {
    /* line 6, assets/sass/components/_form.scss */
    section.component.need-help-form {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 6, assets/sass/components/_form.scss */
    section.component.need-help-form {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 6, assets/sass/components/_form.scss */
    section.component.need-help-form {
      margin-bottom: 50px; } }
  @media (min-width: 768px) {
    /* line 21, assets/sass/components/_form.scss */
    section.component.need-help-form img.attachment-post-thumbnail {
      transform: translate(-70px, -110px); } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 21, assets/sass/components/_form.scss */
    section.component.need-help-form img.attachment-post-thumbnail {
      transform: translate(0, -60px);
      max-width: 80%;
      height: auto;
      margin: 0 auto;
      display: block; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 21, assets/sass/components/_form.scss */
    section.component.need-help-form img.attachment-post-thumbnail {
      transform: translate(0, 0);
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      display: block; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 43, assets/sass/components/_form.scss */
    section.component.need-help-form .gform_wrapper {
      width: 90%;
      margin: 0 auto; } }

/* line 53, assets/sass/components/_form.scss */
.gform_confirmation_wrapper .gform_confirmation_message {
  color: #231F20;
  font-family: "TideSans-300LilKahuna", sans-serif; }
  /* line 57, assets/sass/components/_form.scss */
  .gform_confirmation_wrapper .gform_confirmation_message a {
    color: #CD007A;
    transition: color .2s;
    text-decoration: none !important; }
    /* line 61, assets/sass/components/_form.scss */
    .gform_confirmation_wrapper .gform_confirmation_message a:hover {
      color: #FFE600; }
  /* line 66, assets/sass/components/_form.scss */
  .gform_confirmation_wrapper .gform_confirmation_message strong {
    font-family: "TideSans-600Bunny", sans-serif;
    font-weight: normal; }

/* line 75, assets/sass/components/_form.scss */
.gform_wrapper {
  width: 100%; }
  @media (max-width: 767.98px) {
    /* line 75, assets/sass/components/_form.scss */
    .gform_wrapper {
      margin-top: 30px; } }
  /* line 81, assets/sass/components/_form.scss */
  .gform_wrapper form .gform_heading {
    width: 100%;
    margin: 0 0 30px 0; }
    /* line 84, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_heading h3.gform_title {
      color: #231F20;
      font-family: "TideSans-300LilKahuna", sans-serif;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin: 0;
      text-transform: none;
      border-left: 3px solid #FFE600;
      padding-left: 30px; }
      @media (max-width: 767.98px) {
        /* line 84, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_heading h3.gform_title {
          font-size: 25px;
          line-height: 30px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 84, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_heading h3.gform_title {
          font-size: 25px;
          line-height: 30px; } }
      @media (max-width: 767.98px) {
        /* line 84, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_heading h3.gform_title {
          padding-left: 15px;
          transform: translateX(-15px); } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 84, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_heading h3.gform_title {
          padding-left: 15px;
          transform: translateX(-15px); } }
  /* line 99, assets/sass/components/_form.scss */
  .gform_wrapper form .validation_error.hide {
    display: none; }
  /* line 104, assets/sass/components/_form.scss */
  .gform_wrapper form .gf_progressbar_wrapper .gf_progressbar_title {
    display: none; }
  /* line 105, assets/sass/components/_form.scss */
  .gform_wrapper form .gf_progressbar_wrapper .gf_progressbar {
    border: 1px solid #9D9FA2;
    width: 100%;
    height: 40px;
    margin-bottom: 20px; }
    /* line 111, assets/sass/components/_form.scss */
    .gform_wrapper form .gf_progressbar_wrapper .gf_progressbar .gf_progressbar_percentage {
      background: #F49AC1;
      height: 100%; }
      /* line 115, assets/sass/components/_form.scss */
      .gform_wrapper form .gf_progressbar_wrapper .gf_progressbar .gf_progressbar_percentage span {
        display: none; }
  @media (max-width: 991.98px) {
    /* line 121, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body {
      padding: 0 15px; } }
  /* line 127, assets/sass/components/_form.scss */
  .gform_wrapper form .gform_body ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    /* line 135, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body ul li {
      position: relative;
      margin-bottom: 30px; }
      @media (max-width: 767.98px) {
        /* line 135, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li {
          margin-bottom: 15px; } }
      /* line 143, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.full {
        width: 100%; }
      /* line 146, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.half {
        width: calc(50% - 15px); }
        @media (max-width: 767.98px) {
          /* line 146, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.half {
            width: 100%; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 146, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.half {
            width: 100%; } }
      /* line 160, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.hidden_label .gfield_label {
        display: none; }
      /* line 163, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.marked-radio-field {
        border: 1px solid #EEEDED;
        text-align: center; }
        /* line 166, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.marked-radio-field label.gfield_label {
          background-color: #EEEDED; }
          /* line 169, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.marked-radio-field label.gfield_label.input_focus {
            line-height: 40px !important; }
        /* line 174, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.marked-radio-field ul li {
          flex: 1; }
      /* line 179, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_html {
        width: 100%; }
        /* line 182, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.gform_html.half {
          width: calc(50% - 15px); }
        /* line 186, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.gform_html .editor_content {
          padding: 0 10px; }
          /* line 188, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.gform_html .editor_content em {
            font-size: 12px;
            color: #9D9FA2;
            font-style: italic; }
            /* line 192, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.gform_html .editor_content em a {
              color: #CD007A;
              text-decoration: none;
              transition: color .2s; }
              /* line 196, assets/sass/components/_form.scss */
              .gform_wrapper form .gform_body ul li.gform_html .editor_content em a:hover {
                color: #9D9FA2; }
      /* line 205, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_time .clear-multi {
        display: flex; }
        /* line 208, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.gform_time .clear-multi .gfield_time_hour {
          display: flex;
          align-items: center; }
      /* line 215, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_time input::placeholder {
        visibility: hidden; }
      /* line 220, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_time label {
        display: none; }
      /* line 223, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.pul-check, .gform_wrapper form .gform_body ul li.checkbox {
        padding: 30px 0 0 10px;
        margin: 0; }
        @media (max-width: 767.98px) {
          /* line 223, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.pul-check, .gform_wrapper form .gform_body ul li.checkbox {
            padding: 15px 0 0 10px; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 223, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.pul-check, .gform_wrapper form .gform_body ul li.checkbox {
            padding: 15px 0 0 10px; } }
        /* line 233, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.pul-check .gfield_label, .gform_wrapper form .gform_body ul li.checkbox .gfield_label {
          display: none; }
        /* line 237, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.pul-check ul li, .gform_wrapper form .gform_body ul li.checkbox ul li {
          margin: 0; }
          /* line 240, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.pul-check ul li input[type="checkbox"], .gform_wrapper form .gform_body ul li.checkbox ul li input[type="checkbox"] {
            display: none; }
          /* line 245, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.pul-check ul li input[type="checkbox"]:checked + label:after, .gform_wrapper form .gform_body ul li.checkbox ul li input[type="checkbox"]:checked + label:after {
            opacity: 1; }
          /* line 250, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.pul-check ul li label, .gform_wrapper form .gform_body ul li.checkbox ul li label {
            position: relative;
            height: 20px;
            line-height: 20px;
            padding-left: 30px; }
            /* line 255, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.pul-check ul li label a, .gform_wrapper form .gform_body ul li.checkbox ul li label a {
              color: #CD007A;
              text-decoration: none;
              transition: color .2s; }
              /* line 259, assets/sass/components/_form.scss */
              .gform_wrapper form .gform_body ul li.pul-check ul li label a:hover, .gform_wrapper form .gform_body ul li.checkbox ul li label a:hover {
                color: #231F20; }
            /* line 264, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.pul-check ul li label:before, .gform_wrapper form .gform_body ul li.checkbox ul li label:before {
              content: '';
              width: 20px;
              height: 20px;
              border: 1px solid #231F20;
              position: absolute;
              left: 0;
              top: 0;
              cursor: pointer; }
            /* line 275, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.pul-check ul li label:after, .gform_wrapper form .gform_body ul li.checkbox ul li label:after {
              width: 17px;
              height: 12px;
              content: '';
              display: block;
              position: absolute;
              left: 6px;
              top: 3px;
              background-image: url("../img/form-checkbox.svg");
              background-size: 17px 12px;
              background-repeat: no-repeat;
              pointer-events: none;
              transition: opacity .2s;
              opacity: 0; }
        /* line 295, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.pul-check.checkbox .gfield_label, .gform_wrapper form .gform_body ul li.checkbox.checkbox .gfield_label {
          display: block; }
      /* line 301, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .gfield_label {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        pointer-events: none;
        font-size: 16px;
        color: #231F20;
        transition: line-height .1s, font-size .1s, padding .1s, top .1s;
        backface-visibility: hidden;
        *zoom: 1; }
        /* line 316, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li .gfield_label.input_focus {
          line-height: 6px;
          font-size: 12px;
          padding: 0;
          top: -4px; }
      /* line 325, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.select-label .ginput_container_select {
        padding-top: 20px; }
      /* line 328, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.select-label .gfield_label {
        height: auto;
        line-height: auto;
        padding: 0 10px;
        line-height: 1; }
      /* line 337, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.textarea-label .gfield_label {
        position: static;
        height: auto;
        line-height: auto;
        padding: 0 10px;
        line-height: 1; }
      /* line 345, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.textarea-label .ginput_container_textarea {
        display: flex;
        flex-wrap: wrap; }
        /* line 349, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.textarea-label .ginput_container_textarea label {
          order: 1; }
        /* line 353, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.textarea-label .ginput_container_textarea textarea {
          order: 2;
          height: 120px; }
      /* line 360, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li input[type="text"], .gform_wrapper form .gform_body ul li input[type="email"], .gform_wrapper form .gform_body ul li input[type="number"] {
        width: 100%;
        height: 40px;
        padding: 0 10px;
        border: none;
        border-bottom: 1px solid #231F20;
        background-color: transparent;
        border-radius: 0; }
      /* line 370, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li input[type="text"].datepicker::placeholder {
        visibility: hidden; }
      /* line 374, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li textarea {
        width: 100%;
        min-height: 40px;
        height: 40px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #231F20;
        resize: none;
        padding: 10px;
        color: #231F20;
        overflow: hidden;
        font-size: 16px;
        line-height: 24px;
        transition: height 50ms;
        border-radius: 0; }
      /* line 397, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .select2-container--gform {
        width: 100%;
        max-width: 100%; }
        /* line 401, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li .select2-container--gform .select2-selection--single {
          height: 40px; }
      /* line 406, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .hiddenDiv {
        display: none;
        white-space: pre-wrap;
        width: 100%;
        min-height: 40px;
        font-size: 16px;
        padding: 10px;
        word-wrap: break-word;
        line-height: 24px; }
      /* line 417, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .gfield_description {
        font-family: "TideSans-300LilKahuna", sans-serif;
        font-size: 12px;
        padding: 10px;
        color: #9D9FA2; }
      /* line 424, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .validation_message {
        font-family: "TideSans-600Bunny", sans-serif;
        color: #CD007A;
        display: block;
        position: absolute;
        right: 10px;
        top: -10px;
        text-align: right;
        padding-right: 30px; }
        /* line 434, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li .validation_message.hide {
          display: none; }
      /* line 440, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_radio .validation_message {
        top: -25px; }
      /* line 447, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_radio .gfield_label {
        position: static;
        transform: translate(0, 0); }
      /* line 452, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio {
        justify-content: flex-start; }
        /* line 454, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li {
          margin: 0 10px 0 10px;
          line-height: 1; }
          /* line 458, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li input[type="radio"] {
            display: none; }
          /* line 463, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li input[type="radio"]:checked + label:after {
            transform: scale(1);
            opacity: 1; }
          /* line 469, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li label {
            position: relative;
            padding: 0 0 0 25px;
            line-height: 19px; }
            /* line 474, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li label:before, .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li label:after {
              position: absolute;
              content: '';
              display: block; }
            /* line 481, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li label:before {
              width: 19px;
              height: 19px;
              border: 1px solid #231F20;
              border-radius: 50%;
              left: 0;
              top: 0; }
            /* line 490, assets/sass/components/_form.scss */
            .gform_wrapper form .gform_body ul li.gform_radio ul.gfield_radio li label:after {
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background-color: #CD007A;
              top: 5px;
              left: 5px;
              transform: scale(1.8);
              opacity: 0;
              transition: transform .2s, opacity .2s; }
      /* line 506, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_radio.bf-radio ul.gfield_radio {
        display: table; }
      /* line 512, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_fileupload .gfield_label {
        position: static; }
      /* line 515, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gform_fileupload .gform_fileupload_rules {
        display: block;
        margin-top: 4px; }
      /* line 518, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .gform_drop_area {
        width: 100%;
        border: 1px dashed #231F20;
        text-align: center;
        background-color: #EEEDED;
        padding: 25px 0; }
        /* line 525, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li .gform_drop_area input {
          color: #231F20;
          font-family: "TideSans-600Bunny", sans-serif;
          font-size: 14px;
          background-color: #FFE600;
          border: none;
          outline: none;
          padding: 8px 25px;
          cursor: pointer;
          transition: background-color .2s, color .2s;
          border-radius: 0; }
          /* line 536, assets/sass/components/_form.scss */
          .gform_wrapper form .gform_body ul li .gform_drop_area input:hover {
            background-color: #CD007A;
            color: #FFF; }
      /* line 543, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li .ginput_preview {
        width: 100%;
        border: 1px solid #7CC366;
        padding: 3px 10px; }
        /* line 547, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body ul li .ginput_preview strong {
          font-weight: normal;
          color: #231F20;
          font-family: "TideSans-300LilKahuna", sans-serif; }
      /* line 555, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body ul li.gfield_error textarea, .gform_wrapper form .gform_body ul li.gfield_error input, .gform_wrapper form .gform_body ul li.gfield_error select + .select2-container--gform {
        border-color: #CD007A; }
  /* line 563, assets/sass/components/_form.scss */
  .gform_wrapper form .gform_body .gform_checkbox {
    margin-top: -20px;
    margin-bottom: 0; }
    /* line 566, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body .gform_checkbox .gfield_label {
      line-height: 22px; }
    /* line 569, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body .gform_checkbox .ginput_container_checkbox {
      padding: 10px 0 0 0; }
      @media (max-width: 767.98px) {
        /* line 569, assets/sass/components/_form.scss */
        .gform_wrapper form .gform_body .gform_checkbox .ginput_container_checkbox {
          padding-top: 35px; } }
      /* line 576, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body .gform_checkbox .ginput_container_checkbox li {
        margin-bottom: 10px; }
  @media only screen and (min-width: 200px) and (max-width: 768px) {
    /* line 583, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body li.gform_select {
      border-bottom: 1px solid #231F20; }
      /* line 585, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select select {
        width: 100%;
        padding-bottom: 20px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background-color: inherit; }
      /* line 594, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select .ginput_container_select::after {
        content: "\25BC";
        position: absolute;
        height: .8em;
        font-size: .6em;
        line-height: 1;
        right: .9em;
        top: 50%;
        margin-top: -.5em;
        color: #231F20; }
    /* line 606, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body .input_focus + .ginput_container_select select {
      padding-top: 14px;
      padding-bottom: 6px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* line 613, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body li.gform_select {
      border-bottom: 1px solid #231F20; }
      /* line 615, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select select {
        width: 100%;
        padding-bottom: 20px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background-color: inherit; }
      /* line 624, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select .ginput_container_select::after {
        content: "\25BC";
        position: absolute;
        height: .8em;
        font-size: .6em;
        line-height: 1;
        right: .9em;
        top: 50%;
        margin-top: -.5em;
        color: #231F20; }
    /* line 637, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body .input_focus + .ginput_container_select select {
      padding-top: 14px;
      padding-bottom: 6px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) {
    /* line 644, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body li.gform_select {
      border-bottom: 1px solid #231F20; }
      /* line 646, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select select {
        width: 100%;
        padding-bottom: 20px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background-color: inherit; }
      /* line 655, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_body li.gform_select .ginput_container_select::after {
        content: "\25BC";
        position: absolute;
        height: .8em;
        font-size: .6em;
        line-height: 1;
        right: .9em;
        top: 50%;
        margin-top: -.5em;
        color: #231F20; }
    /* line 668, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_body .input_focus + .ginput_container_select select {
      padding-top: 14px;
      padding-bottom: 6px; } }
  /* line 675, assets/sass/components/_form.scss */
  .gform_wrapper form .gform_footer {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      /* line 675, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_footer {
        justify-content: center;
        padding-top: 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 675, assets/sass/components/_form.scss */
      .gform_wrapper form .gform_footer {
        justify-content: center;
        padding-top: 30px; } }
    /* line 690, assets/sass/components/_form.scss */
    .gform_wrapper form .gform_footer button {
      display: inline-block;
      position: relative;
      font-family: "TideSans-600Bunny", sans-serif;
      font-weight: normal;
      font-size: 16px;
      color: #231F20;
      text-decoration: none;
      line-height: 1;
      transition: color .2s;
      background-color: #FFF;
      background: none;
      padding: 0;
      border: none;
      cursor: pointer;
      width: 220px; }
      /* line 13, assets/sass/components/_buttons.scss */
      .gform_wrapper form .gform_footer button:hover {
        color: #231F20; }
        /* line 15, assets/sass/components/_buttons.scss */
        .gform_wrapper form .gform_footer button:hover span:after {
          width: calc(100% - 5px); }
      /* line 20, assets/sass/components/_buttons.scss */
      .gform_wrapper form .gform_footer button span {
        padding: 18px 38px 16px;
        display: block;
        position: relative;
        z-index: 2;
        border: 1px solid #231F20;
        pointer-events: none; }
        /* line 28, assets/sass/components/_buttons.scss */
        .gform_wrapper form .gform_footer button span:after {
          content: '';
          width: 0;
          height: calc(100% - 5px);
          position: absolute;
          right: 0;
          top: 5px;
          background-color: #FFE600;
          z-index: -1;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
      /* line 43, assets/sass/components/_buttons.scss */
      .gform_wrapper form .gform_footer button:after {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        right: -6px;
        top: 6px;
        background-color: #FFE600;
        z-index: 0;
        transition: width 0.1s cubic-bezier(1, 0, 0, 1);
        transform: translateZ(0); }
      /* line 56, assets/sass/components/_buttons.scss */
      .gform_wrapper form .gform_footer button:before {
        content: '';
        width: 100%;
        height: 6px;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: #FFE600;
        z-index: 0;
        transform: translate(6px, 0);
        pointer-events: none; }

/* line 704, assets/sass/components/_form.scss */
.gform_footer {
  position: relative; }

/* line 708, assets/sass/components/_form.scss */
.gform_ajax_spinner {
  position: absolute;
  left: calc(100% + 15px);
  top: -15px;
  width: 30px;
  height: 30px; }

/* line 718, assets/sass/components/_form.scss */
.gform_validation_container {
  display: none; }

/* line 722, assets/sass/components/_form.scss */
body .select2-container--gform {
  font-size: 14px;
  border-bottom: 1px solid #231F20;
  outline: none; }
  /* line 727, assets/sass/components/_form.scss */
  body .select2-container--gform .select2-selection--single {
    height: 50px;
    outline: none; }
    /* line 731, assets/sass/components/_form.scss */
    body .select2-container--gform .select2-selection--single .select2-selection__placeholder {
      color: #231F20;
      font-size: 16px; }
    /* line 736, assets/sass/components/_form.scss */
    body .select2-container--gform .select2-selection--single .select2-selection__rendered {
      line-height: 50px; }
    /* line 740, assets/sass/components/_form.scss */
    body .select2-container--gform .select2-selection--single .select2-selection__arrow {
      height: 48px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px; }
      /* line 747, assets/sass/components/_form.scss */
      body .select2-container--gform .select2-selection--single .select2-selection__arrow b {
        border-color: #231F20 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
        transition: transform 0.2s; }
  /* line 765, assets/sass/components/_form.scss */
  body .select2-container--gform.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #231F20 transparent transparent transparent;
    transform: rotate(180deg); }
  /* line 773, assets/sass/components/_form.scss */
  body .select2-container--gform .select2-dropdown {
    border-color: #231F20;
    border-radius: 0; }
  /* line 779, assets/sass/components/_form.scss */
  body .select2-container--gform .select2-results__options {
    max-height: 400px;
    overflow: auto; }
    /* line 783, assets/sass/components/_form.scss */
    body .select2-container--gform .select2-results__options .select2-results__option {
      color: #CD007A;
      transition: background 0.2s;
      padding: 7px 6px; }
      /* line 788, assets/sass/components/_form.scss */
      body .select2-container--gform .select2-results__options .select2-results__option:hover {
        background: #EEEDED; }
      /* line 792, assets/sass/components/_form.scss */
      body .select2-container--gform .select2-results__options .select2-results__option:first-child {
        display: none; }
      /* line 796, assets/sass/components/_form.scss */
      body .select2-container--gform .select2-results__options .select2-results__option:nth-child(even) {
        background-color: #EEEDED; }
      /* line 800, assets/sass/components/_form.scss */
      body .select2-container--gform .select2-results__options .select2-results__option.select2-results__option--highlighted, body .select2-container--gform .select2-results__options .select2-results__option:hover {
        background-color: #FFE600;
        color: #231F20; }

/* line 809, assets/sass/components/_form.scss */
.fancybox-button--close {
  background: transparent !important;
  top: 50px !important;
  right: 50px !important;
  position: absolute;
  padding: 0 !important;
  margin: 0 !important;
  width: 50px !important;
  height: 50px !important;
  outline: none !important; }
  @media (max-width: 991.98px) {
    /* line 809, assets/sass/components/_form.scss */
    .fancybox-button--close {
      top: 15px !important;
      right: 15px !important; } }
  /* line 825, assets/sass/components/_form.scss */
  .fancybox-button--close span {
    display: block;
    width: 50px;
    height: 50px;
    position: relative; }
    /* line 831, assets/sass/components/_form.scss */
    .fancybox-button--close span:before, .fancybox-button--close span:after {
      content: '';
      width: 50px;
      height: 2px;
      background-color: #FFE600;
      display: block;
      position: absolute;
      top: calc(50% - 1px); }
    /* line 842, assets/sass/components/_form.scss */
    .fancybox-button--close span:before {
      transform: rotate(45deg); }
    /* line 846, assets/sass/components/_form.scss */
    .fancybox-button--close span:after {
      transform: rotate(-45deg); }

/* line 854, assets/sass/components/_form.scss */
.fancybox-bg {
  opacity: 0.95 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a836aa+0,823ea3+44,483279+100 */
  background: #a836aa !important;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #a836aa 0%, #823ea3 44%, #483279 100%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #a836aa 0%, #823ea3 44%, #483279 100%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #a836aa 0%, #823ea3 44%, #483279 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a836aa', endColorstr='#483279',GradientType=1 ) !important;
  /* IE6-9 fallback on horizontal gradient */ }

/* line 867, assets/sass/components/_form.scss */
.fancybox-slide .ak-fancy-forms {
  max-width: 620px;
  background-color: transparent;
  padding: 24px 0; }
  /* line 872, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .fancybox-close-small {
    display: none; }
  /* line 877, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_confirmation_wrapper .gform_confirmation_message {
    color: #FFF;
    font-family: "TideSans-300LilKahuna", sans-serif; }
    /* line 881, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_confirmation_wrapper .gform_confirmation_message a {
      color: #FFE600;
      transition: color .2s; }
      /* line 884, assets/sass/components/_form.scss */
      .fancybox-slide .ak-fancy-forms .gform_confirmation_wrapper .gform_confirmation_message a:hover {
        color: #CD007A; }
    /* line 889, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_confirmation_wrapper .gform_confirmation_message strong {
      font-family: "TideSans-600Bunny", sans-serif;
      font-weight: normal; }
  /* line 898, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_heading {
    padding: 0 0 20px 0; }
    /* line 900, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_heading h3 {
      border-left: 0;
      padding: 0;
      text-align: center;
      color: #FFF;
      transform: translateX(0) !important; }
      /* line 908, assets/sass/components/_form.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_heading h3:before {
        content: '';
        display: block;
        width: 70px;
        height: 70px;
        background-image: url("../img/paper-plane.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 70px;
        margin: 0 auto 20px; }
  /* line 923, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gfield_label {
    color: #FFF; }
  /* line 926, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body input[type="text"], .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body input[type="email"], .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body textarea {
    border-color: #FFF;
    color: #FFF; }
  /* line 931, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .select2-container--gform {
    border-color: #FFF;
    color: #FFF; }
    /* line 935, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .select2-container--gform .select2-selection--single .select2-selection__arrow b {
      border-color: #FFF transparent transparent transparent; }
  /* line 940, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_drop_area {
    border: 1px dashed #FFF;
    background-color: rgba(255, 255, 255, 0.3); }
    /* line 943, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_drop_area span {
      color: #FFF; }
  /* line 949, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .ginput_preview strong {
    font-weight: normal;
    color: #FFF; }
  /* line 955, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gfield_description {
    color: rgba(255, 255, 255, 0.7); }
  /* line 963, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_radio ul.gfield_radio li label {
    color: #FFF; }
    /* line 965, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_radio ul.gfield_radio li label:before {
      border: 1px solid #FFF; }
    /* line 966, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_radio ul.gfield_radio li label:after {
      background-color: #FFE600; }
  /* line 974, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_html .editor_content em {
    color: #9D9FA2; }
    /* line 976, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_html .editor_content em a {
      color: #FFE600; }
      /* line 978, assets/sass/components/_form.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .gform_html .editor_content em a:hover {
        color: #9D9FA2; }
  /* line 986, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check {
    width: 100%; }
    /* line 988, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check ul {
      justify-content: center; }
      /* line 991, assets/sass/components/_form.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check ul li label {
        color: #FFF; }
        /* line 993, assets/sass/components/_form.scss */
        .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check ul li label a {
          color: #FFE600;
          text-decoration: underline; }
        /* line 997, assets/sass/components/_form.scss */
        .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check ul li label:before {
          border-color: #FFF; }
        /* line 1000, assets/sass/components/_form.scss */
        .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_body .pul-check ul li label:after {
          background-image: url("../img/form-checkbox-yellow.svg"); }
  /* line 1008, assets/sass/components/_form.scss */
  .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer {
    justify-content: center;
    padding-top: 30px; }
    /* line 1011, assets/sass/components/_form.scss */
    .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button {
      transform: translateY(0);
      display: inline-block;
      position: relative;
      font-family: "TideSans-600Bunny", sans-serif;
      font-weight: normal;
      font-size: 16px;
      color: #FFF;
      text-decoration: none;
      line-height: 1;
      transition: color .2s;
      background-color: #FFF;
      background-color: transparent !important; }
      /* line 13, assets/sass/components/_buttons.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button:hover {
        color: #231F20; }
        /* line 15, assets/sass/components/_buttons.scss */
        .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button:hover span:after {
          width: calc(100% - 5px); }
      /* line 20, assets/sass/components/_buttons.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button span {
        padding: 18px 38px 16px;
        display: block;
        position: relative;
        z-index: 2;
        border: 1px solid #FFF;
        pointer-events: none; }
        /* line 28, assets/sass/components/_buttons.scss */
        .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button span:after {
          content: '';
          width: 0;
          height: calc(100% - 5px);
          position: absolute;
          right: 0;
          top: 5px;
          background-color: #FFE600;
          z-index: -1;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
      /* line 43, assets/sass/components/_buttons.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button:after {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        right: -6px;
        top: 6px;
        background-color: #FFE600;
        z-index: 0;
        transition: width 0.1s cubic-bezier(1, 0, 0, 1);
        transform: translateZ(0); }
      /* line 56, assets/sass/components/_buttons.scss */
      .fancybox-slide .ak-fancy-forms .gform_wrapper form .gform_footer button:before {
        content: '';
        width: 100%;
        height: 6px;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: #FFE600;
        z-index: 0;
        transform: translate(6px, 0);
        pointer-events: none; }

/* line 1023, assets/sass/components/_form.scss */
.fancybox-is-open + .select2-container--open {
  z-index: 99992; }

/* line 1029, assets/sass/components/_form.scss */
.validation-ok::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: '\f00c';
  color: #7CC366;
  position: absolute;
  right: 5px; }

/* line 1039, assets/sass/components/_form.scss */
.validation-fail::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: '\f12a';
  color: #CD007A;
  position: absolute;
  right: 5px; }

/* line 1048, assets/sass/components/_form.scss */
.validation-fail-wrapper {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffe4e4+100 */
  background: white;
  /* Old browsers */
  background: -moz-linear-gradient(top, white 10%, #ffe4e4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, white 10%, #ffe4e4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, white 10%, #ffe4e4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffe4e4',GradientType=0 );
  /* IE6-9 */ }
  /* line 1056, assets/sass/components/_form.scss */
  .validation-fail-wrapper input[type=email] {
    border-color: red !important; }

/* General GF Date Picker */
/* line 1062, assets/sass/components/_form.scss */
#ui-datepicker-div {
  background: #FFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: none;
  padding: 20px;
  width: 300px; }
  /* line 1071, assets/sass/components/_form.scss */
  #ui-datepicker-div a {
    color: #CD007A;
    font-family: "TideSans-600Bunny", sans-serif; }
    /* line 1075, assets/sass/components/_form.scss */
    #ui-datepicker-div a:hover {
      background-color: #CD007A;
      color: #FFF;
      text-decoration: none; }
  /* line 1082, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-current-day {
    background: #FFE600; }
    /* line 1084, assets/sass/components/_form.scss */
    #ui-datepicker-div .ui-datepicker-current-day a {
      color: #231F20 !important; }
  /* line 1090, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-unselectable {
    color: #9D9FA2; }
  /* line 1094, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-icon {
    color: transparent;
    cursor: pointer;
    font-size: 0px; }
  /* line 1097, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-icon:before {
    font-family: "FontAwesome";
    font-size: 18px; }
  /* line 1102, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-prev {
    float: left;
    width: 10%; }
    /* line 1104, assets/sass/components/_form.scss */
    #ui-datepicker-div .ui-datepicker-prev .ui-icon:before {
      content: '\f060'; }
    /* line 1107, assets/sass/components/_form.scss */
    #ui-datepicker-div .ui-datepicker-prev.ui-state-disabled {
      display: none; }
  /* line 1111, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-next {
    float: right;
    width: 10%; }
    /* line 1114, assets/sass/components/_form.scss */
    #ui-datepicker-div .ui-datepicker-next .ui-icon:before {
      content: '\f061';
      float: right; }
    /* line 1118, assets/sass/components/_form.scss */
    #ui-datepicker-div .ui-datepicker-next.ui-state-disabled {
      display: none; }
  /* line 1124, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-title select {
    float: left;
    width: 70%; }
  /* line 1128, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-title .ui-datepicker-year, #ui-datepicker-div .ui-datepicker-title .ui-datepicker-month {
    margin: 0px 15% 6px; }
  /* line 1133, assets/sass/components/_form.scss */
  #ui-datepicker-div .ui-datepicker-today {
    background-color: #EEEDED;
    color: #231F20; }
  /* line 1138, assets/sass/components/_form.scss */
  #ui-datepicker-div table {
    width: 100%; }
    /* line 1140, assets/sass/components/_form.scss */
    #ui-datepicker-div table td, #ui-datepicker-div table th {
      text-align: center; }
    /* line 1144, assets/sass/components/_form.scss */
    #ui-datepicker-div table td a {
      display: block;
      padding: 5px; }

/* line 1154, assets/sass/components/_form.scss */
.ritningsproduktion_wrapper .gform_heading {
  text-align: center;
  margin-bottom: 20px; }
  /* line 1158, assets/sass/components/_form.scss */
  .ritningsproduktion_wrapper .gform_heading .gform_title {
    color: #231F20;
    font-family: "TideSans-300LilKahuna", sans-serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    margin: 0;
    text-transform: none; }
    @media (max-width: 767.98px) {
      /* line 1158, assets/sass/components/_form.scss */
      .ritningsproduktion_wrapper .gform_heading .gform_title {
        font-size: 25px;
        line-height: 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 1158, assets/sass/components/_form.scss */
      .ritningsproduktion_wrapper .gform_heading .gform_title {
        font-size: 25px;
        line-height: 30px; } }

/* line 1168, assets/sass/components/_form.scss */
.ritningsproduktion_wrapper form .gform_body ul li.gsection {
  text-align: center; }

/* line 1172, assets/sass/components/_form.scss */
.ritningsproduktion_wrapper form .gform_body ul li .validation_message {
  position: static;
  text-align: left;
  padding: 0;
  padding-top: 10px; }

/* line 1181, assets/sass/components/_form.scss */
.ritningsproduktion_wrapper form .gform_body .gform_page_footer {
  text-align: center; }
  /* line 1184, assets/sass/components/_form.scss */
  .ritningsproduktion_wrapper form .gform_body .gform_page_footer button {
    display: inline-block;
    position: relative;
    font-family: "TideSans-600Bunny", sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #231F20;
    text-decoration: none;
    line-height: 1;
    transition: color .2s;
    background-color: #FFF;
    border: none;
    cursor: pointer; }
    /* line 13, assets/sass/components/_buttons.scss */
    .ritningsproduktion_wrapper form .gform_body .gform_page_footer button:hover {
      color: #231F20; }
      /* line 15, assets/sass/components/_buttons.scss */
      .ritningsproduktion_wrapper form .gform_body .gform_page_footer button:hover span:after {
        width: calc(100% - 5px); }
    /* line 20, assets/sass/components/_buttons.scss */
    .ritningsproduktion_wrapper form .gform_body .gform_page_footer button span {
      padding: 18px 38px 16px;
      display: block;
      position: relative;
      z-index: 2;
      border: 1px solid #231F20;
      pointer-events: none; }
      /* line 28, assets/sass/components/_buttons.scss */
      .ritningsproduktion_wrapper form .gform_body .gform_page_footer button span:after {
        content: '';
        width: 0;
        height: calc(100% - 5px);
        position: absolute;
        right: 0;
        top: 5px;
        background-color: #FFE600;
        z-index: -1;
        transition: width 0.2s cubic-bezier(1, 0, 0, 1); }
    /* line 43, assets/sass/components/_buttons.scss */
    .ritningsproduktion_wrapper form .gform_body .gform_page_footer button:after {
      content: '';
      width: 6px;
      height: 100%;
      position: absolute;
      right: -6px;
      top: 6px;
      background-color: #FFE600;
      z-index: 0;
      transition: width 0.1s cubic-bezier(1, 0, 0, 1);
      transform: translateZ(0); }
    /* line 56, assets/sass/components/_buttons.scss */
    .ritningsproduktion_wrapper form .gform_body .gform_page_footer button:before {
      content: '';
      width: 100%;
      height: 6px;
      position: absolute;
      right: 0;
      top: 100%;
      background-color: #FFE600;
      z-index: 0;
      transform: translate(6px, 0);
      pointer-events: none; }

/* line 1194, assets/sass/components/_form.scss */
.percentbar_100 {
  display: none; }

/* line 1, assets/sass/components/_perspectiveMenu.scss */
html, body, .perspective {
  width: 100%;
  height: 100%; }

/* line 6, assets/sass/components/_perspectiveMenu.scss */
.perspective {
  position: relative;
  height: calc(100% + 1px); }
  /* line 10, assets/sass/components/_perspectiveMenu.scss */
  .perspective.animate main {
    padding-top: 0; }
  /* line 16, assets/sass/components/_perspectiveMenu.scss */
  .perspective button.close-menu {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0 !important;
    line-height: 1;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    background: none;
    cursor: pointer;
    outline: none; }
    @media only screen and (max-width: 900px) {
      /* line 16, assets/sass/components/_perspectiveMenu.scss */
      .perspective button.close-menu {
        width: 25px;
        height: 25px;
        top: 15px;
        right: 15px; } }
    /* line 38, assets/sass/components/_perspectiveMenu.scss */
    .perspective button.close-menu span {
      display: block;
      width: 50px;
      height: 50px; }
      @media only screen and (max-width: 900px) {
        /* line 38, assets/sass/components/_perspectiveMenu.scss */
        .perspective button.close-menu span {
          width: 25px;
          height: 25px; } }
      /* line 49, assets/sass/components/_perspectiveMenu.scss */
      .perspective button.close-menu span:after, .perspective button.close-menu span:before {
        content: '';
        height: 2px;
        width: 50px;
        display: block;
        background-color: #FFE600;
        position: absolute;
        top: 50%;
        left: 0; }
        @media only screen and (max-width: 900px) {
          /* line 49, assets/sass/components/_perspectiveMenu.scss */
          .perspective button.close-menu span:after, .perspective button.close-menu span:before {
            width: 25px; } }
      /* line 66, assets/sass/components/_perspectiveMenu.scss */
      .perspective button.close-menu span:before {
        transform: rotate(45deg); }
      /* line 69, assets/sass/components/_perspectiveMenu.scss */
      .perspective button.close-menu span:after {
        transform: rotate(-45deg); }

/* line 77, assets/sass/components/_perspectiveMenu.scss */
.perspective-container {
  background: #fff;
  min-height: 100%;
  position: relative;
  outline: 1px solid rgba(0, 0, 0, 0);
  z-index: 10;
  -webkit-transform: translateZ(0) translateX(0) rotateY(0deg);
  /* reset transforms (Chrome bug) */
  transform: translateZ(0) translateX(0) rotateY(0deg); }

/* line 87, assets/sass/components/_perspectiveMenu.scss */
.perspective-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  /* the transition delay of the height needs to be synced with the perspective-container transition time */
  -webkit-transition: opacity 0.4s, height 0s 0.4s;
  transition: opacity 0.4s, height 0s 0.4s; }

/* line 102, assets/sass/components/_perspectiveMenu.scss */
.perspective-wrapper {
  position: relative; }

/* Modal view */
/* line 108, assets/sass/components/_perspectiveMenu.scss */
.perspective.modalview {
  position: fixed;
  -webkit-perspective: 1500px;
  perspective: 1500px; }

/* line 114, assets/sass/components/_perspectiveMenu.scss */
.modalview .perspective-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/* line 124, assets/sass/components/_perspectiveMenu.scss */
.modalview .perspective-wrapper {
  -webkit-transform: translateZ(-1px);
  /* solves a rendering bug in Chrome on Windows */ }

/* line 128, assets/sass/components/_perspectiveMenu.scss */
.animate .perspective-container::after {
  opacity: 1;
  height: 101%;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }

/* Outer Nav */
/* line 136, assets/sass/components/_perspectiveMenu.scss */
.outer-nav {
  position: absolute;
  height: auto; }

/* line 142, assets/sass/components/_perspectiveMenu.scss */
.outer-nav.vertical {
  top: 50%;
  transform: translateY(-50%);
  transform-style: preserve-3d; }

/* line 148, assets/sass/components/_perspectiveMenu.scss */
.outer-nav.right {
  right: 25%; }
  @media (max-width: 767.98px) {
    /* line 148, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav.right {
      right: auto;
      left: 80px;
      width: calc(100% - 80px); } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 148, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav.right {
      right: auto;
      left: 340px; } }

@media only screen and (max-width: 900px) {
  /* line 167, assets/sass/components/_perspectiveMenu.scss */
  .outer-nav > ul {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden; } }

@media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
  /* line 167, assets/sass/components/_perspectiveMenu.scss */
  .outer-nav > ul {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden; } }

/* line 181, assets/sass/components/_perspectiveMenu.scss */
.outer-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  /* line 187, assets/sass/components/_perspectiveMenu.scss */
  .outer-nav ul.inactive > li {
    display: none !important; }
    /* line 189, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul.inactive > li.active {
      display: block !important; }
      /* line 191, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul.inactive > li.active > a {
        display: none; }
  /* line 198, assets/sass/components/_perspectiveMenu.scss */
  .outer-nav ul > li {
    margin: 0 0 15px 0; }
    @media only screen and (max-width: 900px) {
      /* line 198, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li {
        width: 100%;
        max-width: 300px; } }
    /* line 206, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li:last-of-type {
      margin: 0 0 0 0; }
    /* line 211, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li.active > ul.sub-menu {
      display: block; }
    /* line 217, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li.menu-item-has-children > a:before {
      background-image: url("../img/menu-submenu.svg"); }
    @media (max-width: 767.98px) {
      /* line 222, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li.hide-more {
        display: none; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 222, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li.hide-more {
        display: none; } }
    /* line 229, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li.hide-more.visible {
      display: block; }
    /* line 234, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li.mobile-only {
      display: none; }
      @media only screen and (max-width: 900px) {
        /* line 234, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li.mobile-only {
          display: block; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 234, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li.mobile-only {
          display: block; } }
    /* line 244, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li span.show-more-menu {
      font-family: "TideSans-300LilKahuna", sans-serif;
      font-size: 18px;
      color: #FFE600;
      padding: 0 0 0 30px;
      position: relative;
      cursor: pointer;
      display: none; }
      @media only screen and (max-width: 900px) {
        /* line 244, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li span.show-more-menu {
          display: block; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 244, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li span.show-more-menu {
          display: block; } }
      /* line 259, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li span.show-more-menu:before {
        content: '...';
        display: block;
        width: 23px;
        position: absolute;
        top: 0;
        left: 5px;
        letter-spacing: 1px; }
    /* line 270, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li > a {
      color: #fff;
      text-decoration: none;
      font-size: 24px;
      color: #FFF;
      font-family: "TideSans-300LilKahuna", sans-serif;
      transition: transform 0.4s, opacity 0.4s, color .2s;
      position: relative;
      padding: 0 0 0 35px; }
      @media only screen and (max-width: 900px) {
        /* line 270, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li > a {
          font-size: 18px;
          padding: 0 0 0 30px;
          display: block;
          width: 100%;
          max-width: 300px; } }
      /* line 291, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li > a:before {
        display: block;
        width: 29px;
        height: 29px;
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        background-image: url("../img/menu-arrow.svg");
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center; }
        @media only screen and (max-width: 900px) {
          /* line 291, assets/sass/components/_perspectiveMenu.scss */
          .outer-nav ul > li > a:before {
            width: 23px;
            height: 23px;
            background-size: 16px; } }
      /* line 312, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li > a:hover {
        color: #FFE600; }
    /* line 317, assets/sass/components/_perspectiveMenu.scss */
    .outer-nav ul > li > ul.sub-menu {
      display: none;
      overflow: visible;
      width: 100%; }
      /* line 321, assets/sass/components/_perspectiveMenu.scss */
      .outer-nav ul > li > ul.sub-menu > li {
        transform: translateX(60px); }
        /* line 322, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li > ul.sub-menu > li:nth-of-type(2) {
          transform: translateX(30px); }
          @media only screen and (max-width: 900px) {
            /* line 322, assets/sass/components/_perspectiveMenu.scss */
            .outer-nav ul > li > ul.sub-menu > li:nth-of-type(2) {
              transform: translateX(0); } }
        @media only screen and (max-width: 900px) {
          /* line 321, assets/sass/components/_perspectiveMenu.scss */
          .outer-nav ul > li > ul.sub-menu > li {
            transform: translateX(0); } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 334, assets/sass/components/_perspectiveMenu.scss */
          .outer-nav ul > li > ul.sub-menu > li a {
            word-break: break-all; } }
        /* line 340, assets/sass/components/_perspectiveMenu.scss */
        .outer-nav ul > li > ul.sub-menu > li.back-to-start {
          color: #FFE600;
          font-family: "TideSans-300LilKahuna", sans-serif;
          font-size: 24px;
          position: relative;
          padding-left: 35px;
          transform: translateX(0);
          margin: 0 0 40px 0;
          cursor: pointer; }
          @media only screen and (max-width: 900px) {
            /* line 340, assets/sass/components/_perspectiveMenu.scss */
            .outer-nav ul > li > ul.sub-menu > li.back-to-start {
              font-size: 18px; } }
          /* line 354, assets/sass/components/_perspectiveMenu.scss */
          .outer-nav ul > li > ul.sub-menu > li.back-to-start:before {
            display: block;
            width: 29px;
            height: 29px;
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            background-image: url("../img/menu-back-arrow.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center center; }
            @media only screen and (max-width: 900px) {
              /* line 354, assets/sass/components/_perspectiveMenu.scss */
              .outer-nav ul > li > ul.sub-menu > li.back-to-start:before {
                width: 23px;
                height: 23px;
                background-size: 16px; } }

/* Effect Move Left */
/* line 384, assets/sass/components/_perspectiveMenu.scss */
.effect-moveleft {
  background-image: url("../img/perspective-background.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover; }

/* line 391, assets/sass/components/_perspectiveMenu.scss */
.effect-moveleft .perspective-container {
  -webkit-transition: -webkit-transform 0.4s, -webkit-opacity .4s;
  transition: transform 0.4s, opacity .4s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

/* line 398, assets/sass/components/_perspectiveMenu.scss */
.effect-moveleft .perspective-container::after {
  background: rgba(255, 255, 255, 0.6); }

/* line 402, assets/sass/components/_perspectiveMenu.scss */
.effect-moveleft.animate .perspective-container {
  transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
  opacity: .6; }
  @media only screen and (max-width: 900px) {
    /* line 402, assets/sass/components/_perspectiveMenu.scss */
    .effect-moveleft.animate .perspective-container {
      transform: translateX(-50%) rotateY(45deg) translateZ(-150px); } }

/* line 412, assets/sass/components/_perspectiveMenu.scss */
.no-csstransforms3d .effect-moveleft.animate .perspective-container {
  left: -75%; }

@media screen and (max-height: 31.6em) {
  /* line 417, assets/sass/components/_perspectiveMenu.scss */
  .outer-nav a {
    margin-bottom: 20px; } }

/* line 1, assets/sass/components/_404.scss */
main.background-404 {
  min-height: 800px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/_404.scss */
    main.background-404 {
      background-size: auto 80%;
      background-position: center 55px;
      min-height: 500px; } }
  /* line 16, assets/sass/components/_404.scss */
  main.background-404 .editor_content h1, main.background-404 .editor_content h2, main.background-404 .editor_content h3, main.background-404 .editor_content h4 {
    color: #FFF;
    margin: 0 0 .4em 0; }
  /* line 21, assets/sass/components/_404.scss */
  main.background-404 .editor_content p {
    font-family: "TideSans-600Bunny", sans-serif;
    font-size: 22px;
    color: #FFF;
    text-transform: uppercase;
    line-height: 1.2em; }
    @media (max-width: 767.98px) {
      /* line 21, assets/sass/components/_404.scss */
      main.background-404 .editor_content p {
        font-size: 16px;
        line-height: 20px; } }
  /* line 33, assets/sass/components/_404.scss */
  main.background-404 .editor_content form {
    width: 100%;
    margin: 30px auto 0;
    max-width: 450px;
    background-color: #FFF; }
    /* line 41, assets/sass/components/_404.scss */
    main.background-404 .editor_content form input[type="search"] {
      width: calc(100% - 90px);
      float: left;
      height: 50px;
      font-family: "TideSans-300LilKahuna", sans-serif;
      font-size: 16px;
      color: #231F20;
      border: none;
      background: none; }
      /* line 19, assets/sass/components/_mixins.scss */
      main.background-404 .editor_content form input[type="search"].placeholder {
        opacity: 1; }
      /* line 20, assets/sass/components/_mixins.scss */
      main.background-404 .editor_content form input[type="search"]:-moz-placeholder {
        opacity: 1; }
      /* line 21, assets/sass/components/_mixins.scss */
      main.background-404 .editor_content form input[type="search"]::-moz-placeholder {
        opacity: 1; }
      /* line 22, assets/sass/components/_mixins.scss */
      main.background-404 .editor_content form input[type="search"]:-ms-input-placeholder {
        opacity: 1; }
      /* line 23, assets/sass/components/_mixins.scss */
      main.background-404 .editor_content form input[type="search"]::-webkit-input-placeholder {
        opacity: 1; }
    /* line 57, assets/sass/components/_404.scss */
    main.background-404 .editor_content form button {
      width: 90px;
      margin: 0;
      padding: 0;
      float: left;
      height: 50px;
      border: none;
      background: none;
      text-align: right;
      font-size: 16px;
      color: #CD007A;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      cursor: pointer;
      outline: none; }

/* line 1, assets/sass/components/_mega-menu.scss */
nav.mega-menu {
  position: fixed;
  left: 0;
  top: 78px;
  width: 100%;
  z-index: 900;
  height: calc(100vh - 78px);
  background-image: url("../img/perspective-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: none;
  overflow-y: auto; }
  /* line 19, assets/sass/components/_mega-menu.scss */
  nav.mega-menu .select2-container--default {
    margin-top: 30px; }
  /* line 24, assets/sass/components/_mega-menu.scss */
  nav.mega-menu button.close-menu {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0 !important;
    line-height: 1;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    background: none;
    cursor: pointer; }
    /* line 38, assets/sass/components/_mega-menu.scss */
    nav.mega-menu button.close-menu span {
      display: block;
      width: 50px;
      height: 50px; }
      /* line 43, assets/sass/components/_mega-menu.scss */
      nav.mega-menu button.close-menu span:after, nav.mega-menu button.close-menu span:before {
        content: '';
        height: 2px;
        width: 50px;
        display: block;
        background-color: #FFE600;
        position: absolute;
        top: 50%;
        left: 0; }
      /* line 55, assets/sass/components/_mega-menu.scss */
      nav.mega-menu button.close-menu span:before {
        transform: rotate(45deg); }
      /* line 58, assets/sass/components/_mega-menu.scss */
      nav.mega-menu button.close-menu span:after {
        transform: rotate(-45deg); }
  /* line 66, assets/sass/components/_mega-menu.scss */
  nav.mega-menu div.editor_content {
    padding-top: 50px; }
    /* line 68, assets/sass/components/_mega-menu.scss */
    nav.mega-menu div.editor_content h3 {
      color: #FFF;
      display: inline-block;
      width: 100%;
      margin-bottom: .4em; }
  /* line 76, assets/sass/components/_mega-menu.scss */
  nav.mega-menu a.editor_content {
    color: #FFF;
    margin-bottom: 40px;
    display: block;
    text-decoration: none; }
    /* line 81, assets/sass/components/_mega-menu.scss */
    nav.mega-menu a.editor_content em {
      font-style: normal;
      color: #FFE600;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      font-size: 16px;
      margin: 0 0 .3em 0;
      display: inline-block;
      width: 100%; }
    /* line 92, assets/sass/components/_mega-menu.scss */
    nav.mega-menu a.editor_content p {
      margin: 0;
      font-family: "TideSans-300LilKahuna", sans-serif;
      font-size: 14px;
      color: #FFF; }
    /* line 99, assets/sass/components/_mega-menu.scss */
    nav.mega-menu a.editor_content ul {
      margin: 0 0 0 14px;
      padding: 0; }
      /* line 103, assets/sass/components/_mega-menu.scss */
      nav.mega-menu a.editor_content ul li {
        font-family: "TideSans-300LilKahuna", sans-serif;
        font-size: 14px;
        color: #FFF; }

/* line 1, assets/sass/components/_search.scss */
main.search-result {
  padding-top: 200px;
  min-height: calc(100vh - 395px); }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/_search.scss */
    main.search-result {
      padding-top: 140px; } }
  /* line 10, assets/sass/components/_search.scss */
  main.search-result .search-row h1 {
    margin: 0 0 1em 0; }
    @media (max-width: 767.98px) {
      /* line 10, assets/sass/components/_search.scss */
      main.search-result .search-row h1 {
        margin: 0 0 .5em 0; } }
  /* line 18, assets/sass/components/_search.scss */
  main.search-result .no-result {
    margin-bottom: 80px; }
    @media (max-width: 767.98px) {
      /* line 18, assets/sass/components/_search.scss */
      main.search-result .no-result {
        margin-bottom: 30px; } }
  /* line 26, assets/sass/components/_search.scss */
  main.search-result .search-group {
    margin-bottom: 80px; }
    @media (max-width: 767.98px) {
      /* line 26, assets/sass/components/_search.scss */
      main.search-result .search-group {
        margin-bottom: 30px; } }
    /* line 33, assets/sass/components/_search.scss */
    main.search-result .search-group .section-title {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      line-height: 1;
      color: #231F20;
      font-size: 19px;
      font-weight: normal;
      font-weight: normal;
      margin: 0 0 2em 0; }
      @media (max-width: 767.98px) {
        /* line 33, assets/sass/components/_search.scss */
        main.search-result .search-group .section-title {
          font-size: 16px; } }
      /* line 31, assets/sass/components/_variables.scss */
      main.search-result .search-group .section-title:before {
        content: '-';
        display: inline-block;
        margin-right: 5px; }
      /* line 36, assets/sass/components/_variables.scss */
      main.search-result .search-group .section-title:after {
        content: '-';
        display: inline-block;
        margin-left: 5px; }
      /* line 40, assets/sass/components/_search.scss */
      main.search-result .search-group .section-title em {
        font-style: normal; }
    /* line 46, assets/sass/components/_search.scss */
    main.search-result .search-group .post-row {
      margin-bottom: 60px; }
      /* line 47, assets/sass/components/_search.scss */
      main.search-result .search-group .post-row:last-of-type {
        margin-bottom: 0; }
      @media (max-width: 767.98px) {
        /* line 46, assets/sass/components/_search.scss */
        main.search-result .search-group .post-row {
          margin-bottom: 30px; } }
      @media (max-width: 767.98px) {
        /* line 56, assets/sass/components/_search.scss */
        main.search-result .search-group .post-row .featured-image {
          margin-bottom: 15px; } }
      /* line 62, assets/sass/components/_search.scss */
      main.search-result .search-group .post-row .h2 {
        margin: 0 0 .25em 0; }
        /* line 63, assets/sass/components/_search.scss */
        main.search-result .search-group .post-row .h2 a {
          color: #CD007A;
          text-decoration: none;
          position: relative;
          transition: color .2s; }
          /* line 68, assets/sass/components/_search.scss */
          main.search-result .search-group .post-row .h2 a:hover {
            color: #231F20; }
      /* line 75, assets/sass/components/_search.scss */
      main.search-result .search-group .post-row em {
        font-style: normal;
        font-size: 16px;
        color: #9D9FA2;
        display: block;
        width: 100%;
        margin: 0 0 1em 0; }
        @media (max-width: 767.98px) {
          /* line 75, assets/sass/components/_search.scss */
          main.search-result .search-group .post-row em {
            font-size: 12px; } }

/* line 1, assets/sass/components/_productSearch.scss */
form.products-search {
  max-width: 572px; }

/*Wrap for spinner positioning inside select 2*/
/* line 6, assets/sass/components/_productSearch.scss */
.select-wrap {
  position: relative;
  max-width: 572px;
  display: flex;
  align-items: center; }
  /* line 11, assets/sass/components/_productSearch.scss */
  .select-wrap .spinner {
    width: 38px;
    background: #fff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 47px; }
    /* line 20, assets/sass/components/_productSearch.scss */
    .select-wrap .spinner svg {
      width: 80%; }
    /* line 23, assets/sass/components/_productSearch.scss */
    .select-wrap .spinner:not(.is-active) {
      display: none;
      /*comment it if want to test spinner and not to hide after search completed*/ }

/*Search spinner fix for mega menu*/
/* line 31, assets/sass/components/_productSearch.scss */
.mega-menu .select-wrap {
  margin-top: 30px; }
  /* line 33, assets/sass/components/_productSearch.scss */
  .mega-menu .select-wrap .select2-container--default {
    margin-top: 0; }

/* line 39, assets/sass/components/_productSearch.scss */
.select2-container--default {
  width: 100% !important; }
  /* line 45, assets/sass/components/_productSearch.scss */
  .select2-container--default.select2-container--open .select2-selection ul.select2-selection__rendered {
    background-color: #FFE600; }
    /* line 47, assets/sass/components/_productSearch.scss */
    .select2-container--default.select2-container--open .select2-selection ul.select2-selection__rendered:after {
      transform: rotate(-180deg); }
  /* line 55, assets/sass/components/_productSearch.scss */
  .select2-container--default span.selection {
    max-height: 51px;
    display: block; }
  /* line 60, assets/sass/components/_productSearch.scss */
  .select2-container--default .select2-selection {
    background-color: #FFF !important;
    border-radius: 0 !important;
    border: none !important;
    min-height: 0 !important;
    max-height: 51px; }
    /* line 67, assets/sass/components/_productSearch.scss */
    .select2-container--default .select2-selection ul.select2-selection__rendered {
      margin: 0;
      padding: 0;
      transition: background-color .2s;
      position: relative; }
      /* line 73, assets/sass/components/_productSearch.scss */
      .select2-container--default .select2-selection ul.select2-selection__rendered:after {
        content: '';
        width: 51px;
        height: 51px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform .2s;
        background-image: url("../img/chevron-down.svg");
        background-repeat: no-repeat;
        background-size: 18px 11px;
        background-position: center center; }
      /* line 89, assets/sass/components/_productSearch.scss */
      .select2-container--default .select2-selection ul.select2-selection__rendered .select2-selection__choice {
        margin: 8px;
        padding: 9px 10px 8px;
        background-color: #FFE600;
        color: #231F20;
        border-radius: 0;
        border: none;
        font-family: "TideSans-600Bunny", sans-serif;
        font-size: 14px; }
        /* line 100, assets/sass/components/_productSearch.scss */
        .select2-container--default .select2-selection ul.select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
          color: #231F20;
          transform: translateY(-1px);
          margin-right: 2px;
          display: inline-block; }
      /* line 108, assets/sass/components/_productSearch.scss */
      .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline {
        padding: 0;
        margin: 0;
        width: 100%;
        position: relative; }
        /* line 116, assets/sass/components/_productSearch.scss */
        .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"] {
          margin: 0 !important;
          height: 51px;
          width: calc(100% - 51px) !important;
          padding: 0 10px;
          color: #231F20; }
          /* line 19, assets/sass/components/_mixins.scss */
          .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"].placeholder {
            opacity: 1;
            font-size: 16px;
            color: #231F20; }
          /* line 20, assets/sass/components/_mixins.scss */
          .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"]:-moz-placeholder {
            opacity: 1;
            font-size: 16px;
            color: #231F20; }
          /* line 21, assets/sass/components/_mixins.scss */
          .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"]::-moz-placeholder {
            opacity: 1;
            font-size: 16px;
            color: #231F20; }
          /* line 22, assets/sass/components/_mixins.scss */
          .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"]:-ms-input-placeholder {
            opacity: 1;
            font-size: 16px;
            color: #231F20; }
          /* line 23, assets/sass/components/_mixins.scss */
          .select2-container--default .select2-selection ul.select2-selection__rendered .select2-search--inline input[type="search"]::-webkit-input-placeholder {
            opacity: 1;
            font-size: 16px;
            color: #231F20; }
  /* line 135, assets/sass/components/_productSearch.scss */
  .select2-container--default .select2-dropdown {
    border: none !important;
    border-radius: 0 !important; }
    /* line 138, assets/sass/components/_productSearch.scss */
    .select2-container--default .select2-dropdown .select2-results {
      padding: 0; }
      /* line 141, assets/sass/components/_productSearch.scss */
      .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option {
        padding: 0;
        font-size: 0;
        line-height: 0; }
        /* line 145, assets/sass/components/_productSearch.scss */
        .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option strong.select2-results__group {
          font-weight: normal;
          font-family: "TideSans-600Bunny", sans-serif;
          padding: 13px 10px 7px;
          line-height: 1;
          font-size: 14px; }
          /* line 151, assets/sass/components/_productSearch.scss */
          .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option strong.select2-results__group:nth-child(even) {
            background: #9D9FA2; }
        /* line 157, assets/sass/components/_productSearch.scss */
        .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option ul.select2-results__options--nested {
          padding: 0; }
          /* line 159, assets/sass/components/_productSearch.scss */
          .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option ul.select2-results__options--nested li.select2-results__option {
            padding: 5px 10px 5px 25px;
            color: #CD007A;
            transition: background-color .2s;
            font-size: 14px;
            line-height: 1.22em; }
            /* line 166, assets/sass/components/_productSearch.scss */
            .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option ul.select2-results__options--nested li.select2-results__option:nth-child(odd) {
              background-color: #EEEDED; }
            /* line 170, assets/sass/components/_productSearch.scss */
            .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option ul.select2-results__options--nested li.select2-results__option.select2-results__option--highlighted, .select2-container--default .select2-dropdown .select2-results ul.select2-results__options li.select2-results__option ul.select2-results__options--nested li.select2-results__option:hover {
              background-color: #FFE600;
              color: #231F20; }

/* line 1, assets/sass/components/_blog.scss */
section.component.post-roll {
  margin-top: -90px;
  margin-bottom: 120px; }
  @media (max-width: 991.98px) {
    /* line 1, assets/sass/components/_blog.scss */
    section.component.post-roll {
      margin-top: 30px; } }
  /* line 9, assets/sass/components/_blog.scss */
  section.component.post-roll article.post {
    position: relative;
    margin-bottom: 30px; }
    /* line 13, assets/sass/components/_blog.scss */
    section.component.post-roll article.post a.blog-thumb {
      width: 100%;
      height: 100%;
      max-height: 190px;
      overflow: hidden;
      display: block;
      background-color: #FFE600; }
    /* line 22, assets/sass/components/_blog.scss */
    section.component.post-roll article.post img {
      max-width: 100%;
      height: auto;
      display: block; }
    /* line 28, assets/sass/components/_blog.scss */
    section.component.post-roll article.post .editor_content {
      width: calc(100% - 30px);
      display: block;
      background-color: #FFF;
      padding: 30px 15px 30px 0;
      text-decoration: none !important;
      margin-top: -60px;
      position: relative;
      z-index: 2; }
      /* line 38, assets/sass/components/_blog.scss */
      section.component.post-roll article.post .editor_content h3 {
        color: #CD007A;
        text-decoration: none;
        margin: 0 0 .4em 0; }
        @media (min-width: 992px) {
          /* line 38, assets/sass/components/_blog.scss */
          section.component.post-roll article.post .editor_content h3 {
            display: inline-block;
            min-height: 72px;
            width: 100%; } }
      /* line 51, assets/sass/components/_blog.scss */
      section.component.post-roll article.post .editor_content time.post-meta {
        color: #9D9FA2;
        border-left: 3px solid #FFE600;
        padding-left: 5px;
        display: inline-block;
        width: 100%;
        margin: 0 0 .3em 0; }
      /* line 60, assets/sass/components/_blog.scss */
      section.component.post-roll article.post .editor_content p {
        color: #231F20; }
        /* line 62, assets/sass/components/_blog.scss */
        section.component.post-roll article.post .editor_content p i {
          color: #CD007A;
          font-size: 16px;
          margin: 0 0 0 5px; }
  /* line 72, assets/sass/components/_blog.scss */
  section.component.post-roll .pagination {
    text-align: center; }
    /* line 75, assets/sass/components/_blog.scss */
    section.component.post-roll .pagination a, section.component.post-roll .pagination span {
      display: inline-block;
      position: relative;
      font-size: 18px;
      font-family: "TideSans-600Bunny", sans-serif;
      padding: 5px;
      line-height: 1; }
    /* line 84, assets/sass/components/_blog.scss */
    section.component.post-roll .pagination span {
      color: #231F20; }
      /* line 86, assets/sass/components/_blog.scss */
      section.component.post-roll .pagination span.dots {
        color: #CD007A; }
      /* line 91, assets/sass/components/_blog.scss */
      section.component.post-roll .pagination span.current:after {
        width: 13px;
        height: 13px;
        content: '';
        display: block;
        position: absolute;
        background-color: #FFE600;
        left: 7px;
        top: 9px;
        z-index: -1; }
    /* line 106, assets/sass/components/_blog.scss */
    section.component.post-roll .pagination a {
      color: #CD007A;
      text-decoration: none; }
      /* line 110, assets/sass/components/_blog.scss */
      section.component.post-roll .pagination a.btn {
        padding: 0;
        cursor: pointer; }
        /* line 113, assets/sass/components/_blog.scss */
        section.component.post-roll .pagination a.btn span {
          padding: 18px 38px 16px; }

/* line 125, assets/sass/components/_blog.scss */
article.single-news section.component.hero,
article.academy-single section.component.hero {
  padding: 80px 0 160px; }

/* line 129, assets/sass/components/_blog.scss */
article.single-news .single-post-thumbnail,
article.academy-single .single-post-thumbnail {
  margin: -80px 0 50px 0; }
  @media (max-width: 767.98px) {
    /* line 129, assets/sass/components/_blog.scss */
    article.single-news .single-post-thumbnail,
    article.academy-single .single-post-thumbnail {
      margin: -80px 0 30px 0; } }
  /* line 135, assets/sass/components/_blog.scss */
  article.single-news .single-post-thumbnail img,
  article.academy-single .single-post-thumbnail img {
    max-width: 100%;
    height: auto; }

/* line 141, assets/sass/components/_blog.scss */
article.single-news .post-content,
article.academy-single .post-content {
  margin-bottom: 80px; }

/* line 2, assets/sass/components/_3d-model.scss */
section.threeD-model .threeD-object {
  width: 1000px;
  height: 800px; }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 2, assets/sass/components/_3d-model.scss */
    section.threeD-model .threeD-object {
      width: 640px;
      height: 512px; } }
  @media (max-width: 767.98px) {
    /* line 2, assets/sass/components/_3d-model.scss */
    section.threeD-model .threeD-object {
      width: 300;
      height: 240px; } }
  /* line 16, assets/sass/components/_3d-model.scss */
  section.threeD-model .threeD-object #canvas {
    width: 100%;
    height: 100%; }

/* line 24, assets/sass/components/_3d-model.scss */
.threeD-model-mobile .editor_content {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }
  @media screen and (max-width: 414px) {
    /* line 24, assets/sass/components/_3d-model.scss */
    .threeD-model-mobile .editor_content {
      height: 520px; } }
  @media screen and (max-width: 375px) {
    /* line 24, assets/sass/components/_3d-model.scss */
    .threeD-model-mobile .editor_content {
      height: 470px; } }
  @media screen and (max-width: 360px) {
    /* line 24, assets/sass/components/_3d-model.scss */
    .threeD-model-mobile .editor_content {
      height: 450px; } }
  @media screen and (max-width: 320px) {
    /* line 24, assets/sass/components/_3d-model.scss */
    .threeD-model-mobile .editor_content {
      height: 400px; } }
  /* line 45, assets/sass/components/_3d-model.scss */
  .threeD-model-mobile .editor_content iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

/**
 * Owl Carousel v2.3.2
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
/* line 9, assets/sass/components/plugins/_owl.scss */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

/* line 16, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

/* line 22, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

/* line 29, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

/* line 34, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

/* line 42, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

/* line 49, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: auto; }

/* line 54, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

/* line 57, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 67, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

/* line 75, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-loaded {
  display: block; }

/* line 77, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

/* line 80, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-hidden {
  opacity: 0; }

/* line 82, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

/* line 84, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 91, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

/* line 94, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-rtl {
  direction: rtl; }

/* line 96, assets/sass/components/plugins/_owl.scss */
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
/* line 100, assets/sass/components/plugins/_owl.scss */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
/* line 106, assets/sass/components/plugins/_owl.scss */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

/* line 110, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-animated-in {
  z-index: 0; }

/* line 113, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-animated-out {
  z-index: 1; }

/* line 116, assets/sass/components/plugins/_owl.scss */
.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
/* line 128, assets/sass/components/plugins/_owl.scss */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
/* line 134, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

/* line 138, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
/* line 144, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

/* line 149, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

/* line 163, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

/* line 167, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

/* line 171, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

/* line 179, assets/sass/components/plugins/_owl.scss */
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/* line 2, assets/sass/components/plugins/_fancybox.scss */
body.fancybox-active {
  overflow: hidden; }

/* line 5, assets/sass/components/plugins/_fancybox.scss */
body.fancybox-iosfix {
  position: fixed;
  left: 0;
  right: 0; }

/* line 10, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden; }

/* line 16, assets/sass/components/plugins/_fancybox.scss */
.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99992;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

/* line 30, assets/sass/components/plugins/_fancybox.scss */
.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* line 40, assets/sass/components/plugins/_fancybox.scss */
.fancybox-outer {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* line 44, assets/sass/components/plugins/_fancybox.scss */
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

/* line 51, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

/* line 55, assets/sass/components/plugins/_fancybox.scss */
.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption-wrap {
  position: absolute;
  direction: ltr;
  z-index: 99997;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility 0s linear .25s;
  box-sizing: border-box; }

/* line 66, assets/sass/components/plugins/_fancybox.scss */
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility 0s; }

/* line 73, assets/sass/components/plugins/_fancybox.scss */
.fancybox-infobar {
  top: 0;
  left: 0;
  font-size: 13px;
  padding: 0 10px;
  height: 44px;
  min-width: 44px;
  line-height: 44px;
  color: #ccc;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
  mix-blend-mode: exclusion; }

/* line 93, assets/sass/components/plugins/_fancybox.scss */
.fancybox-toolbar {
  top: 0;
  right: 0;
  margin: 0;
  padding: 0; }

/* line 99, assets/sass/components/plugins/_fancybox.scss */
.fancybox-stage {
  overflow: hidden;
  direction: ltr;
  z-index: 99994;
  -webkit-transform: translate3d(0, 0, 0); }

/* line 105, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-closing .fancybox-stage {
  overflow: visible; }

/* line 108, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform; }

/* line 130, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0; }

/* line 137, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

/* line 143, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--image {
  overflow: visible; }

/* line 146, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--image::before {
  display: none; }

/* line 149, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--video .fancybox-content,
.fancybox-slide--video iframe {
  background: #000; }

/* line 153, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--map .fancybox-content,
.fancybox-slide--map iframe {
  background: #E5E3DF; }

/* line 157, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--next {
  z-index: 99995; }

/* line 160, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box; }

/* line 172, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide > title,
.fancybox-slide > style,
.fancybox-slide > meta,
.fancybox-slide > link,
.fancybox-slide > script,
.fancybox-slide > base {
  display: none; }

/* line 180, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide .fancybox-image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform; }

/* line 206, assets/sass/components/plugins/_fancybox.scss */
.fancybox-can-zoomOut .fancybox-image-wrap {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

/* line 210, assets/sass/components/plugins/_fancybox.scss */
.fancybox-can-zoomIn .fancybox-image-wrap {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

/* line 214, assets/sass/components/plugins/_fancybox.scss */
.fancybox-can-drag .fancybox-image-wrap {
  cursor: -webkit-grab;
  cursor: grab; }

/* line 218, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-dragging .fancybox-image-wrap {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* line 222, assets/sass/components/plugins/_fancybox.scss */
.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 239, assets/sass/components/plugins/_fancybox.scss */
.fancybox-spaceball {
  z-index: 1; }

/* line 242, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--iframe .fancybox-content {
  padding: 0;
  width: 80%;
  height: 80%;
  max-width: calc(100% - 100px);
  max-height: calc(100% - 88px);
  overflow: visible;
  background: #fff; }

/* line 251, assets/sass/components/plugins/_fancybox.scss */
.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff; }

/* line 260, assets/sass/components/plugins/_fancybox.scss */
.fancybox-error {
  margin: 0;
  padding: 40px;
  width: 100%;
  max-width: 380px;
  background: #fff;
  cursor: default; }

/* line 268, assets/sass/components/plugins/_fancybox.scss */
.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 16px;
  line-height: 20px; }

/* Buttons */
/* line 276, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 10px;
  border: 0;
  border-radius: 0;
  background: rgba(30, 30, 30, 0.6);
  transition: color .3s ease;
  cursor: pointer;
  outline: none; }

/* line 291, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

/* line 296, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff; }

/* line 300, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button[disabled] {
  color: #ccc;
  cursor: default;
  opacity: 0.6; }

/* line 305, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button svg {
  display: block;
  position: relative;
  overflow: visible;
  shape-rendering: geometricPrecision; }

/* line 311, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button svg path {
  fill: currentColor;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

/* line 317, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button--share svg path {
  stroke-width: 1; }

/* line 320, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button--play svg path:nth-child(2) {
  display: none; }

/* line 323, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

/* line 326, assets/sass/components/plugins/_fancybox.scss */
.fancybox-button--zoom svg path {
  fill: transparent; }

/* Navigation arrows */
/* line 330, assets/sass/components/plugins/_fancybox.scss */
.fancybox-navigation {
  display: none; }

/* line 333, assets/sass/components/plugins/_fancybox.scss */
.fancybox-show-nav .fancybox-navigation {
  display: block; }

/* line 336, assets/sass/components/plugins/_fancybox.scss */
.fancybox-navigation button {
  position: absolute;
  top: 50%;
  margin: -50px 0 0 0;
  z-index: 99997;
  background: transparent;
  width: 60px;
  height: 100px;
  padding: 17px; }

/* line 346, assets/sass/components/plugins/_fancybox.scss */
.fancybox-navigation button:before {
  content: "";
  position: absolute;
  top: 30px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: rgba(30, 30, 30, 0.6); }

/* line 355, assets/sass/components/plugins/_fancybox.scss */
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0; }

/* line 358, assets/sass/components/plugins/_fancybox.scss */
.fancybox-navigation .fancybox-button--arrow_right {
  right: 0; }

/* Close button on the top right corner of html content */
/* line 362, assets/sass/components/plugins/_fancybox.scss */
.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  z-index: 10;
  cursor: pointer; }

/* line 376, assets/sass/components/plugins/_fancybox.scss */
.fancybox-close-small:after {
  content: '×';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 22px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  transition: background-color .25s;
  box-sizing: border-box;
  z-index: 2; }

/* line 394, assets/sass/components/plugins/_fancybox.scss */
.fancybox-close-small:focus {
  outline: none; }

/* line 397, assets/sass/components/plugins/_fancybox.scss */
.fancybox-close-small:focus:after {
  outline: 1px dotted #888; }

/* line 400, assets/sass/components/plugins/_fancybox.scss */
.fancybox-close-small:hover:after {
  color: #555;
  background: #eee; }

/* line 404, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  top: 0;
  right: -40px; }

/* line 409, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--image .fancybox-close-small:after,
.fancybox-slide--iframe .fancybox-close-small:after {
  font-size: 35px;
  color: #aaa; }

/* line 414, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide--image .fancybox-close-small:hover:after,
.fancybox-slide--iframe .fancybox-close-small:hover:after {
  color: #fff;
  background: transparent; }

/* line 419, assets/sass/components/plugins/_fancybox.scss */
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

/* Caption */
/* line 424, assets/sass/components/plugins/_fancybox.scss */
.fancybox-caption-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 2vw 0 2vw;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  pointer-events: none; }

/* line 432, assets/sass/components/plugins/_fancybox.scss */
.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none; }

/* line 440, assets/sass/components/plugins/_fancybox.scss */
.fancybox-caption a,
.fancybox-caption button,
.fancybox-caption select {
  pointer-events: all;
  position: relative;
  /* Fix IE11 */ }

/* line 447, assets/sass/components/plugins/_fancybox.scss */
.fancybox-caption a {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
/* line 452, assets/sass/components/plugins/_fancybox.scss */
.fancybox-slide > .fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
/* line 485, assets/sass/components/plugins/_fancybox.scss */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
/* line 489, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-slide.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0; }

/* line 494, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-slide.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0; }

/* line 499, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-slide.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: fade */
/* line 505, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

/* line 510, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
/* line 514, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  opacity: 0; }

/* line 519, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0; }

/* line 524, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

/* transitionEffect: rotate */
/* line 530, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-rotate.fancybox-slide--previous {
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
  opacity: 0; }

/* line 536, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-rotate.fancybox-slide--next {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0; }

/* line 542, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-rotate.fancybox-slide--current {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

/* transitionEffect: circular */
/* line 549, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-circular.fancybox-slide--previous {
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  opacity: 0; }

/* line 554, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-circular.fancybox-slide--next {
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  opacity: 0; }

/* line 559, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-circular.fancybox-slide--current {
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

/* transitionEffect: tube */
/* line 565, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

/* line 569, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

/* line 573, assets/sass/components/plugins/_fancybox.scss */
.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Share */
/* line 578, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share {
  padding: 30px;
  border-radius: 3px;
  background: #f4f4f4;
  max-width: 90%;
  text-align: center; }

/* line 585, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px 0;
  font-size: 35px;
  font-weight: 700; }

/* line 591, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share p {
  margin: 0;
  padding: 0; }

/* line 595, assets/sass/components/plugins/_fancybox.scss */
p.fancybox-share__links {
  margin-right: -10px; }

/* line 598, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button {
  display: inline-block;
  text-decoration: none;
  margin: 0 10px 10px 0;
  padding: 0 15px;
  min-width: 130px;
  border: 0;
  border-radius: 3px;
  background: #fff;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  transition: all .2s; }

/* line 618, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button:hover {
  text-decoration: none; }

/* line 621, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--fb {
  background: #3b5998; }

/* line 624, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--fb:hover {
  background: #344e86; }

/* line 627, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--pt {
  background: #bd081d; }

/* line 630, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--pt:hover {
  background: #aa0719; }

/* line 633, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--tw {
  background: #1da1f2; }

/* line 636, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button--tw:hover {
  background: #0d95e8; }

/* line 639, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button svg {
  position: relative;
  top: -1px;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  vertical-align: middle; }

/* line 647, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__button svg path {
  fill: #fff; }

/* line 650, assets/sass/components/plugins/_fancybox.scss */
.fancybox-share__input {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 10px 15px;
  background: transparent;
  color: #5d5b5b;
  font-size: 14px;
  outline: none;
  border: 0;
  border-bottom: 2px solid #d7d7d7; }

/* Thumbs */
/* line 663, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 212px;
  margin: 0;
  padding: 2px 2px 4px 2px;
  background: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  z-index: 99995; }

/* line 679, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs-x {
  overflow-y: hidden;
  overflow-x: auto; }

/* line 683, assets/sass/components/plugins/_fancybox.scss */
.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

/* line 686, assets/sass/components/plugins/_fancybox.scss */
.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

/* line 689, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
  white-space: nowrap; }

/* line 702, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs-x > ul {
  overflow: hidden; }

/* line 705, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

/* line 708, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

/* line 713, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

/* line 717, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 2px;
  width: 100px;
  height: 75px;
  max-width: calc(50% - 4px);
  max-height: calc(100% - 8px);
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box; }

/* line 734, assets/sass/components/plugins/_fancybox.scss */
li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

/* line 737, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 749, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs > ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

/* line 761, assets/sass/components/plugins/_fancybox.scss */
.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  /* line 766, assets/sass/components/plugins/_fancybox.scss */
  .fancybox-thumbs {
    width: 110px; }
  /* line 768, assets/sass/components/plugins/_fancybox.scss */
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  /* line 770, assets/sass/components/plugins/_fancybox.scss */
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

/* line 1, assets/sass/components/plugins/_select2.scss */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

/* line 7, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

/* line 14, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 21, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

/* line 23, assets/sass/components/plugins/_select2.scss */
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

/* line 26, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

/* line 33, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 39, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-search--inline {
  float: left; }

/* line 41, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

/* line 47, assets/sass/components/plugins/_select2.scss */
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 50, assets/sass/components/plugins/_select2.scss */
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

/* line 61, assets/sass/components/plugins/_select2.scss */
.select2-results {
  display: block; }

/* line 64, assets/sass/components/plugins/_select2.scss */
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 69, assets/sass/components/plugins/_select2.scss */
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

/* line 73, assets/sass/components/plugins/_select2.scss */
.select2-results__option[aria-selected] {
  cursor: pointer; }

/* line 76, assets/sass/components/plugins/_select2.scss */
.select2-container--open .select2-dropdown {
  left: 0; }

/* line 79, assets/sass/components/plugins/_select2.scss */
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 84, assets/sass/components/plugins/_select2.scss */
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 89, assets/sass/components/plugins/_select2.scss */
.select2-search--dropdown {
  display: block;
  padding: 4px; }

/* line 92, assets/sass/components/plugins/_select2.scss */
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

/* line 96, assets/sass/components/plugins/_select2.scss */
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 98, assets/sass/components/plugins/_select2.scss */
.select2-search--dropdown.select2-search--hide {
  display: none; }

/* line 101, assets/sass/components/plugins/_select2.scss */
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

/* line 118, assets/sass/components/plugins/_select2.scss */
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

/* line 130, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

/* line 134, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 137, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

/* line 141, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 143, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

/* line 149, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 161, assets/sass/components/plugins/_select2.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 164, assets/sass/components/plugins/_select2.scss */
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

/* line 168, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

/* line 171, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

/* line 174, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 178, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

/* line 183, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

/* line 189, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

/* line 191, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

/* line 195, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

/* line 201, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 210, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 216, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

/* line 219, assets/sass/components/plugins/_select2.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

/* line 222, assets/sass/components/plugins/_select2.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

/* line 226, assets/sass/components/plugins/_select2.scss */
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 230, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

/* line 234, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

/* line 238, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

/* line 241, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 245, assets/sass/components/plugins/_select2.scss */
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 249, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

/* line 252, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

/* line 259, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 263, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

/* line 266, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

/* line 269, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

/* line 272, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

/* line 274, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

/* line 276, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

/* line 279, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

/* line 282, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

/* line 285, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

/* line 288, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

/* line 292, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

/* line 296, assets/sass/components/plugins/_select2.scss */
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 301, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 311, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

/* line 313, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

/* line 316, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

/* line 321, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

/* line 323, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

/* line 339, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

/* line 351, assets/sass/components/plugins/_select2.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

/* line 354, assets/sass/components/plugins/_select2.scss */
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

/* line 363, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

/* line 365, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

/* line 368, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

/* line 372, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

/* line 382, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

/* line 392, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

/* line 398, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

/* line 400, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

/* line 404, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

/* line 406, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

/* line 415, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

/* line 421, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

/* line 424, assets/sass/components/plugins/_select2.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

/* line 429, assets/sass/components/plugins/_select2.scss */
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

/* line 433, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

/* line 436, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/* line 441, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* line 446, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

/* line 450, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

/* line 454, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

/* line 458, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

/* line 461, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-dropdown--below {
  border-top: none; }

/* line 464, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

/* line 468, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

/* line 471, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

/* line 474, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

/* line 478, assets/sass/components/plugins/_select2.scss */
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

/* line 483, assets/sass/components/plugins/_select2.scss */
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

/* line 1, assets/sass/components/page-components/_hero.scss */
section.component.hero {
  padding: 120px 0;
  position: relative;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cbcbcb+0,d8d8d8+50,cbcbcb+100 */
  background: #cbcbcb;
  background: -moz-linear-gradient(top, #cbcbcb 0%, #d8d8d8 50%, #cbcbcb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #cbcbcb 0%, #d8d8d8 50%, #cbcbcb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #cbcbcb 0%, #d8d8d8 50%, #cbcbcb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbcbcb', endColorstr='#cbcbcb',GradientType=0 );
  /* IE6-9 */ }
  @media (max-width: 991.98px) {
    /* line 1, assets/sass/components/page-components/_hero.scss */
    section.component.hero {
      padding: 60px 0 20px 0; } }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_hero.scss */
    section.component.hero {
      padding: 36px 0 20px 0; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_hero.scss */
    section.component.hero {
      padding: 80px 0; } }
  /* line 26, assets/sass/components/page-components/_hero.scss */
  section.component.hero.margin-under {
    margin-bottom: 100px; }
    @media (max-width: 767.98px) {
      /* line 26, assets/sass/components/page-components/_hero.scss */
      section.component.hero.margin-under {
        margin-bottom: 30px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 26, assets/sass/components/page-components/_hero.scss */
      section.component.hero.margin-under {
        margin-bottom: 60px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 26, assets/sass/components/page-components/_hero.scss */
      section.component.hero.margin-under {
        margin-bottom: 50px; } }
  /* line 39, assets/sass/components/page-components/_hero.scss */
  section.component.hero.more-padding {
    padding: 120px 0 170px; }
    @media (max-width: 767.98px) {
      /* line 39, assets/sass/components/page-components/_hero.scss */
      section.component.hero.more-padding {
        padding: 36px 0 90px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 39, assets/sass/components/page-components/_hero.scss */
      section.component.hero.more-padding {
        padding: 60px 0 80px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 39, assets/sass/components/page-components/_hero.scss */
      section.component.hero.more-padding {
        padding: 80px 0 100px; } }
  /* line 55, assets/sass/components/page-components/_hero.scss */
  section.component.hero.widget-margin {
    margin-bottom: 200px; }
    @media (max-width: 767.98px) {
      /* line 55, assets/sass/components/page-components/_hero.scss */
      section.component.hero.widget-margin {
        margin-bottom: 0; } }
  /* line 62, assets/sass/components/page-components/_hero.scss */
  section.component.hero.no-margin-under {
    margin-bottom: 0;
    padding: 160px 0; }
  /* line 67, assets/sass/components/page-components/_hero.scss */
  section.component.hero.slider-margin-padding, section.component.hero.widget-margin {
    padding: 120px 0 230px;
    margin-bottom: 240px; }
    @media (max-width: 767.98px) {
      /* line 67, assets/sass/components/page-components/_hero.scss */
      section.component.hero.slider-margin-padding, section.component.hero.widget-margin {
        margin-bottom: 0;
        padding: 36px 0 90px; } }
  /* line 79, assets/sass/components/page-components/_hero.scss */
  section.component.hero.widget-margin-smaller {
    padding: 120px 0 160px;
    margin-bottom: 220px; }
    @media (max-width: 767.98px) {
      /* line 79, assets/sass/components/page-components/_hero.scss */
      section.component.hero.widget-margin-smaller {
        margin-bottom: 0;
        padding: 36px 0 90px; } }
  /* line 90, assets/sass/components/page-components/_hero.scss */
  section.component.hero.top {
    z-index: 100; }
  /* line 95, assets/sass/components/page-components/_hero.scss */
  section.component.hero.animated .background-image {
    animation-iteration-count: infinite;
    animation-name: hue-loop;
    animation-duration: 16s;
    animation-timing-function: linear; }
  /* line 103, assets/sass/components/page-components/_hero.scss */
  section.component.hero .background-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right; }

@keyframes hue-loop {
  0% {
    filter: hue-rotate(0); }
  12% {
    filter: hue-rotate(50deg); }
  24% {
    filter: hue-rotate(0); }
  36% {
    filter: hue-rotate(-50deg); }
  48% {
    filter: hue-rotate(-100deg); }
  60% {
    filter: hue-rotate(-150deg); }
  72% {
    filter: hue-rotate(-100deg); }
  84% {
    filter: hue-rotate(-50deg); }
  96% {
    filter: hue-rotate(-10deg); }
  100% {
    filter: hue-rotate(0); } }
  /* line 129, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content.text-center {
    text-align: center; }
  /* line 134, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content.black .h1,
  section.component.hero .hero-content.black .subtitle {
    color: #231F20; }
  /* line 139, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content .h1 {
    color: #FFF;
    font-family: "TideSans-600Bunny", sans-serif;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    margin: 0;
    margin: 0 0 .4em 0; }
    @media (max-width: 991.98px) {
      /* line 139, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .h1 {
        font-size: 45px;
        line-height: 50px; } }
    @media (max-width: 767.98px) {
      /* line 139, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .h1 {
        font-size: 35px;
        line-height: 40px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 139, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .h1 {
        font-size: 45px;
        line-height: 50px; } }
    @media (max-width: 767.98px) {
      /* line 142, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .h1 br {
        display: none; } }
  /* line 148, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content .subtitle {
    font-family: "TideSans-600Bunny", sans-serif;
    font-size: 22px;
    color: #FFF;
    text-transform: uppercase; }
    /* line 154, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content .subtitle.lower-case {
      text-transform: none; }
    @media (max-width: 767.98px) {
      /* line 148, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .subtitle {
        font-size: 16px;
        line-height: 20px; } }
    /* line 162, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content .subtitle a {
      color: #FFE600;
      text-decoration: none; }
  /* line 168, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content time.product-cats {
    display: block;
    width: 100%;
    transform: translateY(-10px);
    margin-bottom: 16px; }
    @media (max-width: 767.98px) {
      /* line 168, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content time.product-cats {
        position: relative;
        padding: 8px 0 0 25px;
        margin-bottom: 10px; } }
    /* line 180, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content time.product-cats.yellow-border {
      border-left: 3px solid #FFE600;
      padding-left: 15px; }
      @media (max-width: 767.98px) {
        /* line 180, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content time.product-cats.yellow-border {
          padding: 0 0 0 15px; } }
      /* line 188, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content time.product-cats.yellow-border a:hover {
        color: #FFE600; }
    /* line 193, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content time.product-cats i {
      color: #9D9FA2;
      font-size: 16px;
      margin-right: 5px; }
      @media (max-width: 767.98px) {
        /* line 193, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content time.product-cats i {
          position: absolute;
          left: 0;
          top: 12px; } }
    /* line 204, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content time.product-cats a {
      color: #9D9FA2;
      font-size: 16px;
      text-transform: uppercase;
      font-family: "TideSans-600Bunny", sans-serif;
      text-decoration: none;
      transition: color .2s; }
      @media (max-width: 767.98px) {
        /* line 204, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content time.product-cats a {
          display: block; }
          /* line 214, assets/sass/components/page-components/_hero.scss */
          section.component.hero .hero-content time.product-cats a span {
            display: none; } }
      /* line 219, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content time.product-cats a:hover {
        color: #CD007A; }
        /* line 221, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content time.product-cats a:hover span {
          color: #9D9FA2; }
      /* line 226, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content time.product-cats a span {
        margin: 0 5px;
        color: #9D9FA2 !important; }
      /* line 232, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content time.product-cats a:last-child span {
        display: none; }
  /* line 239, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content .academy-category-trigger {
    background-color: rgba(0, 0, 0, 0.5);
    font-family: TideSans-600Bunny,sans-serif;
    font-size: 12px;
    color: #ffe600;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    margin: 30px 0 20px;
    -webkit-transition: color .2s,background-color .2s;
    transition: color .2s,background-color .2s;
    max-width: 350px;
    position: relative;
    cursor: pointer; }
    @media (max-width: 767.98px) {
      /* line 239, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .academy-category-trigger {
        margin-bottom: 10px; } }
    @media (max-width: 575.98px) {
      /* line 239, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content .academy-category-trigger {
        margin-bottom: 30px; } }
    /* line 261, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content .academy-category-trigger span {
      width: 34px;
      height: 34px;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      -webkit-transition: -webkit-transform .3s;
      transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s,-webkit-transform .3s; }
  /* line 274, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content .academy-dropdown-container {
    position: relative; }
    /* line 278, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content .academy-dropdown-container.list-visible .academy-category-trigger span i {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg); }
  /* line 286, assets/sass/components/page-components/_hero.scss */
  section.component.hero .hero-content ul.product-cats {
    margin: 30px 0 70px;
    padding: 0;
    width: 80%;
    list-style: none; }
    /* line 292, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content ul.product-cats.academy {
      width: 100%; }
      @media (max-width: 991.98px) {
        /* line 292, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content ul.product-cats.academy {
          max-width: 350px;
          margin: 0;
          display: none;
          position: absolute;
          top: 34px;
          transform: translateX(0); }
          /* line 303, assets/sass/components/page-components/_hero.scss */
          section.component.hero .hero-content ul.product-cats.academy li {
            display: block;
            margin: 0; }
            /* line 307, assets/sass/components/page-components/_hero.scss */
            section.component.hero .hero-content ul.product-cats.academy li a {
              width: 100%;
              background-color: #FFF;
              color: #CD007A; }
            /* line 314, assets/sass/components/page-components/_hero.scss */
            section.component.hero .hero-content ul.product-cats.academy li:nth-child(odd) a {
              background-color: #EEEDED; }
              /* line 317, assets/sass/components/page-components/_hero.scss */
              section.component.hero .hero-content ul.product-cats.academy li:nth-child(odd) a:hover, section.component.hero .hero-content ul.product-cats.academy li:nth-child(odd) a.active {
                background-color: #FFE600;
                color: #231F20; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 286, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content ul.product-cats {
        margin: 30px 0 30px 0; } }
    /* line 335, assets/sass/components/page-components/_hero.scss */
    section.component.hero .hero-content ul.product-cats li {
      display: inline-block;
      margin: 0 8px 8px 0; }
      /* line 339, assets/sass/components/page-components/_hero.scss */
      section.component.hero .hero-content ul.product-cats li a {
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.5);
        font-family: "TideSans-600Bunny", sans-serif;
        font-size: 12px;
        color: #FFE600;
        text-decoration: none;
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        transition: color .2s, background-color .2s; }
        /* line 351, assets/sass/components/page-components/_hero.scss */
        section.component.hero .hero-content ul.product-cats li a:hover, section.component.hero .hero-content ul.product-cats li a.active {
          background-color: #FFE600;
          color: #231F20; }
  /* line 360, assets/sass/components/page-components/_hero.scss */
  section.component.hero time.post-meta {
    display: inline-block;
    border-left: 3px solid #FFE600;
    font-family: "TideSans-600Bunny", sans-serif;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    line-height: 1;
    padding-left: 4px; }
    /* line 370, assets/sass/components/page-components/_hero.scss */
    section.component.hero time.post-meta a {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.3);
      transition: color .2s; }
      /* line 374, assets/sass/components/page-components/_hero.scss */
      section.component.hero time.post-meta a:hover {
        color: #FFE600; }
  /* line 381, assets/sass/components/page-components/_hero.scss */
  section.component.hero .container-fluid {
    position: relative; }
  /* line 386, assets/sass/components/page-components/_hero.scss */
  section.component.hero a.ak-puff:after, section.component.hero a.ak-puff:before {
    transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    pointer-events: none; }
  /* line 392, assets/sass/components/page-components/_hero.scss */
  section.component.hero a.ak-puff .puff-content {
    transition: transform 0.4s cubic-bezier(1, 0, 0, 1); }
  /* line 397, assets/sass/components/page-components/_hero.scss */
  section.component.hero a.ak-puff:hover:before {
    transform: translate(-28px, -28px); }
  /* line 401, assets/sass/components/page-components/_hero.scss */
  section.component.hero a.ak-puff:hover:after {
    transform: translate(-58px, -58px); }
  /* line 407, assets/sass/components/page-components/_hero.scss */
  section.component.hero .ak-puff {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(75px, -70%);
    margin: 0;
    padding: 0; }
    @media (max-width: 991.98px) {
      /* line 407, assets/sass/components/page-components/_hero.scss */
      section.component.hero .ak-puff {
        display: none; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 407, assets/sass/components/page-components/_hero.scss */
      section.component.hero .ak-puff {
        top: 50%;
        transform: translate(75px, 0); } }
    @media screen and (max-width: 1320px) {
      /* line 407, assets/sass/components/page-components/_hero.scss */
      section.component.hero .ak-puff {
        transform: translate(-15px, -60%); } }
    /* line 429, assets/sass/components/page-components/_hero.scss */
    section.component.hero .ak-puff:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: calc(100% + 28px + 58px);
      background-color: #FFE600;
      left: 0;
      top: 0;
      padding: 28px 0 58px 0;
      transform: translate(-28px, -28px); }
    /* line 442, assets/sass/components/page-components/_hero.scss */
    section.component.hero .ak-puff:before {
      content: '';
      z-index: 2;
      position: absolute;
      width: 100%;
      height: calc(100% + 28px + 58px);
      border: 1px solid #FFF;
      left: 0;
      top: 0;
      padding: 28px 0 58px 0;
      transform: translate(-58px, -58px); }
    /* line 456, assets/sass/components/page-components/_hero.scss */
    section.component.hero .ak-puff .puff-content {
      position: relative;
      z-index: 3;
      display: inline-block;
      background-color: #FFF;
      padding: 15px 20px;
      color: #231F20;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      backface-visibility: hidden;
      *zoom: 1;
      max-width: 280px; }
      /* line 473, assets/sass/components/page-components/_hero.scss */
      section.component.hero .ak-puff .puff-content i {
        margin-left: 10px;
        color: #CD007A; }
      /* line 478, assets/sass/components/page-components/_hero.scss */
      section.component.hero .ak-puff .puff-content.max-width {
        max-width: 245px;
        min-width: 240px;
        width: 100%;
        padding: 30px; }
        /* line 483, assets/sass/components/page-components/_hero.scss */
        section.component.hero .ak-puff .puff-content.max-width img {
          max-width: 100%;
          max-height: 100px;
          width: auto;
          height: auto;
          display: block;
          margin: 0 auto; }
  /* line 496, assets/sass/components/page-components/_hero.scss */
  section.component.hero.black-friday {
    padding: 180px 0 160px; }
    /* line 498, assets/sass/components/page-components/_hero.scss */
    section.component.hero.black-friday .background-image {
      height: 900px;
      background-position: center center; }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 498, assets/sass/components/page-components/_hero.scss */
        section.component.hero.black-friday .background-image {
          height: 830px; } }
    @media (max-width: 767.98px) {
      /* line 496, assets/sass/components/page-components/_hero.scss */
      section.component.hero.black-friday {
        padding: 120px 0 60px; } }
    /* line 512, assets/sass/components/page-components/_hero.scss */
    section.component.hero.black-friday .hero-content .h1 {
      font-size: 90px;
      line-height: 90px;
      text-shadow: 2px 1px #9D9FA2, 11px 3px 16px #231F20; }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 512, assets/sass/components/page-components/_hero.scss */
        section.component.hero.black-friday .hero-content .h1 {
          font-size: 75px; } }
      @media (max-width: 767.98px) {
        /* line 512, assets/sass/components/page-components/_hero.scss */
        section.component.hero.black-friday .hero-content .h1 {
          font-size: 37px;
          line-height: 45px; } }
    /* line 526, assets/sass/components/page-components/_hero.scss */
    section.component.hero.black-friday .hero-content ul.product-cats li a {
      background-color: #FFE600;
      color: #231F20; }
      /* line 529, assets/sass/components/page-components/_hero.scss */
      section.component.hero.black-friday .hero-content ul.product-cats li a:hover {
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFE600; }

/* line 538, assets/sass/components/page-components/_hero.scss */
section.component.hero-widgets {
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%;
  transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    /* line 538, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets {
      position: static;
      transform: translateY(0);
      margin-top: -40px; } }
  @media (max-width: 991.98px) {
    /* line 553, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .row .col-12:nth-child(4), section.component.hero-widgets .row .col-12:nth-child(3) {
      display: none; } }
  @media (max-width: 991.98px) {
    /* line 559, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .row .col-12:nth-child(4) {
      display: none; } }
  /* line 566, assets/sass/components/page-components/_hero.scss */
  section.component.hero-widgets .hero-widget {
    position: relative;
    overflow: hidden; }
    @media (max-width: 767.98px) {
      /* line 566, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget {
        margin-bottom: 15px; } }
    /* line 575, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .hero-widget:hover .hero-widget-content {
      top: 0;
      transform: translateX(0); }
      /* line 578, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget:hover .hero-widget-content h4 {
        transform: translate(0, 0); }
        /* line 580, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget:hover .hero-widget-content h4 span {
          opacity: 0;
          width: 0;
          margin-right: 14px; }
    /* line 589, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .hero-widget .hero-widget-content {
      background-color: rgba(255, 230, 0, 0.85);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1), top 0.4s cubic-bezier(1, 0, 0, 1); }
      /* line 599, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget .hero-widget-content .widgetLink {
        display: block;
        height: 100%; }
      /* line 604, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget .hero-widget-content .widgetLink + h4, section.component.hero-widgets .hero-widget .hero-widget-content .widgetLink + h4 + p {
        pointer-events: none; }
    /* line 609, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .hero-widget h4 {
      margin: 0;
      padding: 14px 14px 14px 0;
      line-height: 1;
      display: table;
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #FFF;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      z-index: 1;
      transform: translate(100%, -100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1); }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        /* line 609, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget h4 {
          font-size: 14px; } }
      /* line 631, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget h4 span {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        margin-right: 8px;
        overflow: hidden;
        transition: width 0.4s cubic-bezier(1, 0, 0, 1), margin-right 0.4s cubic-bezier(1, 0, 0, 1), opacity 0.1s ease; }
        /* line 639, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget h4 span:before, section.component.hero-widgets .hero-widget h4 span:after {
          content: '';
          width: 14px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin: -1px 0 0 0;
          background-color: #CD007A;
          border-radius: 1px; }
        /* line 653, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget h4 span:after {
          transform: rotate(90deg); }
    /* line 659, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .hero-widget img {
      width: 100%;
      height: auto; }
    /* line 664, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets .hero-widget p {
      position: absolute;
      left: 0;
      top: 50%;
      margin: 0;
      padding: 0 25px;
      width: 100%;
      line-height: 22px;
      font-size: 14px;
      transform: translateY(-40%); }
      /* line 675, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets .hero-widget p a {
        width: auto;
        display: table;
        font-family: "TideSans-600Bunny", sans-serif;
        color: #CD007A;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 10px;
        position: relative;
        transition: color .2s ease;
        z-index: 1; }
        /* line 687, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget p a:hover {
          color: #231F20; }
          /* line 689, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets .hero-widget p a:hover:after {
            width: 100%; }
        /* line 694, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget p a:after {
          content: '';
          width: 0;
          height: 10px;
          background: #FFF;
          position: absolute;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1);
          right: 0;
          bottom: 0;
          z-index: -1;
          transform: translate(5px, -1px);
          backface-visibility: hidden;
          *zoom: 1; }
        /* line 709, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets .hero-widget p a i {
          margin-left: 4px; }
  /* line 718, assets/sass/components/page-components/_hero.scss */
  section.component.hero-widgets .ak-slider .hero-widget {
    width: 100%; }

/* Hero on Contact page */
/* line 727, assets/sass/components/page-components/_hero.scss */
section.component.hero-widgets-contact {
  position: absolute;
  top: 100%;
  z-index: 10000;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    /* line 727, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets-contact {
      position: static;
      transform: translateY(0);
      margin-top: -40px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 742, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets-contact .col-md-4 {
      padding-left: 10px;
      padding-right: 10px; } }
  /* line 748, assets/sass/components/page-components/_hero.scss */
  section.component.hero-widgets-contact .hero-widget {
    background: #FFF;
    padding: 25px 25px 25px 50px; }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 748, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget {
        padding: 20px 15px 15px 40px; } }
    /* line 756, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets-contact .hero-widget a {
      width: auto;
      display: table;
      font-family: "TideSans-600Bunny", sans-serif;
      color: #CD007A;
      text-decoration: none;
      position: relative;
      transition: color .2s ease;
      z-index: 1; }
      /* line 766, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget a:hover {
        color: #231F20; }
        /* line 768, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget a:hover:after {
          width: 100%; }
      /* line 773, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget a:after {
        content: '';
        width: 0;
        height: 10px;
        background: #FFE600;
        position: absolute;
        transition: width 0.2s cubic-bezier(1, 0, 0, 1);
        right: 0;
        bottom: 0;
        z-index: -1;
        transform: translate(5px, -1px);
        backface-visibility: hidden;
        *zoom: 1; }
      /* line 788, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget a i {
        margin-left: 4px; }
      /* line 792, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget a.large-link {
        font-size: 20px;
        font-family: "TideSans-300LilKahuna", sans-serif; }
    /* line 798, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets-contact .hero-widget .map-dropdown-container {
      position: relative;
      transition: background-color .3s;
      z-index: 9999; }
      /* line 803, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget .map-dropdown-container.list-visible {
        background-color: #FFE600; }
        /* line 805, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container.list-visible p.printshop-list-trigger span {
          transform: rotate(-180deg); }
      /* line 810, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav {
        display: block;
        cursor: pointer;
        width: auto;
        display: table;
        width: auto;
        display: table;
        font-family: "TideSans-600Bunny", sans-serif;
        color: #CD007A;
        text-decoration: none;
        margin-top: 10px;
        position: relative;
        transition: color .2s ease;
        z-index: 1;
        font-size: 20px;
        font-family: "TideSans-300LilKahuna", sans-serif; }
        /* line 827, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger:hover, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav:hover {
          color: #231F20; }
          /* line 829, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger:hover:after, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav:hover:after {
            width: 100%; }
        /* line 834, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger:after, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav:after {
          content: '';
          width: 0;
          height: 10px;
          background: #FFE600;
          position: absolute;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1);
          right: 0;
          bottom: 0;
          z-index: -1;
          transform: translate(5px, -1px);
          backface-visibility: hidden;
          *zoom: 1; }
        /* line 849, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger i, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav i {
          margin-left: 4px; }
        /* line 853, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger span, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav span {
          width: 24px;
          height: 24px;
          position: absolute;
          right: -30px;
          top: 0;
          text-align: center;
          transition: transform .3s; }
          /* line 862, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger span i, section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav span i {
            font-size: 20px; }
      /* line 868, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav {
        display: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background-color: inherit; }
        /* line 876, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav::after {
          content: "\25BC";
          position: absolute;
          height: .8em;
          font-size: .6em;
          line-height: 1;
          right: .9em;
          top: 50%;
          margin-top: -.5em;
          color: #231F20; }
        /* line 888, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav + .fa {
          display: none;
          position: absolute;
          top: 10px;
          right: 16px;
          font-size: 20px;
          color: #CD007A; }
          @media only screen and (max-device-width: 1024px) {
            /* line 888, assets/sass/components/page-components/_hero.scss */
            section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav + .fa {
              top: 0; } }
      @media only screen and (min-device-width: 300px) and (max-device-width: 768px) {
        /* line 903, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger {
          display: none; }
        /* line 907, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav {
          display: block;
          width: 100%; }
          /* line 910, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav + .fa {
            display: block; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        /* line 917, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger {
          display: none; }
        /* line 921, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav {
          display: block;
          font-size: 18px; }
          /* line 924, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav + .fa {
            display: block;
            font-size: 18px; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) {
        /* line 932, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container p.printshop-list-trigger {
          display: none; }
        /* line 936, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav {
          display: block;
          width: 100%; }
          /* line 939, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container #sectional_nav + .fa {
            display: block;
            top: 0; } }
      /* line 946, assets/sass/components/page-components/_hero.scss */
      section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list {
        position: absolute;
        width: 100%;
        top: 100%;
        margin: 10px 0 0 0;
        padding: 0;
        background-color: #FFF;
        list-style: none;
        border: 1px solid #231F20;
        max-height: 400px;
        overflow-y: scroll;
        display: none; }
        @media (max-width: 767.98px) {
          /* line 946, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list {
            max-height: 300px; } }
        /* line 964, assets/sass/components/page-components/_hero.scss */
        section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li {
          width: 100%;
          display: block;
          height: 34px;
          line-height: 34px; }
          /* line 970, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li:first-of-type {
            margin-top: 10px; }
          /* line 971, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li:last-of-type {
            margin-bottom: 10px; }
          /* line 974, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li:nth-child(odd) a {
            background-color: #EEEDED; }
          /* line 979, assets/sass/components/page-components/_hero.scss */
          section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li a {
            display: block;
            width: 100%;
            height: 100%;
            color: #CD007A;
            padding-left: 15px;
            text-decoration: none;
            transition: background-color .2s ease; }
            /* line 987, assets/sass/components/page-components/_hero.scss */
            section.component.hero-widgets-contact .hero-widget .map-dropdown-container ul.printshop-list li a:hover {
              background-color: #FFE600;
              color: #231F20; }
  @media (max-width: 767.98px) {
    /* line 727, assets/sass/components/page-components/_hero.scss */
    section.component.hero-widgets-contact {
      transform: translateY(0); } }

/* line 1, assets/sass/components/page-components/_text-image.scss */
section.component.text-image {
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image {
      margin-bottom: 20px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image {
      margin-bottom: 50px; } }
  @media (max-width: 767.98px) {
    /* line 15, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image .text-image-wrapper {
      display: flex;
      flex-wrap: wrap; } }
  /* line 20, assets/sass/components/page-components/_text-image.scss */
  section.component.text-image .text-image-wrapper .editor_content {
    background-color: #FFF;
    position: absolute;
    width: 100%;
    max-width: 380px;
    left: 0;
    top: 50%;
    transform: translate(-280px, -50%);
    padding: 30px 30px 30px 20px; }
    @media (max-width: 767.98px) {
      /* line 20, assets/sass/components/page-components/_text-image.scss */
      section.component.text-image .text-image-wrapper .editor_content {
        transform: translate(-15px, 0);
        padding: 20px 20px 20px 15px;
        position: static;
        order: 2;
        margin-top: -50px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 20, assets/sass/components/page-components/_text-image.scss */
      section.component.text-image .text-image-wrapper .editor_content {
        transform: translate(-240px, -50%); } }
    /* line 42, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image .text-image-wrapper .editor_content .h2 {
      margin-bottom: .4em;
      position: relative; }
      /* line 46, assets/sass/components/page-components/_text-image.scss */
      section.component.text-image .text-image-wrapper .editor_content .h2:before {
        width: 3px;
        height: 100%;
        content: '';
        display: inline-block;
        background-color: #FFE600;
        position: absolute;
        left: -23px;
        top: 0; }
        @media (max-width: 767.98px) {
          /* line 46, assets/sass/components/page-components/_text-image.scss */
          section.component.text-image .text-image-wrapper .editor_content .h2:before {
            left: -15px; } }
    /* line 64, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image .text-image-wrapper .editor_content a.arrow-link {
      font-size: 16px;
      line-height: 1;
      color: #CD007A;
      transition: color .2s;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      position: relative;
      text-decoration: none; }
      /* line 146, assets/sass/components/_buttons.scss */
      section.component.text-image .text-image-wrapper .editor_content a.arrow-link i {
        margin-left: 5px; }
      /* line 150, assets/sass/components/_buttons.scss */
      section.component.text-image .text-image-wrapper .editor_content a.arrow-link:after {
        content: '';
        width: 0;
        height: 10px;
        background-color: #FFE600;
        position: absolute;
        right: -6px;
        top: calc(100% - 9px);
        z-index: -1;
        transition: width 0.3s cubic-bezier(1, 0, 0, 1); }
      /* line 162, assets/sass/components/_buttons.scss */
      section.component.text-image .text-image-wrapper .editor_content a.arrow-link:hover {
        color: #231F20; }
        /* line 164, assets/sass/components/_buttons.scss */
        section.component.text-image .text-image-wrapper .editor_content a.arrow-link:hover:after {
          width: 100%; }
  /* line 70, assets/sass/components/page-components/_text-image.scss */
  section.component.text-image .text-image-wrapper img {
    max-width: 100%;
    height: auto;
    order: 1; }

/* line 81, assets/sass/components/page-components/_text-image.scss */
section.component.text-image-news .text-image-wrapper .editor_content {
  -webkit-transform: translate(400px, -50%);
  transform: translate(400px, -50%);
  padding: 30px 30px 30px 40px;
  max-width: 510px; }
  /* line 86, assets/sass/components/page-components/_text-image.scss */
  section.component.text-image-news .text-image-wrapper .editor_content ul {
    list-style: none;
    padding-inline-start: 0;
    overflow: scroll;
    max-height: 280px;
    margin-bottom: 0; }
    /* line 92, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image-news .text-image-wrapper .editor_content ul li {
      padding: 5px 0; }
      /* line 95, assets/sass/components/page-components/_text-image.scss */
      section.component.text-image-news .text-image-wrapper .editor_content ul li a.arrow-link.magenta {
        font-family: "TideSans-600Bunny", sans-serif;
        text-transform: inherit; }
      /* line 100, assets/sass/components/page-components/_text-image.scss */
      section.component.text-image-news .text-image-wrapper .editor_content ul li time {
        color: #9D9FA2;
        font-family: "TideSans-300LilKahuna", sans-serif;
        display: inline-block;
        width: 100%;
        margin: 0 0 .3em; }
  @media (max-width: 767.98px) {
    /* line 81, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image-news .text-image-wrapper .editor_content {
      transform: translate(-15px, 0);
      padding: 20px 20px 20px 15px;
      position: static;
      order: 2;
      margin-top: -50px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 81, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image-news .text-image-wrapper .editor_content {
      transform: translate(240px, -50%); } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 81, assets/sass/components/page-components/_text-image.scss */
    section.component.text-image-news .text-image-wrapper .editor_content {
      transform: translate(380px, -50%); } }

/* line 1, assets/sass/components/page-components/_column-block.scss */
section.component.column-block, section.component.image-slider {
  position: relative;
  margin-bottom: 100px; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block, section.component.image-slider {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block, section.component.image-slider {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block, section.component.image-slider {
      margin-bottom: 50px; } }
  /* line 17, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block .title-row, section.component.image-slider .title-row {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media (max-width: 767.98px) {
      /* line 17, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .title-row, section.component.image-slider .title-row {
        padding-top: 30px;
        padding-bottom: 10px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 17, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .title-row, section.component.image-slider .title-row {
        padding-top: 40px;
        padding-bottom: 20px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 17, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .title-row, section.component.image-slider .title-row {
        padding-top: 30px;
        padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    /* line 40, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper:nth-child(1), section.component.image-slider .column-wrapper:nth-child(1) {
      margin-bottom: 70px; }
    /* line 43, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper:nth-child(2), section.component.image-slider .column-wrapper:nth-child(2) {
      margin-bottom: 70px; }
    /* line 46, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper:nth-child(3), section.component.image-slider .column-wrapper:nth-child(3) {
      display: none; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 52, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper:nth-child(3), section.component.image-slider .column-wrapper:nth-child(3) {
      display: none; } }
  @media (max-width: 767.98px) {
    /* line 59, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper.column-wrapper-referencecase:nth-child(1), section.component.image-slider .column-wrapper.column-wrapper-referencecase:nth-child(1) {
      margin-bottom: 0; }
    /* line 62, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .column-wrapper.column-wrapper-referencecase:nth-child(3), section.component.image-slider .column-wrapper.column-wrapper-referencecase:nth-child(3) {
      display: none; } }
  /* line 69, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block a.column, section.component.image-slider a.column {
    position: relative;
    display: block;
    transition: transform .2s ease; }
    /* line 74, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block a.column img, section.component.image-slider a.column img {
      width: 100%;
      max-width: 90%;
      height: auto; }
    /* line 80, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block a.column .editor_content, section.component.image-slider a.column .editor_content {
      width: 100%;
      max-width: 90%;
      background-color: #FFF;
      position: absolute;
      left: 40px;
      top: 40px;
      padding: 25px; }
      @media (max-width: 767.98px) {
        /* line 80, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block a.column .editor_content, section.component.image-slider a.column .editor_content {
          left: auto;
          right: -15px;
          top: auto;
          bottom: -50px;
          max-width: 320px; } }
      /* line 97, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block a.column .editor_content .h2, section.component.image-slider a.column .editor_content .h2 {
        margin-bottom: .55em;
        color: #CD007A; }
        @media (max-width: 767.98px) {
          /* line 97, assets/sass/components/page-components/_column-block.scss */
          section.component.column-block a.column .editor_content .h2, section.component.image-slider a.column .editor_content .h2 {
            margin-bottom: .22em; } }
      /* line 105, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block a.column .editor_content p, section.component.image-slider a.column .editor_content p {
        color: #231F20; }
        @media (max-width: 767.98px) {
          /* line 105, assets/sass/components/page-components/_column-block.scss */
          section.component.column-block a.column .editor_content p, section.component.image-slider a.column .editor_content p {
            font-size: 14px; } }
        /* line 110, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block a.column .editor_content p i, section.component.image-slider a.column .editor_content p i {
          color: #CD007A;
          font-size: 20px;
          transform: translateY(1px); }
  /* line 122, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block .reference, section.component.image-slider .reference {
    position: relative;
    overflow: hidden;
    margin: 0 0 30px 0; }
    /* line 128, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference:hover .reference-content, section.component.image-slider .reference:hover .reference-content {
      top: 0;
      transform: translateX(0); }
      /* line 131, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .reference:hover .reference-content h4, section.component.image-slider .reference:hover .reference-content h4 {
        transform: translate(0, 0); }
        /* line 133, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference:hover .reference-content h4 span, section.component.image-slider .reference:hover .reference-content h4 span {
          opacity: 0;
          width: 0;
          margin-right: 14px; }
    /* line 142, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference .reference-content, section.component.image-slider .reference .reference-content {
      background-color: rgba(255, 230, 0, 0.85);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1), top 0.4s cubic-bezier(1, 0, 0, 1); }
    /* line 155, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference.small-reference .reference-content p, section.component.image-slider .reference.small-reference .reference-content p {
      font-size: 25px; }
    /* line 163, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference.big-reference .reference-content p, section.component.image-slider .reference.big-reference .reference-content p {
      font-size: 35px; }
    /* line 169, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference h4, section.component.image-slider .reference h4 {
      margin: 0;
      padding: 14px 14px 14px 0;
      line-height: 1;
      display: table;
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #FFF;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      z-index: 1;
      transform: translate(100%, -100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1); }
      /* line 187, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .reference h4 span, section.component.image-slider .reference h4 span {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        margin-right: 8px;
        overflow: hidden;
        transition: width 0.4s cubic-bezier(1, 0, 0, 1), margin-right 0.4s cubic-bezier(1, 0, 0, 1), opacity 0.1s ease; }
        /* line 195, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference h4 span:before, section.component.column-block .reference h4 span:after, section.component.image-slider .reference h4 span:before, section.component.image-slider .reference h4 span:after {
          content: '';
          width: 14px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin: -1px 0 0 0;
          background-color: #CD007A;
          border-radius: 1px; }
        /* line 209, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference h4 span:after, section.component.image-slider .reference h4 span:after {
          transform: rotate(90deg); }
    /* line 215, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference img, section.component.image-slider .reference img {
      width: 100%;
      height: auto; }
    /* line 220, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .reference q, section.component.image-slider .reference q {
      position: absolute;
      left: 0;
      top: 50%;
      margin: 0;
      padding: 0 25px;
      width: 100%;
      line-height: 22px;
      font-size: 14px;
      transform: translateY(-40%);
      text-align: center; }
      /* line 232, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .reference q:after, section.component.column-block .reference q:before, section.component.image-slider .reference q:after, section.component.image-slider .reference q:before {
        display: none; }
      /* line 237, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .reference q p, section.component.image-slider .reference q p {
        font-family: 'Times', serif;
        font-style: italic;
        color: #231F20;
        line-height: 1.2em; }
        /* line 242, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference q p:before, section.component.image-slider .reference q p:before {
          content: '“'; }
        /* line 243, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference q p:after, section.component.image-slider .reference q p:after {
          content: '”'; }
      /* line 246, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .reference q a, section.component.image-slider .reference q a {
        width: auto;
        display: table;
        font-family: "TideSans-600Bunny", sans-serif;
        color: #CD007A;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 10px;
        position: relative;
        transition: color .2s ease;
        z-index: 1;
        margin: 0 auto; }
        /* line 259, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference q a:hover, section.component.image-slider .reference q a:hover {
          color: #231F20; }
          /* line 261, assets/sass/components/page-components/_column-block.scss */
          section.component.column-block .reference q a:hover:after, section.component.image-slider .reference q a:hover:after {
            width: 100%; }
        /* line 266, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference q a:after, section.component.image-slider .reference q a:after {
          content: '';
          width: 0;
          height: 10px;
          background: #FFF;
          position: absolute;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1);
          right: 0;
          bottom: 0;
          z-index: -1;
          transform: translate(5px, -1px);
          backface-visibility: hidden;
          *zoom: 1; }
        /* line 281, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .reference q a i, section.component.image-slider .reference q a i {
          margin-left: 4px; }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 289, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .news-carousel, section.component.image-slider .news-carousel {
      max-width: 640px;
      margin: 0 auto; } }
  /* line 301, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block .carousel-item img, section.component.image-slider .carousel-item img {
    width: 100%;
    height: auto; }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 297, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .carousel-item, section.component.image-slider .carousel-item {
      width: 640px; } }
  /* line 310, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block .carousel-item .editor_content, section.component.image-slider .carousel-item .editor_content {
    background-color: #FFF;
    padding: 25px;
    float: right;
    max-width: 80%;
    margin-top: -60px;
    position: relative;
    z-index: 2; }
    @media (max-width: 767.98px) {
      /* line 310, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content, section.component.image-slider .carousel-item .editor_content {
        padding: 15px;
        max-width: 300px; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 310, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content, section.component.image-slider .carousel-item .editor_content {
        max-width: 90%; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 310, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content, section.component.image-slider .carousel-item .editor_content {
        float: none;
        max-width: 500px;
        float: right;
        margin-top: -80px; } }
    /* line 335, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .carousel-item .editor_content .h2, section.component.column-block .carousel-item .editor_content h2, section.component.image-slider .carousel-item .editor_content .h2, section.component.image-slider .carousel-item .editor_content h2 {
      color: #CD007A;
      margin: 0 0 .4em 0; }
      /* line 338, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content .h2 a, section.component.column-block .carousel-item .editor_content h2 a, section.component.image-slider .carousel-item .editor_content .h2 a, section.component.image-slider .carousel-item .editor_content h2 a {
        color: #CD007A;
        text-decoration: none; }
    @media (max-width: 767.98px) {
      /* line 344, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content p, section.component.image-slider .carousel-item .editor_content p {
        display: none; } }
    /* line 350, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .carousel-item .editor_content time, section.component.image-slider .carousel-item .editor_content time {
      color: #9d9fa2;
      border-left: 3px solid #FFE600;
      padding-left: 7px;
      font-family: "TideSans-300LilKahuna", sans-serif;
      display: inline-block;
      width: 100%;
      margin: 0 0 .3em 0;
      overflow-wrap: break-word; }
      /* line 360, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .carousel-item .editor_content time a, section.component.column-block .carousel-item .editor_content time span.separator, section.component.image-slider .carousel-item .editor_content time a, section.component.image-slider .carousel-item .editor_content time span.separator {
        color: #9d9fa2;
        font-family: "TideSans-300LilKahuna", sans-serif;
        transition: color .2s;
        text-decoration: none; }
        /* line 365, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block .carousel-item .editor_content time a:hover, section.component.column-block .carousel-item .editor_content time span.separator:hover, section.component.image-slider .carousel-item .editor_content time a:hover, section.component.image-slider .carousel-item .editor_content time span.separator:hover {
          color: #CD007A; }
  @media (max-width: 767.98px) {
    /* line 377, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block.carousel--version .owl-nav, section.component.image-slider.carousel--version .owl-nav {
      top: 50px; }
      /* line 380, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block.carousel--version .owl-nav button, section.component.image-slider.carousel--version .owl-nav button {
        width: 36px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 377, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block.carousel--version .owl-nav, section.component.image-slider.carousel--version .owl-nav {
      top: 50%;
      transform: translateY(-90px); }
      /* line 388, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block.carousel--version .owl-nav button, section.component.image-slider.carousel--version .owl-nav button {
        width: 36px; } }
  /* line 394, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block.carousel--version .button-row, section.component.image-slider.carousel--version .button-row {
    padding-top: 0; }
    @media (max-width: 767.98px) {
      /* line 394, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block.carousel--version .button-row, section.component.image-slider.carousel--version .button-row {
        padding-top: 20px; } }
  /* line 400, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block.carousel--version figure.bg-img, section.component.image-slider.carousel--version figure.bg-img {
    height: 432px; }
    @media (max-width: 767.98px) {
      /* line 400, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block.carousel--version figure.bg-img, section.component.image-slider.carousel--version figure.bg-img {
        height: 98%; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 400, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block.carousel--version figure.bg-img, section.component.image-slider.carousel--version figure.bg-img {
        height: 421px; } }
  /* line 413, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block .button-row, section.component.image-slider .button-row {
    padding-top: 60px; }
    @media (max-width: 767.98px) {
      /* line 413, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block .button-row, section.component.image-slider .button-row {
        padding-top: 70px; } }
    /* line 418, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .button-row.smaller, section.component.image-slider .button-row.smaller {
      padding-top: 20px; }
    /* line 423, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block .button-row.multiple-buttons a.btn, section.component.image-slider .button-row.multiple-buttons a.btn {
      margin: 0 15px; }
  /* line 431, assets/sass/components/page-components/_column-block.scss */
  section.component.column-block figure.bg-img, section.component.image-slider figure.bg-img {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 75%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
    @media (max-width: 767.98px) {
      /* line 431, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block figure.bg-img, section.component.image-slider figure.bg-img {
        height: 98%; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 431, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block figure.bg-img, section.component.image-slider figure.bg-img {
        height: 95%; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 431, assets/sass/components/page-components/_column-block.scss */
      section.component.column-block figure.bg-img, section.component.image-slider figure.bg-img {
        height: 94%; } }
    /* line 455, assets/sass/components/page-components/_column-block.scss */
    section.component.column-block figure.bg-img.smaller, section.component.image-slider figure.bg-img.smaller {
      height: 67%; }
      @media (max-width: 767.98px) {
        /* line 455, assets/sass/components/page-components/_column-block.scss */
        section.component.column-block figure.bg-img.smaller, section.component.image-slider figure.bg-img.smaller {
          height: 98%; } }

/* line 1, assets/sass/components/page-components/_map.scss */
section.component.small-map-component {
  margin-bottom: 120px; }
  /* line 4, assets/sass/components/page-components/_map.scss */
  section.component.small-map-component.hide {
    display: none; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_map.scss */
    section.component.small-map-component {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_map.scss */
    section.component.small-map-component {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_map.scss */
    section.component.small-map-component {
      margin-bottom: 50px; } }
  /* line 20, assets/sass/components/page-components/_map.scss */
  section.component.small-map-component .small-map-wrapper {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px; }
    /* line 25, assets/sass/components/page-components/_map.scss */
    section.component.small-map-component .small-map-wrapper .editor_content {
      background-color: #FFF;
      position: relative;
      width: 100%;
      max-width: 380px;
      transform: translateX(-280px);
      padding: 30px 30px 30px 20px;
      z-index: 9999; }
      @media (max-width: 767.98px) {
        /* line 25, assets/sass/components/page-components/_map.scss */
        section.component.small-map-component .small-map-wrapper .editor_content {
          position: static;
          transform: translate(0, 0);
          padding: 0 0 20px 0; } }
    /* line 42, assets/sass/components/page-components/_map.scss */
    section.component.small-map-component .small-map-wrapper .small-map {
      width: 100%;
      background-color: #CD007A;
      min-height: 430px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px; }
      @media (max-width: 767.98px) {
        /* line 42, assets/sass/components/page-components/_map.scss */
        section.component.small-map-component .small-map-wrapper .small-map {
          height: 230px;
          position: static; } }
      @media (max-width: 991.98px) {
        /* line 42, assets/sass/components/page-components/_map.scss */
        section.component.small-map-component .small-map-wrapper .small-map {
          min-height: 300px; } }

/* line 66, assets/sass/components/page-components/_map.scss */
section.component.arkitektkopia-map-container {
  position: relative;
  margin-bottom: 160px; }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 71, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container:after {
      content: '';
      display: table;
      width: 100%;
      clear: both; } }
  @media (max-width: 991.98px) {
    /* line 66, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 66, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container {
      margin-bottom: 80px; } }
  /* line 87, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .ak-large-map {
    width: 100%;
    height: 660px;
    margin-bottom: 50px; }
    @media screen and (max-width: 711px) {
      /* line 87, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .ak-large-map {
        height: 400px;
        margin-bottom: 0; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 87, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .ak-large-map {
        height: 500px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 87, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .ak-large-map {
        height: 420px; } }
  /* line 107, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .iwCross {
    width: 20px !important;
    height: 20px !important;
    right: 42px !important;
    top: 13px !important;
    opacity: 1 !important; }
    /* line 114, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .iwCross img {
      display: none; }
    /* line 118, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .iwCross:after, section.component.arkitektkopia-map-container .iwCross:before {
      width: 20px;
      height: 1px;
      content: '';
      background-color: #FFE600;
      position: absolute;
      left: 0;
      top: 50%; }
    /* line 129, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .iwCross:before {
      transform: rotate(-45deg); }
    /* line 132, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .iwCross:after {
      transform: rotate(45deg); }
  /* line 138, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .gm-style-iw {
    width: auto !important;
    min-width: 290px !important;
    background-color: black; }
    /* line 143, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .gm-style-iw .gm-style-iw-d::-webkit-scrollbar-track-piece {
      background-color: black; }
    /* line 147, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .gm-style-iw .gm-style-iw-d {
      min-width: 278px !important; }
    /* line 151, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .gm-style-iw button {
      background-image: url("../img/close-printshop.svg") !important;
      background-repeat: no-repeat !important;
      top: 8px !important;
      right: 8px !important;
      width: 20px !important;
      height: 20px !important; }
      /* line 158, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .gm-style-iw button.gm-ui-hover-effect {
        opacity: 1; }
      /* line 162, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .gm-style-iw button img {
        display: none !important; }
  /* line 169, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .markers-inner {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px 20px 20px 20px; }
    /* line 173, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .markers-inner h4 {
      color: #FFF;
      font-family: "TideSans-600Bunny", sans-serif;
      font-size: 16px;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 1em; }
      /* line 181, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .markers-inner h4:before {
        width: 19px;
        height: 27px;
        content: '';
        display: inline-block;
        background-image: url("../img/small-yellow-pin.png");
        background-repeat: no-repeat;
        background-size: 19px 27px;
        margin-right: 6px;
        transform: translateY(6px); }
    /* line 195, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .markers-inner p {
      color: #FFF;
      font-family: "TideSans-300LilKahuna", sans-serif;
      font-size: 14px;
      margin: 0;
      font-weight: normal;
      line-height: 1.66em; }
      /* line 202, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .markers-inner p:last-of-type {
        margin-bottom: 16px; }
    /* line 207, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .markers-inner .map-btn {
      display: inline-block;
      text-decoration: none;
      width: 220px;
      background-color: #FFF;
      color: #CD007A;
      font-family: "TideSans-600Bunny", sans-serif;
      text-align: center;
      font-size: 16px;
      height: 50px;
      line-height: 50px; }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 221, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .container-fluid {
      width: 50%;
      max-width: 50%;
      float: left; } }
  /* line 229, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .map-side-content {
    width: 100%;
    max-width: 390px;
    padding: 40px 64px 40px;
    position: absolute;
    bottom: 80px;
    right: 50px;
    z-index: 9998; }
    @media (max-width: 767.98px) {
      /* line 229, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-side-content {
        position: static;
        padding: 30px 15px 30px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 229, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-side-content {
        position: static;
        width: 50%;
        max-width: 100%;
        bottom: auto;
        right: auto;
        padding: 0 35px 30px;
        float: left; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 229, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-side-content {
        bottom: -55px; } }
    /* line 257, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-side-content * {
      z-index: 3;
      position: relative; }
    /* line 262, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-side-content:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #FFF;
      z-index: 0; }
      @media (max-width: 767.98px) {
        /* line 262, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-side-content:before {
          display: none; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 262, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-side-content:before {
          display: none; } }
    /* line 281, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-side-content:after {
      width: calc(100% + 40px);
      height: calc(100% - 20px);
      content: '';
      top: 40px;
      left: -20px;
      position: absolute;
      border: 1px solid #231F20;
      z-index: -1; }
      @media (max-width: 767.98px) {
        /* line 281, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-side-content:after {
          display: none; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 281, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-side-content:after {
          display: none; } }
  /* line 301, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .payoff p {
    border-left: 3px solid #FFE600;
    padding-left: 30px;
    font-family: "TideSans-300LilKahuna", sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal; }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 301, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .payoff p {
        max-width: 80%; } }
    @media (max-width: 767.98px) {
      /* line 301, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .payoff p {
        padding-left: 15px;
        transform: translateX(-15px); } }
  /* line 319, assets/sass/components/page-components/_map.scss */
  section.component.arkitektkopia-map-container .map-dropdown-container {
    width: 390px;
    height: 51px;
    background-color: #FFF;
    position: absolute;
    top: 70px;
    right: 50px;
    transition: background-color .3s;
    z-index: 9999; }
    @media (max-width: 767.98px) {
      /* line 319, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container {
        width: auto;
        left: 15px;
        right: 15px;
        top: 15px;
        height: 40px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 319, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container {
        top: 15px;
        left: 15px; } }
    /* line 343, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-dropdown-container.list-visible {
      background-color: #FFE600; }
      /* line 345, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container.list-visible p.printshop-list-trigger span {
        transform: rotate(-180deg); }
    /* line 350, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
      display: block;
      width: 100%;
      height: 51px;
      line-height: 49px;
      color: #231F20;
      font-size: 16px;
      padding-left: 15px;
      cursor: pointer; }
      @media (max-width: 767.98px) {
        /* line 350, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
          height: 40px;
          line-height: 39px;
          font-size: 14px; } }
      /* line 366, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger span, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav span {
        width: 51px;
        height: 51px;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        transition: transform .3s; }
        @media (max-width: 767.98px) {
          /* line 366, assets/sass/components/page-components/_map.scss */
          section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger span, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav span {
            width: 40px;
            height: 40px; } }
        /* line 379, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger span i, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav span i {
          font-size: 20px; }
          @media (max-width: 767.98px) {
            /* line 379, assets/sass/components/page-components/_map.scss */
            section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger span i, section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav span i {
              font-size: 16px; } }
    /* line 388, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
      display: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      background-color: inherit; }
      /* line 396, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav::after {
        content: "\25BC";
        position: absolute;
        height: .8em;
        font-size: .6em;
        line-height: 1;
        right: .9em;
        top: 50%;
        margin-top: -.5em;
        color: #231F20; }
      /* line 408, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav + .fa {
        display: none;
        position: absolute;
        top: 10px;
        right: 16px;
        font-size: 20px; }
    @media only screen and (min-device-width: 300px) and (max-device-width: 768px) {
      /* line 418, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger {
        display: none; }
      /* line 422, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
        display: block; }
        /* line 424, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav + .fa {
          display: block; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      /* line 431, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger {
        display: none; }
      /* line 435, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
        display: block; }
        /* line 437, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav + .fa {
          display: block; } }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (orientation: landscape) {
      /* line 444, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container p.printshop-list-trigger {
        display: none; }
      /* line 448, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav {
        display: block; }
        /* line 450, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container #sectional_nav + .fa {
          display: block; } }
    /* line 456, assets/sass/components/page-components/_map.scss */
    section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      background-color: #FFF;
      list-style: none;
      border: 1px solid #231F20;
      max-height: 400px;
      overflow-y: scroll;
      display: none; }
      @media (max-width: 767.98px) {
        /* line 456, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list {
          max-height: 300px; } }
      /* line 475, assets/sass/components/page-components/_map.scss */
      section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li {
        width: 100%;
        display: block;
        height: 34px;
        line-height: 34px; }
        /* line 481, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li:first-of-type {
          margin-top: 10px; }
        /* line 482, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li:last-of-type {
          margin-bottom: 10px; }
        /* line 485, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li:nth-child(odd) a {
          background-color: #EEEDED; }
        /* line 490, assets/sass/components/page-components/_map.scss */
        section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li a {
          display: block;
          width: 100%;
          height: 100%;
          color: #CD007A;
          padding-left: 15px;
          text-decoration: none;
          transition: background-color .2s ease; }
          /* line 498, assets/sass/components/page-components/_map.scss */
          section.component.arkitektkopia-map-container .map-dropdown-container ul.printshop-list li a:hover {
            background-color: #FFE600;
            color: #231F20; }

/* line 2, assets/sass/components/page-components/_contact-people.scss */
section.component.contact-people .contact-wrapper {
  margin-bottom: 120px;
  position: relative; }
  @media (max-width: 767.98px) {
    /* line 2, assets/sass/components/page-components/_contact-people.scss */
    section.component.contact-people .contact-wrapper {
      margin-bottom: 50px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 2, assets/sass/components/page-components/_contact-people.scss */
    section.component.contact-people .contact-wrapper {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 2, assets/sass/components/page-components/_contact-people.scss */
    section.component.contact-people .contact-wrapper {
      margin-bottom: 70px; } }
  /* line 18, assets/sass/components/page-components/_contact-people.scss */
  section.component.contact-people .contact-wrapper img {
    width: 100%;
    max-width: 320px;
    height: auto; }
    @media (max-width: 767.98px) {
      /* line 18, assets/sass/components/page-components/_contact-people.scss */
      section.component.contact-people .contact-wrapper img {
        max-width: 320px; } }
  /* line 27, assets/sass/components/page-components/_contact-people.scss */
  section.component.contact-people .contact-wrapper .editor_content {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-30%);
    background-color: #FFF;
    width: 55%;
    padding: 20px; }
    @media (max-width: 991.98px) {
      /* line 27, assets/sass/components/page-components/_contact-people.scss */
      section.component.contact-people .contact-wrapper .editor_content {
        top: auto;
        bottom: 0;
        width: 100%;
        max-width: 320px;
        transform: translateY(50px);
        right: -15px;
        padding: 15px; } }
    /* line 46, assets/sass/components/page-components/_contact-people.scss */
    section.component.contact-people .contact-wrapper .editor_content h3 {
      text-transform: none;
      font-size: 19px;
      font-weight: normal;
      margin: 0 0 .2em 0; }
    /* line 53, assets/sass/components/page-components/_contact-people.scss */
    section.component.contact-people .contact-wrapper .editor_content a {
      position: relative;
      transition: color .2s; }
      /* line 56, assets/sass/components/page-components/_contact-people.scss */
      section.component.contact-people .contact-wrapper .editor_content a:after {
        content: '';
        width: 0;
        height: 1px;
        background-color: #FFE600;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: width 0.4s cubic-bezier(1, 0, 0, 1); }
      /* line 69, assets/sass/components/page-components/_contact-people.scss */
      section.component.contact-people .contact-wrapper .editor_content a:hover {
        color: #231F20; }
        /* line 71, assets/sass/components/page-components/_contact-people.scss */
        section.component.contact-people .contact-wrapper .editor_content a:hover:after {
          width: 100%; }
      @media (max-width: 991.98px) {
        /* line 53, assets/sass/components/page-components/_contact-people.scss */
        section.component.contact-people .contact-wrapper .editor_content a {
          font-size: 13px; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        /* line 53, assets/sass/components/page-components/_contact-people.scss */
        section.component.contact-people .contact-wrapper .editor_content a {
          font-size: 13px; } }

/* line 1, assets/sass/components/page-components/_content-block.scss */
section.component.content-block {
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block {
      margin-bottom: 50px; } }
  /* line 14, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .offset-title-row {
    margin-bottom: 50px; }
    @media (max-width: 767.98px) {
      /* line 14, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .offset-title-row {
        margin-bottom: 30px; } }
  /* line 20, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .contentblock-row {
    margin-bottom: 50px; }
    @media (max-width: 767.98px) {
      /* line 20, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-row {
        margin-bottom: 30px; } }
    /* line 25, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-row:last-of-type {
      margin-bottom: 0; }
    @media (max-width: 767.98px) {
      /* line 29, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-row .editor_content {
        margin-bottom: 30px; } }
    @media (max-width: 767.98px) {
      /* line 33, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-row .editor_content:last-of-type {
        margin-bottom: 0; } }
    /* line 38, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-row .editor_content strong {
      font-family: "TideSans-600Bunny", sans-serif, sans-serif; }
    /* line 43, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-row.contentblock-row-3col {
      margin-bottom: 50px; }
      @media (max-width: 767.98px) {
        /* line 43, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-3col {
          margin-bottom: 30px; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        /* line 43, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-3col {
          margin-bottom: 60px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 43, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-3col {
          margin-bottom: 20px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 56, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-3col .editor_content {
          margin-bottom: 30px; } }
    /* line 64, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-row.contentblock-row-4col {
      margin-bottom: 100px; }
      @media (max-width: 767.98px) {
        /* line 64, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-4col {
          margin-bottom: 30px; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        /* line 64, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-4col {
          margin-bottom: 60px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 64, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-4col {
          margin-bottom: 20px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 77, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-row-4col .editor_content {
          margin-bottom: 30px; } }
    /* line 85, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-row.contentblock-faqs {
      margin-bottom: 60px; }
      @media (max-width: 767.98px) {
        /* line 85, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-row.contentblock-faqs {
          margin-bottom: 30px; } }
  /* line 93, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .text-left {
    text-align: left !important; }
  /* line 96, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .text-center {
    text-align: center !important; }
  /* line 99, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .text-right {
    text-align: right !important; }
  /* line 104, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block ul.faqs {
    margin: 0;
    padding: 0;
    list-style: none; }
    /* line 109, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block ul.faqs li {
      transition: background-color .2s; }
      /* line 111, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block ul.faqs li:nth-child(odd) {
        background-color: #EEEDED; }
      /* line 115, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block ul.faqs li.question-active {
        background-color: #FFE600; }
        /* line 117, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block ul.faqs li.question-active h4:after {
          transform: rotate(-180deg); }
      /* line 122, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block ul.faqs li h4 {
        display: table;
        width: 100%;
        position: relative;
        font-family: "TideSans-300LilKahuna", sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: #231F20;
        height: 60px;
        margin: 0;
        padding: 0 30px;
        cursor: pointer; }
        @media (max-width: 767.98px) {
          /* line 122, assets/sass/components/page-components/_content-block.scss */
          section.component.content-block ul.faqs li h4 {
            padding: 0 30px 0 15px; } }
        /* line 139, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block ul.faqs li h4 span {
          display: table-cell;
          vertical-align: middle; }
        /* line 144, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block ul.faqs li h4:after {
          width: 60px;
          height: 60px;
          content: '';
          display: block;
          background-image: url("../img/faq-arrow.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 13px 9px;
          position: absolute;
          right: 0;
          top: 0;
          transition: transform .2s;
          backface-visibility: hidden;
          *zoom: 1; }
      /* line 162, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block ul.faqs li .editor_content {
        display: none;
        padding: 0 30px 30px 30px; }
        /* line 165, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block ul.faqs li .editor_content p + ul {
          margin-top: -10px; }
        /* line 168, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block ul.faqs li .editor_content ul {
          padding: 0 0 20px 15px;
          list-style-type: disc; }
          /* line 171, assets/sass/components/page-components/_content-block.scss */
          section.component.content-block ul.faqs li .editor_content ul li:nth-child(odd) {
            background-color: transparent; }
  /* line 179, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block figure.spacer {
    width: 100%;
    height: 50px; }
    @media (max-width: 767.98px) {
      /* line 179, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block figure.spacer {
        display: none; } }
  /* line 187, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .fact-box {
    background-color: #EEEDED;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px; }
    /* line 193, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box.white {
      color: #FFF; }
    /* line 197, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box .ak-puff {
      position: absolute;
      top: 32px;
      margin: 0;
      padding: 0;
      z-index: 10; }
      @media (max-width: 767.98px) {
        /* line 197, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .fact-box .ak-puff {
          display: none; } }
      /* line 207, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .fact-box .ak-puff .puff-content {
        position: relative;
        z-index: 3;
        display: inline-block;
        background-color: #FFF;
        padding: 12px 18px;
        color: #231F20;
        font-family: "TideSans-600Bunny", sans-serif, sans-serif;
        text-transform: uppercase;
        font-size: 22px;
        line-height: 26px;
        margin: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        max-width: 200px; }
      /* line 224, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .fact-box .ak-puff:after {
        content: '';
        z-index: 1;
        position: absolute;
        width: 100%;
        height: calc(100% + 10px + 40px);
        background-color: #FFE600;
        left: 0;
        top: 0;
        padding: 10px 0 30px 0;
        -webkit-transform: translate(-28px, -18px);
        transform: translate(-28px, -18px); }
    /* line 241, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box .editor_content h3.white {
      color: #FFF; }
    /* line 248, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box.one-col .ak-puff {
      right: 32px; }
    /* line 254, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box.two-col .right-col {
      padding-top: 140px; }
      @media (max-width: 767.98px) {
        /* line 254, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .fact-box.two-col .right-col {
          padding-top: 0; } }
      /* line 260, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .fact-box.two-col .right-col .ak-puff {
        top: 16px;
        left: 42px; }
        /* line 263, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .fact-box.two-col .right-col .ak-puff .puff-content {
          max-width: 280px; }
    /* line 269, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box.two-col .title {
      padding-left: 0; }
    /* line 275, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .fact-box .title h3 {
      color: #231F20;
      font-family: "TideSans-600Bunny", sans-serif;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: normal;
      margin: 0 0 .4em 0;
      font-size: 18px;
      line-height: 26px;
      border-left: 3px solid #FFE600;
      padding-left: 13px;
      transform: translateX(-15px); }
      /* line 283, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .fact-box .title h3.white {
        color: #FFF; }
  /* line 291, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block .contentblock-table table {
    width: 100%; }
    /* line 295, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col1 th:first-child, section.component.content-block .contentblock-table table.col1 td:first-child {
      color: #CD007A; }
    /* line 303, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col2 th:nth-child(2), section.component.content-block .contentblock-table table.col2 td:nth-child(2) {
      color: #CD007A; }
    /* line 310, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col3 th:nth-child(3), section.component.content-block .contentblock-table table.col3 td:nth-child(3) {
      color: #CD007A; }
    /* line 317, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col4 th:nth-child(4), section.component.content-block .contentblock-table table.col4 td:nth-child(4) {
      color: #CD007A; }
    /* line 324, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col5 th:nth-child(5), section.component.content-block .contentblock-table table.col5 td:nth-child(5) {
      color: #CD007A; }
    /* line 331, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col6 th:nth-child(6), section.component.content-block .contentblock-table table.col6 td:nth-child(6) {
      color: #CD007A; }
    /* line 338, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col7 th:nth-child(7), section.component.content-block .contentblock-table table.col7 td:nth-child(7) {
      color: #CD007A; }
    /* line 345, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col8 th:nth-child(8), section.component.content-block .contentblock-table table.col8 td:nth-child(8) {
      color: #CD007A; }
    /* line 352, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col9 th:nth-child(9), section.component.content-block .contentblock-table table.col9 td:nth-child(9) {
      color: #CD007A; }
    /* line 359, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.col10 th:nth-child(10), section.component.content-block .contentblock-table table.col10 td:nth-child(10) {
      color: #CD007A; }
    /* line 364, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table th {
      font-family: "TideSans-600Bunny", sans-serif, sans-serif;
      border-bottom: 1px solid #231F20; }
    /* line 369, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table td, section.component.content-block .contentblock-table table th {
      border-left: 1px solid #231F20;
      padding: 5px 10px;
      line-height: 22px; }
      /* line 374, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-table table td:first-child, section.component.content-block .contentblock-table table th:first-child {
        border-left: none;
        padding-left: 0; }
      /* line 379, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-table table td .strong, section.component.content-block .contentblock-table table th .strong {
        font-family: "TideSans-600Bunny", sans-serif, sans-serif; }
      /* line 383, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-table table td .magenta, section.component.content-block .contentblock-table table th .magenta {
        color: #CD007A; }
      @media (max-width: 767.98px) {
        /* line 369, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-table table td, section.component.content-block .contentblock-table table th {
          font-size: 11px;
          padding: 5px; } }
      @media (max-width: 575.98px) {
        /* line 369, assets/sass/components/page-components/_content-block.scss */
        section.component.content-block .contentblock-table table td, section.component.content-block .contentblock-table table th {
          font-size: 10px; } }
    /* line 398, assets/sass/components/page-components/_content-block.scss */
    section.component.content-block .contentblock-table table.lines tr {
      border-bottom: 1px solid #231F20; }
      /* line 400, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-table table.lines tr:nth-child(2) {
        border-top: 1px solid #231F20; }
      /* line 403, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block .contentblock-table table.lines tr:last-child {
        border-bottom: none; }
  /* line 412, assets/sass/components/page-components/_content-block.scss */
  section.component.content-block + .cta-akademi {
    margin-top: -60px; }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 412, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block + .cta-akademi {
        margin-top: -20px; } }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 412, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block + .cta-akademi {
        margin-top: -20px; } }
    @media (max-width: 575.98px) {
      /* line 412, assets/sass/components/page-components/_content-block.scss */
      section.component.content-block + .cta-akademi {
        margin-top: 0; } }

/* line 1, assets/sass/components/page-components/_quote-block.scss */
section.component.quote-block {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0;
  margin: 0 0 120px 0; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block {
      padding: 30px 0;
      margin: 0 0 45px 0; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block {
      padding: 60px 0;
      margin: 0 0 60px 0; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block {
      padding: 50px 0;
      margin: 0 0 50px 0; } }
  /* line 24, assets/sass/components/page-components/_quote-block.scss */
  section.component.quote-block .quote-slider .owl-item {
    padding: 0 0 50px 0; }
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
      /* line 24, assets/sass/components/page-components/_quote-block.scss */
      section.component.quote-block .quote-slider .owl-item {
        width: 100%; } }
  /* line 34, assets/sass/components/page-components/_quote-block.scss */
  section.component.quote-block blockquote {
    position: relative;
    margin: 0;
    padding: 34px; }
    @media (max-width: 767.98px) {
      /* line 34, assets/sass/components/page-components/_quote-block.scss */
      section.component.quote-block blockquote {
        padding: 20px 20px 30px 20px; } }
    /* line 44, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block blockquote p {
      font-family: 'Times', serif;
      font-style: italic;
      color: #FFF;
      font-size: 35px; }
      @media (max-width: 767.98px) {
        /* line 44, assets/sass/components/page-components/_quote-block.scss */
        section.component.quote-block blockquote p {
          font-size: 22px;
          line-height: 1.22em; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 44, assets/sass/components/page-components/_quote-block.scss */
        section.component.quote-block blockquote p {
          font-size: 28px;
          line-height: 1.22em; } }
      /* line 60, assets/sass/components/page-components/_quote-block.scss */
      section.component.quote-block blockquote p:before {
        content: '“'; }
      /* line 61, assets/sass/components/page-components/_quote-block.scss */
      section.component.quote-block blockquote p:after {
        content: '”'; }
    /* line 65, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block blockquote:before, section.component.quote-block blockquote:after {
      content: '';
      height: 100%;
      width: 34px;
      position: absolute;
      top: 0;
      display: block;
      border: 1px solid #FFF; }
    /* line 76, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block blockquote:before {
      border-right: 0;
      left: 0; }
    /* line 81, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block blockquote:after {
      border-left: 0;
      right: 0; }
    /* line 86, assets/sass/components/page-components/_quote-block.scss */
    section.component.quote-block blockquote em {
      display: inline-block;
      position: absolute;
      right: 60px;
      top: 100%;
      background-color: #FFE600;
      font-style: normal;
      font-family: "TideSans-600Bunny", sans-serif;
      font-size: 16px;
      padding: 15px;
      transform: translateY(-50%); }
      /* line 97, assets/sass/components/page-components/_quote-block.scss */
      section.component.quote-block blockquote em span {
        display: block;
        font-family: "TideSans-300LilKahuna", sans-serif;
        font-size: 14px; }
      @media (max-width: 767.98px) {
        /* line 86, assets/sass/components/page-components/_quote-block.scss */
        section.component.quote-block blockquote em {
          top: 105%; } }

/* line 1, assets/sass/components/page-components/_inspiration.scss */
section.component.inspiration {
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_inspiration.scss */
    section.component.inspiration {
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_inspiration.scss */
    section.component.inspiration {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_inspiration.scss */
    section.component.inspiration {
      margin-bottom: 50px; } }
  /* line 16, assets/sass/components/page-components/_inspiration.scss */
  section.component.inspiration .title-row {
    margin-bottom: 30px; }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      /* line 16, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .title-row {
        margin-bottom: 10px; } }
  /* line 23, assets/sass/components/page-components/_inspiration.scss */
  section.component.inspiration .my-flex-columns {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px; }
    @media (max-width: 767.98px) {
      /* line 23, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns {
        margin: 0; } }
    /* line 32, assets/sass/components/page-components/_inspiration.scss */
    section.component.inspiration .my-flex-columns .column {
      position: relative;
      transition: transform .2s;
      backface-visibility: hidden;
      *zoom: 1; }
      /* line 38, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column:hover {
        transform: scale(1.05); }
      @media (max-width: 767.98px) {
        /* line 32, assets/sass/components/page-components/_inspiration.scss */
        section.component.inspiration .my-flex-columns .column {
          padding: 7.5px 0;
          margin-bottom: 7.5px; } }
      /* line 47, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column.small-col {
        width: 25%; }
        @media (max-width: 767.98px) {
          /* line 47, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column.small-col {
            width: 100%; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 47, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column.small-col {
            width: 33.3333333333%; } }
      /* line 57, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column.big-col {
        width: 50%; }
        @media (max-width: 767.98px) {
          /* line 57, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column.big-col {
            width: 100%; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 57, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column.big-col {
            width: 66.6666666667%; } }
      /* line 68, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column img {
        width: 100%;
        height: auto; }
      /* line 73, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column .column-inner {
        padding-bottom: 75%;
        position: relative; }
      /* line 78, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column a {
        width: auto;
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        background-size: cover;
        background-repeat: no-repeat; }
        @media (max-width: 767.98px) {
          /* line 78, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column a {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; } }
      /* line 98, assets/sass/components/page-components/_inspiration.scss */
      section.component.inspiration .my-flex-columns .column p.inspiration-link {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #FFF;
        padding: 10px 10px 10px 0;
        color: #231F20;
        text-transform: uppercase;
        text-decoration: none;
        font-family: "TideSans-600Bunny", sans-serif;
        font-size: 14px;
        margin: 0; }
        @media (max-width: 767.98px) {
          /* line 98, assets/sass/components/page-components/_inspiration.scss */
          section.component.inspiration .my-flex-columns .column p.inspiration-link {
            bottom: 0; } }
        /* line 116, assets/sass/components/page-components/_inspiration.scss */
        section.component.inspiration .my-flex-columns .column p.inspiration-link i {
          color: #CD007A;
          margin-right: 5px; }

/* line 1, assets/sass/components/page-components/_references.scss */
section.component.references {
  margin-bottom: 100px;
  margin-top: -90px; }
  @media (max-width: 767.98px) {
    /* line 1, assets/sass/components/page-components/_references.scss */
    section.component.references {
      margin-bottom: 30px;
      margin-top: -20px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 1, assets/sass/components/page-components/_references.scss */
    section.component.references {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 1, assets/sass/components/page-components/_references.scss */
    section.component.references {
      margin-bottom: 50px;
      margin-top: -50px; } }
  /* line 19, assets/sass/components/page-components/_references.scss */
  section.component.references .reference {
    position: relative;
    overflow: hidden;
    margin: 0 0 30px 0; }
    @media (max-width: 767.98px) {
      /* line 19, assets/sass/components/page-components/_references.scss */
      section.component.references .reference {
        margin: 0 0 15px 0; } }
    /* line 29, assets/sass/components/page-components/_references.scss */
    section.component.references .reference:hover .reference-content {
      top: 0;
      transform: translateX(0); }
      /* line 32, assets/sass/components/page-components/_references.scss */
      section.component.references .reference:hover .reference-content h4 {
        transform: translate(0, 0); }
        /* line 34, assets/sass/components/page-components/_references.scss */
        section.component.references .reference:hover .reference-content h4 span {
          opacity: 0;
          width: 0;
          margin-right: 14px; }
    /* line 43, assets/sass/components/page-components/_references.scss */
    section.component.references .reference .reference-content {
      background-color: rgba(255, 230, 0, 0.85);
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1), top 0.4s cubic-bezier(1, 0, 0, 1); }
    /* line 56, assets/sass/components/page-components/_references.scss */
    section.component.references .reference.small-reference .reference-content p {
      font-size: 25px; }
      @media (max-width: 767.98px) {
        /* line 56, assets/sass/components/page-components/_references.scss */
        section.component.references .reference.small-reference .reference-content p {
          font-size: 23px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 56, assets/sass/components/page-components/_references.scss */
        section.component.references .reference.small-reference .reference-content p {
          display: none; } }
    /* line 70, assets/sass/components/page-components/_references.scss */
    section.component.references .reference.big-reference .reference-content p {
      font-size: 35px; }
      @media (max-width: 767.98px) {
        /* line 70, assets/sass/components/page-components/_references.scss */
        section.component.references .reference.big-reference .reference-content p {
          font-size: 23px; } }
      @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
        /* line 70, assets/sass/components/page-components/_references.scss */
        section.component.references .reference.big-reference .reference-content p {
          font-size: 28px; } }
    /* line 82, assets/sass/components/page-components/_references.scss */
    section.component.references .reference h4 {
      margin: 0;
      padding: 14px 14px 14px 0;
      line-height: 1;
      display: table;
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #FFF;
      font-family: "TideSans-600Bunny", sans-serif;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      z-index: 1;
      transform: translate(100%, -100%);
      transition: transform 0.4s cubic-bezier(1, 0, 0, 1); }
      /* line 100, assets/sass/components/page-components/_references.scss */
      section.component.references .reference h4 span {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        margin-right: 8px;
        overflow: hidden;
        transition: width 0.4s cubic-bezier(1, 0, 0, 1), margin-right 0.4s cubic-bezier(1, 0, 0, 1), opacity 0.1s ease; }
        /* line 108, assets/sass/components/page-components/_references.scss */
        section.component.references .reference h4 span:before, section.component.references .reference h4 span:after {
          content: '';
          width: 14px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin: -1px 0 0 0;
          background-color: #CD007A;
          border-radius: 1px; }
        /* line 122, assets/sass/components/page-components/_references.scss */
        section.component.references .reference h4 span:after {
          transform: rotate(90deg); }
    /* line 128, assets/sass/components/page-components/_references.scss */
    section.component.references .reference img {
      width: 100%;
      height: auto; }
    /* line 133, assets/sass/components/page-components/_references.scss */
    section.component.references .reference q {
      position: absolute;
      left: 0;
      top: 50%;
      margin: 0;
      padding: 0 25px;
      width: 100%;
      line-height: 22px;
      font-size: 14px;
      transform: translateY(-40%);
      text-align: center; }
      /* line 145, assets/sass/components/page-components/_references.scss */
      section.component.references .reference q:after, section.component.references .reference q:before {
        display: none; }
      /* line 150, assets/sass/components/page-components/_references.scss */
      section.component.references .reference q p {
        font-family: 'Times', serif;
        font-style: italic;
        color: #231F20;
        line-height: 1.2em; }
        /* line 155, assets/sass/components/page-components/_references.scss */
        section.component.references .reference q p:before {
          content: '“'; }
        /* line 156, assets/sass/components/page-components/_references.scss */
        section.component.references .reference q p:after {
          content: '”'; }
      /* line 159, assets/sass/components/page-components/_references.scss */
      section.component.references .reference q a {
        width: auto;
        display: table;
        font-family: "TideSans-600Bunny", sans-serif;
        color: #CD007A;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 10px;
        position: relative;
        transition: color .2s ease;
        z-index: 1;
        margin: 0 auto; }
        /* line 172, assets/sass/components/page-components/_references.scss */
        section.component.references .reference q a:hover {
          color: #231F20; }
          /* line 174, assets/sass/components/page-components/_references.scss */
          section.component.references .reference q a:hover:after {
            width: 100%; }
        /* line 179, assets/sass/components/page-components/_references.scss */
        section.component.references .reference q a:after {
          content: '';
          width: 0;
          height: 10px;
          background: #FFF;
          position: absolute;
          transition: width 0.2s cubic-bezier(1, 0, 0, 1);
          right: 0;
          bottom: 0;
          z-index: -1;
          transform: translate(5px, -1px);
          backface-visibility: hidden;
          *zoom: 1; }
        /* line 194, assets/sass/components/page-components/_references.scss */
        section.component.references .reference q a i {
          margin-left: 4px; }
  /* line 203, assets/sass/components/page-components/_references.scss */
  section.component.references .my-flex-columns {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px; }
    @media (max-width: 767.98px) {
      /* line 203, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns {
        margin: 0; } }
    /* line 212, assets/sass/components/page-components/_references.scss */
    section.component.references .my-flex-columns .column {
      position: relative;
      backface-visibility: hidden;
      *zoom: 1; }
      @media (max-width: 767.98px) {
        /* line 212, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column {
          padding: 7.5px 0;
          margin-bottom: 7.5px; } }
      /* line 222, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column.small-col {
        width: 33.3333333333%; }
        @media (max-width: 767.98px) {
          /* line 222, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column.small-col {
            width: 100%; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 222, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column.small-col {
            width: 33.3333333333%; } }
        /* line 231, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column.small-col .inner-content .hidden-content .hidden-content-inner q {
          font-size: 25px; }
      /* line 237, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column.big-col {
        width: 66.6666666667%; }
        @media (max-width: 767.98px) {
          /* line 237, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column.big-col {
            width: 100%; } }
        @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
          /* line 237, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column.big-col {
            width: 66.6666666667%; } }
        /* line 246, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column.big-col p.inspiration-link {
          white-space: nowrap; }
      /* line 252, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column img {
        width: 100%;
        height: auto; }
      /* line 259, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column:hover .inner-content .hidden-content {
        transform: translate(0, 0); }
      /* line 263, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column:hover .inner-content p.inspiration-link {
        bottom: 100%;
        left: 100%;
        transform: translate(-100%, 100%);
        padding: 10px 10px 10px 10px; }
        /* line 269, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column:hover .inner-content p.inspiration-link i {
          opacity: 0; }
      /* line 276, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column .column-inner {
        padding-bottom: 75%;
        position: relative; }
      /* line 281, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column .inner-content {
        width: auto;
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden; }
        @media (max-width: 767.98px) {
          /* line 281, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column .inner-content {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; } }
        /* line 300, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column .inner-content .hidden-content {
          background-color: rgba(255, 230, 0, 0.85);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: translate(-100%, 100%);
          transition: transform .3s; }
          /* line 312, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column .inner-content .hidden-content .hidden-content-inner {
            max-width: 100%;
            width: 100%;
            padding: 0 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; }
            /* line 322, assets/sass/components/page-components/_references.scss */
            section.component.references .my-flex-columns .column .inner-content .hidden-content .hidden-content-inner q {
              display: inline-block;
              width: 100%;
              font-family: 'Times', serif;
              font-size: 35px;
              font-style: italic;
              line-height: 1;
              margin-bottom: 13px; }
            /* line 332, assets/sass/components/page-components/_references.scss */
            section.component.references .my-flex-columns .column .inner-content .hidden-content .hidden-content-inner a {
              font-family: "TideSans-600Bunny", sans-serif;
              font-size: 14px;
              text-transform: uppercase;
              color: #CD007A;
              text-decoration: none; }
              /* line 338, assets/sass/components/page-components/_references.scss */
              section.component.references .my-flex-columns .column .inner-content .hidden-content .hidden-content-inner a i {
                margin-left: 5px; }
      /* line 349, assets/sass/components/page-components/_references.scss */
      section.component.references .my-flex-columns .column p.inspiration-link {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #FFF;
        padding: 10px 10px 10px 20px;
        color: #231F20;
        text-transform: uppercase;
        text-decoration: none;
        font-family: "TideSans-600Bunny", sans-serif;
        font-size: 14px;
        margin: 0;
        z-index: 10;
        text-align: left;
        transition: bottom .3s, left .3s, transform .3s, padding .3s; }
        @media (max-width: 767.98px) {
          /* line 349, assets/sass/components/page-components/_references.scss */
          section.component.references .my-flex-columns .column p.inspiration-link {
            bottom: 0; } }
        /* line 377, assets/sass/components/page-components/_references.scss */
        section.component.references .my-flex-columns .column p.inspiration-link i {
          color: #CD007A;
          margin-right: 5px;
          transition: opacity .3s;
          position: absolute;
          left: 3px;
          top: 50%;
          transform: translateY(-50%); }

/* line 394, assets/sass/components/page-components/_references.scss */
section.component.referenceslider {
  margin-top: -90px;
  margin-bottom: 120px; }
  @media (max-width: 767.98px) {
    /* line 394, assets/sass/components/page-components/_references.scss */
    section.component.referenceslider {
      margin-top: -30px;
      margin-bottom: 30px; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* line 394, assets/sass/components/page-components/_references.scss */
    section.component.referenceslider {
      margin-top: -50px;
      margin-bottom: 60px; } }
  @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation: portrait) {
    /* line 394, assets/sass/components/page-components/_references.scss */
    section.component.referenceslider {
      margin-top: -60px;
      margin-bottom: 50px; } }
  /* line 413, assets/sass/components/page-components/_references.scss */
  section.component.referenceslider .owl-nav.disabled {
    display: block !important; }

/* line 1, assets/sass/components/page-components/_logo-block.scss */
section.logos {
  margin: 60px 0;
  padding: 50px 0; }
  /* line 6, assets/sass/components/page-components/_logo-block.scss */
  section.logos .container-fluid .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  /* line 11, assets/sass/components/page-components/_logo-block.scss */
  section.logos .container-fluid .logo {
    padding: 23px; }
    /* line 13, assets/sass/components/page-components/_logo-block.scss */
    section.logos .container-fluid .logo img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      text-align: center;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      transition: filter 0.4s; }
      /* line 22, assets/sass/components/page-components/_logo-block.scss */
      section.logos .container-fluid .logo img:hover {
        -webkit-filter: grayscale(0%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(0%); }
  /* line 31, assets/sass/components/page-components/_logo-block.scss */
  section.logos .editor-content .h2 {
    font-size: 30px; }
    /* line 33, assets/sass/components/page-components/_logo-block.scss */
    section.logos .editor-content .h2::before {
      width: 3px;
      height: 100%;
      content: '';
      display: inline-block;
      background-color: #FFE600;
      position: absolute;
      left: -23px;
      top: 0; }

/* line 1, assets/sass/components/page-components/_bar.scss */
section.component.content-block.bar {
  margin-bottom: 0;
  width: 100%;
  background-color: #FFE600;
  padding: 17px;
  border-top: 1px solid #e5cf00; }
  /* line 7, assets/sass/components/page-components/_bar.scss */
  section.component.content-block.bar .link {
    text-align: right; }
  /* line 11, assets/sass/components/page-components/_bar.scss */
  section.component.content-block.bar p {
    font-family: "TideSans-600Bunny", sans-serif;
    font-size: 16px;
    border-left: 2px solid #CD007A;
    padding-left: 15px; }
  /* line 18, assets/sass/components/page-components/_bar.scss */
  section.component.content-block.bar .editor_content a.arrow-link.magenta {
    line-height: 0;
    text-transform: inherit; }

/* line 1, assets/sass/components/page-components/_pricing.scss */
section.component.pricing {
  margin-bottom: 50px; }
  /* line 3, assets/sass/components/page-components/_pricing.scss */
  section.component.pricing .price-wrapper {
    background-color: #EEEDED;
    height: 100%;
    display: flex;
    flex-direction: column; }
    /* line 9, assets/sass/components/page-components/_pricing.scss */
    section.component.pricing .price-wrapper .price-name {
      padding: 20px 15px;
      text-align: center; }
      /* line 12, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.magenta {
        background-color: #CD007A; }
      /* line 15, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.yellow {
        background-color: #FFE600; }
      /* line 18, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.pink {
        background-color: #F49AC1; }
      /* line 21, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.black {
        background-color: #231F20; }
      /* line 24, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.blue {
        background-color: #75BEE9; }
      /* line 27, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.gray {
        background-color: #9D9FA2; }
      /* line 30, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price-wrapper .price-name.green {
        background-color: #7CC366; }
    /* line 34, assets/sass/components/page-components/_pricing.scss */
    section.component.pricing .price-wrapper .h2.the-price {
      text-align: center;
      padding: 20px 15px; }
    /* line 39, assets/sass/components/page-components/_pricing.scss */
    section.component.pricing .price-wrapper .payment-period {
      background: rgba(255, 255, 255, 0.3);
      padding: 5px 10px 5px; }
    /* line 43, assets/sass/components/page-components/_pricing.scss */
    section.component.pricing .price-wrapper .content {
      padding: 20px 15px; }
  /* line 49, assets/sass/components/page-components/_pricing.scss */
  section.component.pricing .ak-buttons-container {
    text-align: center;
    margin-top: auto;
    padding: 0 15px 20px 15px; }
    @media (max-width: 991.98px) {
      /* line 53, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .ak-buttons-container a.magenta span, section.component.pricing .ak-buttons-container a.white span {
        min-width: 190px; } }
  @media (max-width: 575.98px) {
    /* line 59, assets/sass/components/page-components/_pricing.scss */
    section.component.pricing .price {
      margin-top: 30px; }
      /* line 62, assets/sass/components/page-components/_pricing.scss */
      section.component.pricing .price:first-child {
        margin-top: 0; } }
