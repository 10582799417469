section.component.text-image {
    margin-bottom: 120px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
    }

    .text-image-wrapper {
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
        }
        .editor_content {
            background-color: $white;
            position: absolute;
            width: 100%;
            max-width: 380px;
            left: 0;
            top: 50%;
            transform: translate(-280px,-50%);
            padding: 30px 30px 30px 20px;

            @include media-breakpoint-down(sm) {
                transform: translate(-15px,0);
                padding: 20px 20px 20px 15px;
                position: static;
                order: 2;
                margin-top: -50px;
            }

            @include tablet {
                transform: translate(-240px,-50%);
            }

            .h2 {
                margin-bottom: .4em;
                position: relative;

                &:before {
                    width: 3px;
                    height: 100%;
                    content: '';
                    display: inline-block;
                    background-color: $yellow;
                    position: absolute;
                    left: -23px;
                    top: 0;

                    @include media-breakpoint-down(sm) {
                        left: -15px;
                    }

                }

            }

            a.arrow-link {
                @include arrow-link($magenta, $black);
            }

        }

        img {
            max-width: 100%;
            height: auto;
            order: 1;
        }

    }
}

section.component.text-image-news {
    .text-image-wrapper {
        .editor_content {
            -webkit-transform: translate(400px,-50%);
            transform: translate(400px,-50%);
            padding: 30px 30px 30px 40px;
            max-width: 510px;
            ul {
                list-style: none;
                padding-inline-start: 0;
                overflow: scroll;
                max-height: 280px;
                margin-bottom: 0;
                li {
                    padding: 5px 0;

                    a.arrow-link.magenta {
                        font-family: $tideBunny;
                        text-transform: inherit;
                    }

                    time {
                        color: $gray;
                        font-family: $tideKahuna;
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 .3em;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                transform: translate(-15px,0);
                padding: 20px 20px 20px 15px;
                position: static;
                order: 2;
                margin-top: -50px;
            }

            @include tablet {
                transform: translate(240px,-50%);
            }
            @include tabletLandscape {
                transform: translate(380px,-50%);
            }
        }
    }
}
