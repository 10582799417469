@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

// Clearfix
%clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
        width: 100%;
    }
}

// Input styles
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// Fallback for svg
@mixin svg-background-fallback($image-url, $svg-url) {
  background: url($image-url);
  background: url($svg-url), linear-gradient(transparent, transparent);
}

// Query mixins
@mixin menu-breakpoint {
    @media only screen and (max-width: 900px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 481px) and (max-width: 769px) and (orientation:portrait) {
        @content;
    }
}

@mixin tabletLandscape {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        @content;
    }
}
