form.products-search {
    max-width: 572px;
}

/*Wrap for spinner positioning inside select 2*/
.select-wrap {
    position: relative;
    max-width: 572px;
    display: flex;
    align-items: center;
    .spinner {
        width: 38px;
        background: #fff;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 47px;
        svg {
            width: 80%;
        }
        &:not(.is-active) {
            display: none; /*comment it if want to test spinner and not to hide after search completed*/
    }
    }
}

/*Search spinner fix for mega menu*/
.mega-menu {
    .select-wrap {
        margin-top: 30px;
        .select2-container--default {
            margin-top: 0;
        }
    }
}

.select2-container--default {
    width: 100%!important;


    &.select2-container--open {
        .select2-selection {
            ul.select2-selection__rendered {
                background-color: $yellow;
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }


    span.selection {
        max-height: 51px;
        display: block;
    }

    .select2-selection {
        background-color: $white!important;
        border-radius: 0!important;
        border: none!important;
        min-height: 0!important;
        max-height: 51px;

        ul.select2-selection__rendered {
            margin: 0;
            padding: 0;
            transition: background-color .2s;
            position: relative;

            &:after {
                content: '';
                width: 51px;
                height: 51px;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                transition: transform .2s;

                background-image: url('../img/chevron-down.svg');
                background-repeat: no-repeat;
                background-size: 18px 11px;
                background-position: center center;
            }

            .select2-selection__choice {
                margin: 8px;
                padding: 9px 10px 8px;
                background-color: $yellow;
                color: $black;
                border-radius: 0;
                border: none;

                font-family: $tideBunny;
                font-size: 14px;

                .select2-selection__choice__remove {
                    color: $black;
                    transform: translateY(-1px);
                    margin-right: 2px;
                    display: inline-block;
                }
            }

            .select2-search--inline {
                padding: 0;
                margin: 0;
                width: 100%;
                position: relative;



                input[type="search"] {
                    margin: 0!important;
                    height: 51px;
                    width: calc(100% - 51px)!important;
                    padding: 0 10px;
                    color: $black;

                    @include input-placeholder {
                        opacity: 1;
                        font-size: 16px;
                        color: $black;
                    }
                }
            }
        }

    }


    .select2-dropdown {
        border: none!important;
        border-radius: 0!important;
        .select2-results {
            padding: 0;
            ul.select2-results__options {
                li.select2-results__option {
                    padding: 0;
                    font-size: 0;
                    line-height: 0;
                    strong.select2-results__group {
                        font-weight: normal;
                        font-family: $tideBunny;
                        padding: 13px 10px 7px;
                        line-height: 1;
                        font-size: 14px;
                        &:nth-child(even) {
                            background: $gray;

                        }
                    }

                    ul.select2-results__options--nested {
                        padding: 0;
                        li.select2-results__option {
                            padding: 5px 10px 5px 25px;
                            color: $magenta;
                            transition: background-color .2s;
                            font-size: 14px;
                            line-height: 1.22em;

                            &:nth-child(odd) {
                                background-color: $lightGray;
                            }

                            &.select2-results__option--highlighted,
                            &:hover {
                                background-color: $yellow;
                                color: $black;
                            }
                        }
                    }

                }
            }
        }
    }

}
