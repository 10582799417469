html, body, .perspective {
	width: 100%;
	height: 100%;
}

.perspective {
	position: relative;
	height: calc(100% + 1px);
	&.animate {
		main {
			padding-top: 0;
		}
	}


	button.close-menu {
		display: block;
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 0!important;
        line-height: 1;
        position: absolute;
        top: 30px;
        right: 30px;
        border: none;
        background: none;
        cursor: pointer;
		outline: none;

		@include menu-breakpoint {
			width: 25px;
			height: 25px;
			top: 15px;
			right: 15px;
		}

        span {
            display: block;
            width: 50px;
            height: 50px;

			@include menu-breakpoint {
				width: 25px;
				height: 25px;
			}


            &:after,
            &:before {
                content: '';
                height: 2px;
                width: 50px;
                display: block;
                background-color: $yellow;
                position: absolute;
                top: 50%;
                left: 0;

				@include menu-breakpoint {
					width: 25px;
				}

            }

            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
	}

}

.perspective-container {
	background: #fff;
	min-height: 100%;
	position: relative;
	outline: 1px solid rgba(0,0,0,0);
	z-index: 10;
	-webkit-transform: translateZ(0) translateX(0) rotateY(0deg); /* reset transforms (Chrome bug) */
	transform: translateZ(0) translateX(0) rotateY(0deg);
}

.perspective-container::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0px;
	opacity: 0;
	background: rgba(0,0,0,0.2);

	/* the transition delay of the height needs to be synced with the perspective-container transition time */
	-webkit-transition: opacity 0.4s, height 0s 0.4s;
	transition: opacity 0.4s, height 0s 0.4s;
}

.perspective-wrapper {
	position: relative;
}


/* Modal view */
.perspective.modalview {
	position: fixed;
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.modalview .perspective-container {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.modalview .perspective-wrapper {
	-webkit-transform: translateZ(-1px); /* solves a rendering bug in Chrome on Windows */
}

.animate .perspective-container::after {
	opacity: 1;
	height: 101%;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

/* Outer Nav */
.outer-nav {
	position: absolute;
	height: auto;
	//font-size: 2em;
}

.outer-nav.vertical {
	top: 50%;
	transform: translateY(-50%);
	transform-style: preserve-3d;
}

.outer-nav.right {
	right: 25%;

	@include media-breakpoint-down(sm) {
		right: auto;
		left: 80px;
		width: calc(100% - 80px);
	}

	@include tablet {
		right: auto;
		left: 340px;
	}

}


.outer-nav {

	> ul {
		@include menu-breakpoint {
			max-height: 90vh;
			overflow-y: scroll;
			overflow-x: hidden;
		}

		@include tablet {
			max-height: 90vh;
			overflow-y: scroll;
			overflow-x: hidden;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		&.inactive {
			> li {
				display: none!important;
				&.active {
					display: block!important;
					> a {
						display: none;
					}
				}
			}
		}

		> li {
			margin: 0 0 15px 0;

			@include menu-breakpoint {
				width: 100%;
				max-width: 300px;
			}

			&:last-of-type {
				margin: 0 0 0 0;
			}

			&.active {
				> ul.sub-menu {
					display: block;
				}
			}

			&.menu-item-has-children {
				> a:before {
					background-image: url('../img/menu-submenu.svg');
				}
			}

			&.hide-more {
				@include media-breakpoint-down(sm) {
					display: none;
				}
				@include tablet {
					display: none;
				}
				&.visible {
					display: block;
				}
			}

			&.mobile-only {
				display: none;
				@include menu-breakpoint {
					display: block;
				}
				@include tablet {
					display: block;
				}
			}

			span.show-more-menu {
				font-family: $tideKahuna;
				font-size: 18px;
				color: $yellow;
				padding: 0 0 0 30px;
				position: relative;
				cursor: pointer;

				display: none;
				@include menu-breakpoint {
					display: block;
				}
				@include tablet {
					display: block;
				}
				&:before {
					content: '...';
					display: block;
					width: 23px;
					position: absolute;
					top: 0;
					left: 5px;
					letter-spacing: 1px;
				}
			}

			> a {
				//white-space: nowrap;
				color: #fff;
			    text-decoration: none;
				font-size: 24px;
				color: $white;
			    font-family: $tideKahuna;
				transition: transform 0.4s, opacity 0.4s, color .2s;
				position: relative;
				padding: 0 0 0 35px;



				@include menu-breakpoint {
					font-size: 18px;
					padding: 0 0 0 30px;
					display: block;
					width: 100%;
					max-width: 300px;
				}

				&:before {
					display: block;
					width: 29px;
					height: 29px;
					content: '';
					left: 0;
					top: 0;
					position: absolute;
					background-image: url('../img/menu-arrow.svg');
					background-repeat: no-repeat;
					background-size: 16px;
					background-position: center center;

					@include menu-breakpoint {
						width: 23px;
						height: 23px;
						background-size: 16px;
					}

				}

			    &:hover {
			        color: $yellow;
			    }
			}

			> ul.sub-menu {
				display: none;
				overflow: visible;
				width: 100%;
				> li {
					&:nth-of-type(2) {
						transform: translateX(30px);
						@include menu-breakpoint {
							transform: translateX(0);
						}
					}

					transform: translateX(60px);
					@include menu-breakpoint {
						transform: translateX(0);
					}

					a {
						@include tablet {
							word-break: break-all;
						}
					}

					&.back-to-start {
						color: $yellow;
					    font-family: $tideKahuna;
						font-size: 24px;
						position: relative;
						padding-left: 35px;
						transform: translateX(0);
						margin: 0 0 40px 0;
						cursor: pointer;

						@include menu-breakpoint {
							font-size: 18px;
						}

						&:before {
							display: block;
							width: 29px;
							height: 29px;
							content: '';
							left: 0;
							top: 0;
							position: absolute;
							background-image: url('../img/menu-back-arrow.svg');
							background-repeat: no-repeat;
							background-size: 16px;
							background-position: center center;
							@include menu-breakpoint {
								width: 23px;
								height: 23px;
								background-size: 16px;
							}
						}

					}
				}
			}

		}
	}
}



/* Effect Move Left */
.effect-moveleft {
    background-image:url('../img/perspective-background.jpg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
}

.effect-moveleft .perspective-container {
	-webkit-transition: -webkit-transform 0.4s, -webkit-opacity .4s;
	transition: transform 0.4s, opacity .4s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.effect-moveleft .perspective-container::after {
	background: rgba(255,255,255,0.6);
}

.effect-moveleft.animate .perspective-container {
	transform: translateX(-50%) rotateY(45deg) translateZ(-50px);
	opacity: .6;

	@include menu-breakpoint {
		transform: translateX(-50%) rotateY(45deg) translateZ(-150px);
	}

}

.no-csstransforms3d .effect-moveleft.animate .perspective-container {
	left: -75%;
}

@media screen and (max-height: 31.6em) {
	.outer-nav a {
		margin-bottom: 20px;
	}
}
