input, textarea {
    outline: none;
}

// Form section
section.component.need-help-form {
    margin-bottom: 100px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
    }

    img.attachment-post-thumbnail {
        @include media-breakpoint-up(md) {
            transform: translate(-70px, -110px);
        }

        @include tabletLandscape {
            transform: translate(0, -60px);
            max-width: 80%;
            height: auto;
            margin: 0 auto;
            display: block;
        }

        @include tablet {
            transform: translate(0, 0);
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            display: block;
        }
    }

    .gform_wrapper {
        @include tablet {
            width: 90%;
            margin: 0 auto;
        }
    }

}

.gform_confirmation_wrapper {
    .gform_confirmation_message {
        color: $black;
        font-family: $tideKahuna;

        a {
            color: $magenta;
            transition: color .2s;
            text-decoration: none!important;
            &:hover {
                color: $yellow;
            }
        }

        strong {
            font-family: $tideBunny;
            font-weight: normal;
        }

    }
}

// The actual form
.gform_wrapper {
    width: 100%;
    @include media-breakpoint-down(sm) {
        margin-top: 30px;
    }
    form {
        .gform_heading {
            width: 100%;
            margin: 0 0 30px 0;
            h3.gform_title {
                @include h2;
                border-left: 3px solid $yellow;
                padding-left: 30px;
                @include media-breakpoint-down(sm) {
                    padding-left: 15px;
                    transform: translateX(-15px);
                }

                @include tablet {
                    padding-left: 15px;
                    transform: translateX(-15px);
                }
            }
        }
        .validation_error.hide {
            display: none;
        }

        .gf_progressbar_wrapper {
            .gf_progressbar_title { display: none; }
            .gf_progressbar {
                border: 1px solid $gray;
                width: 100%;
                height: 40px;
                margin-bottom: 20px;
    
                .gf_progressbar_percentage {
                    background: $pink;
                    height: 100%;
    
                    span { display: none; }
                }
            }
        }
       

        .gform_body {

            @include media-breakpoint-down(md) {
                padding: 0 15px;
            }

            ul {
                margin: 0;
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                li {
                    position: relative;
                    margin-bottom: 30px;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 15px;
                    }

                    &.full {
                        width: 100%;
                    }
                    &.half {
                        width: calc(50% - 15px);

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }

                        @include tablet {
                            width: 100%;
                        }

                    }

                    &.hidden_label {
                        .gfield_label { display: none }
                    }

                    &.marked-radio-field {
                        border: 1px solid $lightGray;
                        text-align: center;
                        label.gfield_label {
                            background-color: $lightGray;

                            &.input_focus {
                                line-height: 40px!important;
                            }
                        }

                        ul li {
                            flex: 1;
                        }
                    }

                    &.gform_html {
                        width: 100%;

                        &.half {
                            width: calc(50% - 15px);
                        }
                        //max-width: calc(100% - 240px);
                        .editor_content {
                            padding: 0 10px;
                            em {
                                font-size: 12px;
                                color: $gray;
                                font-style: italic;
                                a {
                                    color: $magenta;
                                    text-decoration: none;
                                    transition: color .2s;
                                    &:hover {
                                        color: $gray;
                                    }
                                }
                            }
                        }
                    }

                    &.gform_time {
                        .clear-multi {
                            display: flex;

                            .gfield_time_hour {
                                display: flex;
                                align-items: center;
                            }
                        }

                        input {
                            &::placeholder {
                                visibility: hidden;
                            }
                        }

                        label { display: none; }
                    }

                    &.pul-check, &.checkbox {
                        padding: 30px 0 0 10px;
                        margin: 0;
                        @include media-breakpoint-down(sm) {
                            padding: 15px 0 0 10px;
                        }
                        @include tablet {
                            padding: 15px 0 0 10px;
                        }

                        .gfield_label {
                            display: none;
                        }
                        ul {
                            li {
                                margin: 0;

                                input[type="checkbox"] {
                                    display: none;
                                }

                                input[type="checkbox"]:checked + label {
                                    &:after {
                                        opacity: 1;
                                    }
                                }

                                label {
                                    position: relative;
                                    height: 20px;
                                    line-height: 20px;
                                    padding-left: 30px;
                                    a {
                                        color: $magenta;
                                        text-decoration: none;
                                        transition: color .2s;
                                        &:hover {
                                            color: $black;
                                        }
                                    }

                                    &:before {
                                        content: '';
                                        width: 20px;
                                        height: 20px;
                                        border: 1px solid $black;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        cursor: pointer;
                                    }

                                    &:after {
                                        width: 17px;
                                        height: 12px;
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        left: 6px;
                                        top: 3px;
                                        background-image: url('../img/form-checkbox.svg');
                                        background-size: 17px 12px;
                                        background-repeat: no-repeat;
                                        pointer-events: none;
                                        transition: opacity .2s;
                                        opacity: 0;
                                    }
                                }
                            }
                        }

                        &.checkbox {
                            .gfield_label {
                                display: block;
                            }
                        }
                    }

                    .gfield_label {
                        display: block;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 0 10px;
                        pointer-events: none;
                        font-size: 16px;
                        color: $black;
                        transition: line-height .1s, font-size .1s, padding .1s, top .1s;
                        backface-visibility: hidden;
                        *zoom:1;
                        &.input_focus {
                            line-height: 6px;
                            font-size: 12px;
                            padding: 0;
                            top: -4px;
                        }
                    }

                    &.select-label {
                        .ginput_container_select {
                            padding-top: 20px;
                        }
                        .gfield_label {
                            height: auto;
                            line-height: auto;
                            padding: 0 10px;
                            line-height: 1;
                        }
                    }

                    &.textarea-label {
                        .gfield_label {
                            position: static;
                            height: auto;
                            line-height: auto;
                            padding: 0 10px;
                            line-height: 1;
                        }

                        .ginput_container_textarea {
                            display: flex;
                            flex-wrap: wrap;

                            label {
                                order: 1;
                            }

                            textarea {
                                order: 2;
                                height: 120px;
                            }
                        }
                    }

                    input[type="text"], input[type="email"], input[type="number"] {
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;
                        border: none;
                        border-bottom: 1px solid $black;
                        background-color: transparent;
                        border-radius: 0;
                    }

                    input[type="text"].datepicker::placeholder {
                         visibility: hidden;
                      }

                    textarea {
                        width: 100%;
                        min-height: 40px;
                        height: 40px;
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid $black;
                        resize: none;
                        padding: 10px;
                        color: $black;
                        overflow: hidden;
                        font-size: 16px;
                        line-height: 24px;
                        transition: height 50ms;
                        border-radius: 0;
                    }

                    &.gform_select {
                        .gfield_label {
                            //display: none;
                        }
                    }

                    .select2-container--gform {
                        width: 100%;
                        max-width: 100%;

                        .select2-selection--single {
                            height: 40px;
                        }
                    }

                    .hiddenDiv {
                        display: none;
                        white-space: pre-wrap;
                        width: 100%;
                        min-height: 40px;
                        font-size: 16px;
                        padding: 10px;
                        word-wrap: break-word;
                        line-height: 24px;
                    }

                    .gfield_description {
                        font-family: $tideKahuna;
                        font-size: 12px;
                        padding: 10px;
                        color: $gray;
                    }

                    .validation_message {
                        font-family: $tideBunny;
                        color: $magenta;
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: -10px;
                        text-align: right;
                        padding-right: 30px;

                        &.hide {
                            display: none;
                        }
                    }

                    &.gform_radio {
                        .validation_message {
                            top: -25px;
                        }
                    }

                    &.gform_radio {

                        .gfield_label {
                            position: static;
                            transform: translate(0, 0);
                        }

                        ul.gfield_radio {
                            justify-content: flex-start;
                            li {
                                margin: 0 10px 0 10px;
                                line-height: 1;

                                input[type="radio"] {
                                    display: none;
                                }

                                input[type="radio"]:checked + label {
                                    &:after {
                                        transform: scale(1);
                                        opacity: 1;
                                    }
                                }

                                label {
                                    position: relative;
                                    padding: 0 0 0 25px;
                                    line-height: 19px;

                                    &:before,
                                    &:after {
                                        position: absolute;
                                        content: '';
                                        display: block;
                                    }

                                    &:before {
                                        width: 19px;
                                        height: 19px;
                                        border: 1px solid $black;
                                        border-radius: 50%;
                                        left: 0;
                                        top: 0;
                                    }

                                    &:after {
                                        width: 9px;
                                        height: 9px;
                                        border-radius: 50%;
                                        background-color: $magenta;
                                        top: 5px;
                                        left: 5px;
                                        transform: scale(1.8);
                                        opacity: 0;
                                        transition: transform .2s, opacity .2s;
                                    }

                                }

                            }
                        }
                        &.bf-radio ul.gfield_radio {
                            display: table;
                        }
                    }

                    &.gform_fileupload {
                        .gfield_label {
                            position: static;
                        }
                        .gform_fileupload_rules { display: block; margin-top: 4px; }
                    }

                    .gform_drop_area {
                        width: 100%;
                        border: 1px dashed $black;
                        text-align: center;
                        background-color: $lightGray;
                        padding: 25px 0;

                        input {
                            color: $black;
                            font-family: $tideBunny;
                            font-size: 14px;
                            background-color: $yellow;
                            border: none;
                            outline: none;
                            padding: 8px 25px;
                            cursor: pointer;
                            transition: background-color .2s, color .2s;
                            border-radius: 0;
                            &:hover {
                                background-color: $magenta;
                                color: $white;
                            }
                        }
                    }

                    .ginput_preview {
                        width: 100%;
                        border: 1px solid $green;
                        padding: 3px 10px;
                        strong {
                            font-weight: normal;
                            color: $black;
                            font-family: $tideKahuna;
                        }
                    }

                    &.gfield_error {
                        textarea, input, select + .select2-container--gform {
                            border-color: $magenta;
                        }
                    }

                }
            }

            .gform_checkbox {
                margin-top: -20px;
                margin-bottom: 0;
                .gfield_label {
                    line-height: 22px;
                }
                .ginput_container_checkbox {
                    padding: 10px 0 0 0;

                    @include media-breakpoint-down(sm) {
                        padding-top: 35px;
                    }

                    li {
                        margin-bottom: 10px;
                    }
                }
            }

           @media only screen and (min-width: 200px) and (max-width: 768px) {
                li.gform_select {
                    border-bottom: 1px solid $black;
                    select {
                        width: 100%;
                        padding-bottom: 20px;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        border: none;
                        background-color: inherit;
                    }
                    .ginput_container_select::after {
                        content: "\25BC";
                        position: absolute;
                        height: .8em;
                        font-size: .6em;
                        line-height: 1;
                        right: .9em;
                        top: 50%;
                        margin-top: -.5em;
                        color: $black;
                    }
                }
                .input_focus + .ginput_container_select select {
                    padding-top: 14px;
                    padding-bottom: 6px;
                }
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                li.gform_select {
                    border-bottom: 1px solid $black;
                    select {
                        width: 100%;
                        padding-bottom: 20px;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        border: none;
                        background-color: inherit;
                    }
                    .ginput_container_select::after {
                        content: "\25BC";
                        position: absolute;
                        height: .8em;
                        font-size: .6em;
                        line-height: 1;
                        right: .9em;
                        top: 50%;
                        margin-top: -.5em;
                        color: $black;
                    }
                }

                .input_focus + .ginput_container_select select {
                    padding-top: 14px;
                    padding-bottom: 6px;
                }
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation : landscape) {
                li.gform_select {
                    border-bottom: 1px solid $black;
                    select {
                        width: 100%;
                        padding-bottom: 20px;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        border: none;
                        background-color: inherit;
                    }
                    .ginput_container_select::after {
                        content: "\25BC";
                        position: absolute;
                        height: .8em;
                        font-size: .6em;
                        line-height: 1;
                        right: .9em;
                        top: 50%;
                        margin-top: -.5em;
                        color: $black;
                    }
                }

                .input_focus + .ginput_container_select select {
                    padding-top: 14px;
                    padding-bottom: 6px;
                }
            }
        }

        .gform_footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                justify-content: center;
                padding-top: 30px;
            }

            @include tablet {
                justify-content: center;
                padding-top: 30px;
            }

            button {
                @include white-line-button($black, $black);
                background: none;
                padding: 0;
                border: none;
                cursor: pointer;
                width: 220px;


            }
        }
    }
}

.gform_footer {
    position: relative;
}

.gform_ajax_spinner {
	position: absolute;
    left: calc(100% + 15px);
    top: -15px;
	width: 30px;
	height: 30px;
}


// Validation
.gform_validation_container {
    display: none;
}

body .select2-container--gform {
    font-size: 14px;
    border-bottom: 1px solid $black;
    outline: none;

    .select2-selection--single {
        height: 50px;
        outline: none;

        .select2-selection__placeholder {
            color: $black;
            font-size: 16px;
        }

        .select2-selection__rendered {
            line-height: 50px;
        }

        .select2-selection__arrow {
            height: 48px;
            position: absolute;
            top: 1px;
            right: 1px;
            width: 20px;

            b {
                border-color: $black transparent transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                height: 0;
                left: 50%;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
                width: 0;
                transition: transform 0.2s;
            }
        }
    }
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: $black transparent transparent transparent;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .select2-dropdown {
        border-color: $black;
        border-radius: 0;

    }

    .select2-results__options {
        max-height: 400px;
        overflow: auto;

        .select2-results__option {
            color: $magenta;
            transition: background 0.2s;
            padding: 7px 6px;

            &:hover {
                background: $lightGray;
            }

            &:first-child {
                display: none;
            }

            &:nth-child(even) {
                background-color: $lightGray;
            }

            &.select2-results__option--highlighted,
            &:hover {
                background-color: $yellow;
                color: $black;
            }
        }
    }
}

.fancybox-button--close {
    background: transparent!important;
    top: 50px!important;
    right: 50px!important;
    position: absolute;
    padding: 0!important;
    margin: 0!important;
    width: 50px!important;
    height: 50px!important;
    outline: none!important;

    @include media-breakpoint-down(md) {
        top: 15px!important;
        right: 15px!important;
    }

    span {
        display: block;
        width: 50px;
        height: 50px;
        position: relative;

        &:before,
        &:after {
            content: '';
            width: 50px;
            height: 2px;
            background-color: $yellow;
            display: block;
            position: absolute;
            top: calc(50% - 1px);
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

    }
}

// Fancybox forms
.fancybox-bg {

    opacity: .95!important;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a836aa+0,823ea3+44,483279+100 */
    background: rgb(168,54,170)!important; /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(168,54,170,1) 0%, rgba(130,62,163,1) 44%, rgba(72,50,121,1) 100%)!important; /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(168,54,170,1) 0%,rgba(130,62,163,1) 44%,rgba(72,50,121,1) 100%)!important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(168,54,170,1) 0%,rgba(130,62,163,1) 44%,rgba(72,50,121,1) 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a836aa', endColorstr='#483279',GradientType=1 )!important; /* IE6-9 fallback on horizontal gradient */
}

.fancybox-slide {
    .ak-fancy-forms {
        max-width: 620px;
        background-color: transparent;
        padding: 24px 0;

        .fancybox-close-small {
            display: none;
        }

        .gform_confirmation_wrapper {
            .gform_confirmation_message {
                color: $white;
                font-family: $tideKahuna;

                a {
                    color: $yellow;
                    transition: color .2s;
                    &:hover {
                        color: $magenta;
                    }
                }

                strong {
                    font-family: $tideBunny;
                    font-weight: normal;
                }
            }
        }

        .gform_wrapper {
            form {
                .gform_heading {
                    padding: 0 0 20px 0;
                    h3 {
                        border-left: 0;
                        padding: 0;
                        text-align: center;
                        color: $white;

                        transform: translateX(0)!important;

                        &:before {
                            content: '';
                            display: block;
                            width: 70px;
                            height: 70px;
                            background-image: url('../img/paper-plane.png');
                            background-repeat: no-repeat;
                            background-position: center top;
                            background-size: 70px;
                            margin: 0 auto 20px;
                        }
                    }
                }
                .gform_body {

                    .gfield_label {
                        color: $white;
                    }
                    input[type="text"], input[type="email"], textarea {
                        border-color: $white;
                        color: $white;
                    }

                    .select2-container--gform {
                        border-color: $white;
                        color: $white;

                        .select2-selection--single .select2-selection__arrow b {
                            border-color: $white transparent transparent transparent;
                        }
                    }

                    .gform_drop_area {
                        border: 1px dashed $white;
                        background-color: rgba(255,255,255, .3);
                        span {
                            color: $white;
                        }
                    }

                    .ginput_preview {
                        strong {
                            font-weight: normal;
                            color: $white;
                        }
                    }

                    .gfield_description {
                        color: rgba(255,255,255, .7);
                    }


                    .gform_radio {
                        ul.gfield_radio {
                            li {
                                label {
                                    color: $white;
                                    &:before { border: 1px solid $white; }
                                    &:after { background-color: $yellow; }
                                }
                            }
                        }
                    } // Radio

                    .gform_html {
                        .editor_content {
                            em {
                                color: $gray;
                                a {
                                    color: $yellow;
                                    &:hover {
                                        color: $gray;
                                    }
                                }
                            }
                        }
                    }

                    .pul-check {
                        width: 100%;
                        ul {
                            justify-content: center;
                            li {
                                label {
                                    color: $white;
                                    a {
                                        color: $yellow;
                                        text-decoration: underline;
                                    }
                                    &:before {
                                        border-color: $white;
                                    }
                                    &:after {
                                        background-image: url('../img/form-checkbox-yellow.svg');
                                    }
                                }
                            }
                        }
                    }
                }
                .gform_footer {
                    justify-content: center;
                    padding-top: 30px;
                    button {
                        transform: translateY(0);
                        @include white-line-button($white, $black);
                        background-color: transparent!important;

                    }
                }
            }
        }
    }
}

.fancybox-is-open + .select2-container--open {
    z-index: 99992;
}

// Form validator
.validation-ok {
    &::after {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: '\f00c';
        color: $green;
        position: absolute;
        right: 5px;
    }
}
.validation-fail {
    &::after {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        content: '\f12a';
        color: $magenta;
        position: absolute;
        right: 5px;
    }
}
.validation-fail-wrapper {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffe4e4+100 */
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top, rgb(255,255,255) 10%, rgb(255,228,228) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(255,255,255) 10%,rgb(255,228,228) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgb(255,255,255) 10%,rgb(255,228,228) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffe4e4',GradientType=0 ); /* IE6-9 */

    input[type=email] {
        border-color: red!important;
    }
}

/* General GF Date Picker */
#ui-datepicker-div {
    background: #FFF;
    border: 1px solid #ddd;
    border-radius:4px;
    //.box-shadow(0px 3px 5px 0px rgba(50, 50, 50, 0.75));
    display:none;
    padding:20px;
    width:300px;

    a {
        color: $magenta;
        font-family: $tideBunny;

        &:hover {
            background-color: $magenta;
            color: $white;
            text-decoration: none;
        }
    }

    .ui-datepicker-current-day {
        background: $yellow;
        a {
            color: $black!important;
        }

    }

    .ui-datepicker-unselectable {
        color: $gray;
    }

  .ui-icon {
      color: transparent; cursor: pointer; font-size: 0px;
  }
  .ui-icon:before {
      //color: @anchor-color;
      font-family:"FontAwesome"; font-size: 18px;
  }

  .ui-datepicker-prev {
      float: left; width: 10%;
    .ui-icon:before {
        content:'\f060';
    }
    &.ui-state-disabled {
        display:none;
    }
  }
  .ui-datepicker-next {
      float: right;
      width: 10%;
    .ui-icon:before {
        content:'\f061';
        float: right;
    }
    &.ui-state-disabled {
        display:none;
    }
  }

  .ui-datepicker-title {
    select {
        float: left;  width: 70%;
    }

    .ui-datepicker-year, .ui-datepicker-month {
        margin: 0px 15% 6px;
    }
  }

  .ui-datepicker-today {
    background-color: $lightGray;
      color: $black;
  }

  table {
      width: 100%;
    td, th {
        text-align: center;
    }
    td {
      a {
          display: block; padding: 5px;
      }
    }
  }

}


.ritningsproduktion_wrapper {
    .gform_heading {
        text-align: center;
        margin-bottom: 20px;

        .gform_title {
            @include h2();
        }
       
    }

    form {
        .gform_body {
            ul {
                li {
                    &.gsection {
                        text-align: center;
                    }

                    .validation_message {
                        position: static;
                        text-align: left;
                        padding: 0;
                        padding-top: 10px;
                    }
                }
            }

            .gform_page_footer {
                text-align: center;

                button {
                    @include white-line-button($black, $black);
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.percentbar_100 {
    display: none;
}