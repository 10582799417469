main.background-404 {
    min-height: 800px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
        background-size: auto 80%;
        background-position: center 55px;
        min-height: 500px;

    }

    .editor_content {

        h1,h2,h3,h4 {
            color: $white;
            margin: 0 0 .4em 0;
        }

        p {
            font-family: $tideBunny;
            font-size: 22px;
            color: $white;
            text-transform: uppercase;
            line-height: 1.2em;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        form {
            width: 100%;
            margin: 30px auto 0;
            max-width: 450px;
            background-color: $white;

            @extend %clearfix;

            input[type="search"] {
                width: calc(100% - 90px);
                float: left;
                height: 50px;
                font-family: $tideKahuna;
                font-size: 16px;
                color: $black;
                border: none;
                background: none;

                @include input-placeholder() {
                    opacity: 1;
                }

            }

            button {
                width: 90px;
                margin: 0;
                padding: 0;
                float: left;
                height: 50px;
                border: none;
                background: none;
                text-align: right;
                font-size: 16px;
                color: $magenta;
                font-family: $tideBunny;
                text-transform: uppercase;
                cursor: pointer;
                outline: none;
            }

        }

    }

}
