$tideKahuna: 'TideSans-300LilKahuna', sans-serif;
$tideBunny: 'TideSans-600Bunny', sans-serif;

@font-face {
	font-family: 'TideSans-600Bunny';
	font-display: swap;
	src: url('../fonts/TideSans-600Bunny.eot?#iefix') format('embedded-opentype'),  url('../fonts/TideSans-600Bunny.otf')  format('opentype'),
	     url('../fonts/TideSans-600Bunny.woff') format('woff'), url('../fonts/TideSans-600Bunny.ttf')  format('truetype'), url('../fonts/TideSans-600Bunny.svg#TideSans-600Bunny') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'TideSans-300LilKahuna';
	font-display: swap;
	src: url('../fonts/TideSans-300LilKahuna.eot?#iefix') format('embedded-opentype'),  url('../fonts/TideSans-300LilKahuna.otf')  format('opentype'),
	     url('../fonts/TideSans-300LilKahuna.woff') format('woff'), url('../fonts/TideSans-300LilKahuna.ttf')  format('truetype'), url('../fonts/TideSans-300LilKahuna.svg#TideSans-300LilKahuna') format('svg');
	font-weight: normal;
	font-style: normal;
}
