section.component.column-block, section.component.image-slider {
    position: relative;
    margin-bottom: 100px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
    }

    @include tabletLandscape {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 50px;
    }

    .title-row {
        padding-top: 60px;
        padding-bottom: 60px;

        @include media-breakpoint-down(sm) {
            padding-top: 30px;
            padding-bottom: 10px;
        }

        @include tabletLandscape {
            padding-top: 40px;
            padding-bottom: 20px;
        }

        @include tablet {
            padding-top: 30px;
            padding-bottom: 20px;
        }

    }

    .column-wrapper {
        @include media-breakpoint-down(sm) {
            &:nth-child(1) {
                margin-bottom: 70px;
            }
            &:nth-child(2) {
                margin-bottom: 70px;
            }
            &:nth-child(3) {
                display: none;
            }
        }

        @include tablet {
            &:nth-child(3) {
                display: none;
            }
        }

        &.column-wrapper-referencecase {
            @include media-breakpoint-down(sm) {
                &:nth-child(1) {
                    margin-bottom: 0;
                }
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    a.column {
        position: relative;
        display: block;
        transition: transform .2s ease;

        img {
            width: 100%;
            max-width: 90%;
            height: auto;
        }

        .editor_content {
            width: 100%;
            max-width: 90%;
            background-color: $white;
            position: absolute;
            left: 40px;
            top: 40px;
            padding: 25px;

            @include media-breakpoint-down(sm) {
                left: auto;
                right: -15px;
                top: auto;
                bottom: -50px;
                max-width: 320px
            }

            .h2 {
                margin-bottom: .55em;
                color: $magenta;
                @include media-breakpoint-down(sm) {
                    margin-bottom: .22em;
                }
            }

            p {
                color: $black;
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
                i {
                    color: $magenta;
                    font-size: 20px;
                    transform: translateY(1px);
                }
            }

        }
    }



    .reference {
        position: relative;
        overflow: hidden;
        margin: 0 0 30px 0;

        &:hover {
            .reference-content {
                top: 0;
                transform: translateX(0);
                h4 {
                    transform: translate(0, 0);
                    span {
                        opacity: 0;
                        width: 0;
                        margin-right: 14px;
                    }
                }
            }
        }

        .reference-content {
            background-color: transparentize($yellow, .15);
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: transform .4s $easeInOutExpo, top .4s $easeInOutExpo;
        }

        &.small-reference {
            .reference-content {
                p {
                    font-size: 25px;
                }
            }
        }

        &.big-reference {
            .reference-content {
                p {
                    font-size: 35px;
                }
            }
        }

        h4 {
            margin: 0;
            padding: 14px 14px 14px 0;
            line-height: 1;
            display: table;
            width: auto;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $white;
            font-family: $tideBunny;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 16px;
            z-index: 1;
            transform: translate(100%, -100%);
            transition: transform .4s $easeInOutExpo;

            span {
                display: inline-block;
                width: 14px;
                height: 14px;
                position: relative;
                margin-right: 8px;
                overflow: hidden;
                transition: width .4s $easeInOutExpo, margin-right .4s $easeInOutExpo, opacity .1s ease;
                &:before,
                &:after {
                    content: '';
                    width: 14px;
                    height: 4px;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin: -1px 0 0 0;
                    background-color: $magenta;
                    border-radius: 1px;
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }

        img {
            width: 100%;
            height: auto;
        }

        q {
            position: absolute;
            left: 0;
            top: 50%;
            margin: 0;
            padding: 0 25px;
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            transform: translateY(-40%);
            text-align: center;

            &:after,
            &:before {
                display: none;
            }

            p {
                font-family: 'Times', serif;
                font-style: italic;
                color: $black;
                line-height: 1.2em;
                &:before { content: '“'; }
                &:after { content: '”'; }
            }

            a {
                width: auto;
                display: table;
                font-family: $tideBunny;
                color: $magenta;
                text-decoration: none;
                text-transform: uppercase;
                margin-top: 10px;
                position: relative;
                transition: color .2s ease;
                z-index: 1;
                margin: 0 auto;

                &:hover {
                    color: $black;
                    &:after {
                        width: 100%;
                    }
                }

                &:after {
                    content: '';
                    width: 0;
                    height: 10px;
                    background: $white;
                    position: absolute;
                    transition: width .2s $easeInOutExpo;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    transform: translate(5px, -1px);
                    backface-visibility: hidden;
                    *zoom:1;
                }

                i {
                    margin-left: 4px;
                }
            }
        }
    }


    .news-carousel {
        @include tablet {
            max-width: 640px;
            margin: 0 auto;
        }
    }


    .carousel-item {

        @extend %clearfix;

        img {
            width: 100%;
            height: auto;
        }

        @include tablet {
            width: 640px;
        }

        .editor_content {
            background-color: $white;
            padding: 25px;
            float: right;
            max-width: 80%;
            margin-top: -60px;
            position: relative;
            z-index: 2;

            @include media-breakpoint-down(sm) {
                padding: 15px;
                max-width: 300px;
            }

            @include tabletLandscape {
                max-width: 90%;
            }

            @include tablet {
                float: none;
                max-width: 500px;
                float: right;
                margin-top: -80px;
            }

            .h2, h2 {
                color: $magenta;
                margin: 0 0 .4em 0;
                a {
                    color: $magenta;
                    text-decoration: none;
                }
            }

            p {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            time {
                color: #9d9fa2;
                border-left: 3px solid $yellow;
                padding-left: 7px;
                font-family: $tideKahuna;
                display: inline-block;
                width: 100%;
                margin: 0 0 .3em 0;
                overflow-wrap: break-word;

                a, span.separator {
                    color: #9d9fa2;
                    font-family: $tideKahuna;
                    transition: color .2s;
                    text-decoration: none;
                    &:hover {
                        color: $magenta;
                    }
                }
            }

        }

    }


    &.carousel--version {
        .owl-nav {
            @include media-breakpoint-down(sm) {
                top: 50px;
                button {
                    width: 36px;
                }
            }

            @include tablet {
                top: 50%;
                transform: translateY(-90px);
                button {
                    width: 36px;
                }
            }

        }
        .button-row {
            padding-top: 0;
            @include media-breakpoint-down(sm) {
                padding-top: 20px;
            }
        }
        figure.bg-img {
            height: 432px;
            @include media-breakpoint-down(sm) {
                height: 98%;
            }

            @include tablet {
                height: 421px;
            }
        }
    }


    .button-row {
        padding-top: 60px;
        @include media-breakpoint-down(sm) {
            padding-top: 70px;
        }
        &.smaller {
            padding-top: 20px;
        }

        &.multiple-buttons {
            a.btn {
                margin: 0 15px;
            }
        }

    }


    figure.bg-img {
        position: absolute;
        margin: 0;
        padding: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 75%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @include media-breakpoint-down(sm) {
            height: 98%;
        }

        @include tabletLandscape {
            height: 95%;
        }

        @include tablet {
            height: 94%;
        }

        &.smaller {
            height: 67%;
            @include media-breakpoint-down(sm) {
                height: 98%;
            }
        }

    }

}
