body {
    font-family: $tideKahuna;
    font-size: 14px;
}

.container-fluid {
    width: 100%;
    max-width: 1170px;
}

main {
    padding-top: 78px;
    transition: padding .2s;

    @include menu-breakpoint {
        padding-top: 55px;
    }

}

// Scrolldown indicator
button.scrolldown-indicator {
    border: none;
    outline: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: $yellow;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -25px);
    z-index: 99;

    @include media-breakpoint-down(sm) {
        width: 34px;
        height: 34px;
        transform: translate(-50%, -17px);
    }

    svg {
        width: 24px;
        transform: translateY(2px);

        @include media-breakpoint-down(sm) {
            width: 16px;
        }
    }
}

// Section scrolling effect
// section.component {
//     opacity: 0;
//     transform: translateY(100px);

//     transition: transform 500ms $easeInOutQuad, opacity 500ms $easeInOutQuad;
//     &.visible {
//         opacity: 1;
//         transform: translateY(0);
//     }
// }

.ak-breadcrumbs {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    display: block;
    margin: 0;
    padding: 4px 10px 4px 120px;
    list-style: none;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 */

    @include media-breakpoint-down(sm) {
        display: none;
    }


    li {
        display: inline-block;
        color: $white;
        font-size: 12px;
        text-transform: uppercase;

        span.separator {
            margin: 0 5px;
            display: inline-block;
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }
}

.intelecomchat {
    z-index: 899;
    transition: opacity .2s;
    opacity: 1;

    &.not-visible {
        opacity: 0;
    }

    @include menu-breakpoint {
        z-index: 899;
    }

    @include media-breakpoint-down(sm) {
        z-index: 10000;
    }

    .intelecomchatstarter {
        position: fixed;
        top: 85%!important;
        right: 0;
        background: none;
        border: 1px solid $black;
        border-right: 0;
        background-color: $white;
        height: 40px;
        width: 80px;
        border-radius: 0;

        @include media-breakpoint-down(sm) {
            top: 50px!important;
            left: 15px!important;
            width: 27px;
            height: 21px;
            border: none;
        }


        &:before {
            position: absolute;
            text-indent: 0;
            display: block;
            content: 'Chat';
            width: 100%;
            top: 0;
            left: 0;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-family: $tideBunny;
            font-weight: normal;
            color: $black;
            // text-transform: uppercase;
            z-index: 2;

            @include media-breakpoint-down(sm) {
                content: '';
                height: 21px;
                width: 27px;
                background-image: url('../img/chat-icon-black.svg');
            }


        }

        &:after {
            height: 5px;
            content: '';
            width: 100%;
            position: absolute;
            top: calc(100% + 1px);
            left: 5px;
            background-color: $yellow;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

    }

}

.owl-carousel {
    .owl-nav {
        position: absolute;
        top: 50%;
        width: calc(100% + 150px);
        left: -75px;
        transform: translateY(-50%);

        @media only screen and (max-width: 1310px) {
            width: calc(100% + 30px);
            left: -15px;
        }

        button {
            width: 47px;
            height: 47px;
            background: $yellow!important;
            outline: none;

            @include media-breakpoint-down(sm) {
                width: 36px;
            }

            @include tabletLandscape {
                width: 36px;
            }

            svg {
                margin-top: 3px;
                max-width: 17px;
                @include media-breakpoint-down(sm) {
                    max-width: 13px;
                }
            }

            &.owl-prev {
                background: $yellow;
                svg {
                    transform: rotate(180deg);
                }
            }
            &.owl-next {
                float: right;
                background: $yellow;
                svg {
                    margin: 3px 0 0 5px;
                }
            }

        }
    }

    .owl-dots {
        width: 100%;
        position: absolute;
        top: calc(100% + 42px);
        left: 0;
        text-align: center;

        @include media-breakpoint-down(sm) {
            top: calc(100% - 5px);
        }

        @include tabletLandscape {
            top: calc(100% + 15px);
        }

        @include tablet {
            top: calc(100% + 7px);
        }

        button {
            margin: 0 12px;
            padding: 0;
            line-height: 1;
            display: inline-block;
            opacity: .3;
            transition: opacity .2s;
            &.active {
                opacity: 1;
            }
            span {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $white;
            }
        }
    }
}

// Password protected area
.password-required-area {

    width: 100%;
    min-height: 600px;
    background-color: $lightGray;
    position: relative;

    @include media-breakpoint-down(sm) {
        min-height: 350px;
    }

    form.post-password-form {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 640px;
        transform: translate(-50%, -50%);
        text-align: center;
        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }

        p {
            input[type="password"] {
                height: 40px;
                font-family: $tideBunny;
                padding: 0 10px;
                border: none;
            }

            input[type="submit"] {
                height: 40px;
                border: none;
                background: $yellow;
                cursor: pointer;
                font-family: $tideBunny;
            }

        }

    }

}

// Cookie notice
#cookie-notice {
    background-color: $yellow!important;
    .cookie-notice-container {
        padding: 15px;
        span {
            color: $black;
            font-family: $tideBunny;
            font-size: 14px;
        }
    }

    .cn-set-cookie,
    #cn-more-info {
        color: $white;
        background: $magenta;
        border: none;
        font-family: $tideBunny;
        font-size: 14px;
        text-decoration: none;
        display: inline-block;
        padding: 5px 15px;
    }
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
