section.component.post-roll {
    margin-top: -90px;
    margin-bottom: 120px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }

    article.post {
        position: relative;
        margin-bottom: 30px;

        a.blog-thumb {
            width: 100%;
            height: 100%;
            max-height: 190px;
            overflow: hidden;
            display: block;
            background-color: $yellow;
        }

        img {
            max-width: 100%;
            height: auto;
            display: block;
        }

        .editor_content {
            width: calc(100% - 30px);
            display: block;
            background-color: $white;
            padding: 30px 15px 30px 0;
            text-decoration: none!important;
            margin-top: -60px;
            position: relative;
            z-index: 2;

            h3 {
                color: $magenta;
                text-decoration: none;
                margin: 0 0 .4em 0;

                @include media-breakpoint-up(lg) {
                    display: inline-block;
                    min-height: 72px;
                    width: 100%;
                }

            }

            time.post-meta {
                color: $gray;
                border-left: 3px solid $yellow;
                padding-left: 5px;
                display: inline-block;
                width: 100%;
                margin: 0 0 .3em 0;
            }

            p {
                color: $black;
                i {
                    color: $magenta;
                    font-size: 16px;
                    margin: 0 0 0 5px;
                }
            }

        }
    }

    .pagination {
        text-align: center;

        a, span {
            display: inline-block;
            position: relative;
            font-size: 18px;
            font-family: $tideBunny;
            padding: 5px;
            line-height: 1;
        }

        span {
            color: $black;
            &.dots {
                color: $magenta;
            }

            &.current {
                &:after {
                    width: 13px;
                    height: 13px;
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: $yellow;
                    left: 7px;
                    top: 9px;
                    z-index: -1;
                }
            }

        }

        a {
            color: $magenta;
            text-decoration: none;

            &.btn {
                padding: 0;
                cursor: pointer;
                span {
                    padding: 18px 38px 16px;
                }
            }
        }
    }
}


article.single-news,
article.academy-single {

    section.component.hero {
        padding: 80px 0 160px;
    }

    .single-post-thumbnail {
        margin: -80px 0 50px 0;
        @include media-breakpoint-down(sm) {
            margin: -80px 0 30px 0;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .post-content {
        margin-bottom: 80px;
    }

}
