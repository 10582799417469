@mixin white-line-button($from: $white, $to: $black) {
    display: inline-block;
    position: relative;
    font-family: $tideBunny;
    font-weight: normal;
    font-size: 16px;
    color: $from;
    text-decoration: none;
    line-height: 1;
    transition: color .2s;
    background-color: $white;

    &:hover {
        color: $to;
        span:after {
            width: calc(100% - 5px);
        }
    }

    span {
        padding: 18px 38px 16px;
        display: block;
        position: relative;
        z-index: 2;
        border: 1px solid $from;
        pointer-events: none;

        &:after {
            content: '';
            //width: 100%;
            width: 0;
            height: calc(100% - 5px);
            position: absolute;
            right: 0;
            top: 5px;
            background-color: $yellow;
            z-index: -1;
            transition: width .2s $easeInOutExpo;

        }
    }

    &:after {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        right: -6px;
        top: 6px;
        background-color: $yellow;
        z-index: 0;
        transition: width .1s $easeInOutExpo;
        transform: translateZ(0);
    }

    &:before {
        content: '';
        width: 100%;
        height: 6px;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: $yellow;
        z-index: 0;
        transform: translate(6px, 0);
        pointer-events: none;
    }

}

@mixin filled-btn($bgFrom: $magenta, $bgTo: $white, $textFrom: $white, $textTo: $black, $box: $yellow) {
    display: inline-block;
    position: relative;
    text-decoration: none;

    span {
        display: block;
        line-height: 51px;
        font-family: $tideBunny;
        //text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        height: 51px;
        min-width: 230px;
        transition: background-color .2s, color .2s;
        color: $textFrom;
        background-color: $bgFrom;
        position: relative;
        z-index: 1;
        padding: 0 10px;
        i {
            margin-right: 5px;
        }
    }

    &:hover, &.active {
        span {
            color: $textTo;
            background-color: $bgTo;
        }
        &:after {
            top: 6px;
            left: 6px;
        }

        &.gold {
            span {
                background: $goldGradientReverse;
            }
        }

        &.copper {
            span {
                background: $copperGradientReverse;
            }
        }
    }



    &:after {
        width: 100%;
        height: 51px;
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: $box;
        z-index: 0;
        transition: top .2s, left .2s;
    }

}

@mixin arrow-link($from: $magenta, $to: $black) {
    font-size: 16px;
    line-height: 1;
    color: $from;
    transition: color .2s;
    font-family: $tideBunny;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;

    i {
        margin-left: 5px;
    }

    &:after {
        content: '';
        width: 0;
        height: 10px;
        background-color: $yellow;
        position: absolute;
        right: -6px;
        top: calc(100% - 9px);
        z-index: -1;
        transition: width .3s $easeInOutExpo;
    }

    &:hover {
        color: $to;
        &:after {
            width: 100%;
        }
    }
}


a.btn.btn-outlined-black {
    @include white-line-button($black, $black);

    &.black-bg {
        background-color: $black;
        color: $white;
        &:hover {
            color: $black;
        }
    }

}


.ak-buttons-container {
    padding-top: 20px;
    a {
        margin: 0 30px 0 0;
        &:last-child {
            margin: 0 0 0 0;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0 0 15px 0;
            &:last-child {
                margin: 0 0 0 0;
            }
        }

        &.magenta {
            @include filled-btn($magenta, $black, $white, $white, $yellow);
        }
        &.white {
            @include filled-btn($white, $yellow, $black, $black, $black);
        }

        &.gold {
            @include filled-btn($gold, $gold, $black, $black, $black);
            span {
                background: $goldGradient;
            }
        }

        &.copper {
            @include filled-btn($copper, $copper, $black, $black, $black);
            span {
                background: $copperGradient;
            }
        }

    }
}

.component.buttons {
    padding: 20px 0 60px;

    .h2 {
        border-left: 3px solid #ffe600;
        padding: 0 0 10px 30px;
    }

    .ak-buttons-container {
        text-align: center;
    }
}
