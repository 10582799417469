section.logos {
    margin: 60px 0;
    padding: 50px 0;

    .container-fluid {
        .row {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .logo {
            padding: 23px;
            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
                text-align: center;
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                transition: filter 0.4s;

                &:hover {
                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                }
            }
        }
    }

    .editor-content {
        .h2 {
            font-size: 30px;
            &::before {
                width: 3px;
                height: 100%;
                content: '';
                display: inline-block;
                background-color: $yellow;
                position: absolute;
                left: -23px;
                top: 0;
            }
        }
    }
}
